import React from 'react'
import { Box } from '@chakra-ui/core'
import customTheme from '../../../../styles/theme'

const Container: React.FC = ({ children }) => {
  return (
    <Box
      width="100%"
      marginLeft="auto"
      marginRight="auto"
      paddingX={4}
      maxW={customTheme.breakpoints}>
      {children}
    </Box>
  )
}

export default Container
