import Cookie from 'js-cookie'

export const agreeUseCookies = (): void => {
  Cookie.set('agreeUseCookie', '1', {
    expires: (30 * 12) * 10
  })
}

export const readAgreeUseCookie = (): boolean => {
  return !!Cookie.get('agreeUseCookie')
}
