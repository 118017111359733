/**
 * Google functions
 * @author Flavio Lacerda
 */

const GOOGLE_FORMS_URL = 'https://docs.google.com/forms/d'
const OTHER_OPTION_RESPONSE = 'other_option_response'

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex.default : ex }
const fetch = _interopDefault(require('isomorphic-unfetch'))

const formatQuestionName = function formatQuestionName (id) {
  if (id.includes(OTHER_OPTION_RESPONSE)) {
    return 'entry.' + id.replace('-', '.')
  }
  return 'entry.' + id
}

export const submitToGoogleForms = function submitToGoogleForms (form, formData) {
  try {
    const urlParams = new URLSearchParams()
    Object.keys(formData).forEach(function (key) {
      if (formData[key]) {
        if (formData[key].constructor === Array) {
          formData[key].forEach(function (answer) {
            urlParams.append(formatQuestionName(key), answer)
          })
        } else {
          urlParams.append(formatQuestionName(key), formData[key])
        }
      }
    })
    return Promise.resolve(fetch(GOOGLE_FORMS_URL + '/' + form.action + '/formResponse?submit=Submit&' + urlParams.toString(), {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })).then(function (fetchedResult) {
      const wasSuccessful = fetchedResult.ok && fetchedResult.status < 300 && fetchedResult.status >= 200
      return wasSuccessful
    })
  } catch (e) {
    return Promise.reject(e)
  }
}
