import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as SearchRegular } from '../ui/icons/search-regular.svg'
import styled from '@emotion/styled'
import { useAlertBox } from '../ui/layout/alert-box/alert-box-context'
import { useHistory } from 'react-router'
import customTheme from '../../styles/theme'

const SearchButton = () => {
  const [active, setActive] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { alertBox } = useAlertBox()
  const history = useHistory()

  const clickSearch = () => {
    setActive(prev => !prev)
  }

  const handleClickOutside = (e) => {
    if (inputRef.current.contains(e.target)) {
      return
    }

    setActive(false)
  }

  useEffect(() => {
    if (active) {
      inputRef.current.focus()
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [active])

  const handleClick = () => {
    const value = inputRef.current.value.trim()
    if (value && value.length <= 3) {
      alertBox('Digite pelo menos 3 caracteres para pesquisar')
    } else {
      history.push('/pesquisa?q=' + value)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick()
    }
  }

  return (
    <CustomButton>
      <SearchRegular onClick={clickSearch} />
      <button onClick={handleClick} />
      <InputSearch className={active ? 'active' : ''}>
        <input ref={inputRef} type="search" placeholder="Pesquisar" name="q" onKeyDown={handleKeyDown} />
      </InputSearch>
    </CustomButton>
  )
}

export default SearchButton

const CustomButton = styled.span`
  position: relative;

  @media (max-width: 991px) {
    svg {
      position: absolute;
      color: #313131;
      top: 8px;
      right: 2px;
      z-index: 1;
    }
    
    input {
      padding-right: 35px !important;
      color: #313131 !important;
    }
    
    button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background: transparent;
      width: 35px;
      z-index: 10;
      display: block !important;
    }
  }

  button {
    display: none;  
  }
`

const InputSearch = styled.div`
  @media (min-width: ${customTheme.breakpoints[2]}) {
    position: absolute;
    right: 0;
    z-index: 9;
    top: 100%;
    width: 300px;
    display: none;
    &.active {
      display: block;
    }
  }
  
  input {
    width: 100%;
    padding: 7px 15px;
    border: 1px solid ${customTheme.colors.gray['700']};
  }
`
