import React, { useEffect } from 'react'
import { Typography } from '../ui/foundation'
import { Box } from '@chakra-ui/core'
import { CommentPost } from './index'
import CommentItem from './comment-item'
import Loading from '../loading/loading'
import { useCommentProvider } from './contexts/comment-provider'

interface CommentsProps {
  contentUri: string
}

const Comments = ({ contentUri }: CommentsProps) => {
  const { comments, setCommentParams } = useCommentProvider()

  useEffect(() => {
    if (contentUri) {
      setCommentParams({ id: contentUri.replace('/v1/contents/', '') })
    }
  }, [contentUri, setCommentParams])

  return (
    <Box mt={16}>
      <Typography type="3xl" as="h3" color="purple.500">COMENTÁRIOS</Typography>
      <CommentPost contentUri={contentUri} />

      {comments
        ? (comments.map((comment, index) => (
          <CommentItem
            index={index}
            key={index}
            comment={comment}
            contentUri={contentUri}
          />
        )))
        : <Loading />
      }
    </Box>
  )
}

export default Comments
