export const ddiWithMasks = {
  1: '(999) 999-9999',
  55: '(99) 99999-9999',
  7: '999999999999999',
  20: '999999999999999',
  27: '999999999999999',
  30: '999999999999999',
  31: '999999999999999',
  32: '999999999999999',
  33: '999999999999999',
  34: '999999999999999',
  36: '999999999999999',
  39: '999999999999999',
  40: '999999999999999',
  41: '999999999999999',
  43: '999999999999999',
  44: '999999999999999',
  45: '999999999999999',
  46: '999999999999999',
  47: '999999999999999',
  48: '999999999999999',
  49: '999999999999999',
  51: '999999999999999',
  52: '999999999999999',
  53: '999999999999999',
  54: '999999999999999',
  56: '999999999999999',
  57: '999999999999999',
  58: '999999999999999',
  60: '999999999999999',
  61: '999999999999999',
  62: '999999999999999',
  63: '999999999999999',
  64: '999999999999999',
  65: '999999999999999',
  66: '999999999999999',
  81: '999999999999999',
  82: '999999999999999',
  84: '999999999999999',
  86: '999999999999999',
  90: '999999999999999',
  91: '999999999999999',
  92: '999999999999999',
  94: '999999999999999',
  95: '999999999999999',
  98: '999999999999999',
  212: '999999999999999',
  213: '999999999999999',
  216: '999999999999999',
  218: '999999999999999',
  220: '999999999999999',
  221: '999999999999999',
  222: '999999999999999',
  223: '999999999999999',
  224: '999999999999999',
  226: '999999999999999',
  227: '999999999999999',
  228: '999999999999999',
  229: '999999999999999',
  231: '999999999999999',
  232: '999999999999999',
  233: '999999999999999',
  234: '999999999999999',
  236: '999999999999999',
  237: '999999999999999',
  238: '999999999999999',
  239: '999999999999999',
  240: '999999999999999',
  241: '999999999999999',
  242: '999999999999999',
  244: '999999999999999',
  245: '999999999999999',
  248: '999999999999999',
  249: '999999999999999',
  250: '999999999999999',
  251: '999999999999999',
  252: '999999999999999',
  253: '999999999999999',
  254: '999999999999999',
  256: '999999999999999',
  257: '999999999999999',
  258: '999999999999999',
  260: '999999999999999',
  261: '999999999999999',
  262: '999999999999999',
  263: '999999999999999',
  264: '999999999999999',
  265: '999999999999999',
  266: '999999999999999',
  267: '999999999999999',
  268: '999999999999999',
  269: '999999999999999',
  290: '999999999999999',
  291: '999999999999999',
  297: '999999999999999',
  298: '999999999999999',
  299: '999999999999999',
  350: '999999999999999',
  351: '999999999999999',
  352: '999999999999999',
  353: '999999999999999',
  354: '999999999999999',
  356: '999999999999999',
  357: '999999999999999',
  358: '999999999999999',
  359: '999999999999999',
  370: '999999999999999',
  371: '999999999999999',
  372: '999999999999999',
  373: '999999999999999',
  374: '999999999999999',
  375: '999999999999999',
  376: '999999999999999',
  377: '999999999999999',
  378: '999999999999999',
  379: '999999999999999',
  380: '999999999999999',
  381: '999999999999999',
  385: '999999999999999',
  386: '999999999999999',
  387: '999999999999999',
  389: '999999999999999',
  417: '999999999999999',
  420: '999999999999999',
  421: '999999999999999',
  500: '999999999999999',
  501: '999999999999999',
  502: '999999999999999',
  503: '999999999999999',
  504: '999999999999999',
  505: '999999999999999',
  506: '999999999999999',
  507: '999999999999999',
  509: '999999999999999',
  590: '999999999999999',
  591: '999999999999999',
  592: '999999999999999',
  593: '999999999999999',
  594: '999999999999999',
  595: '999999999999999',
  596: '999999999999999',
  597: '999999999999999',
  598: '999999999999999',
  670: '999999999999999',
  671: '999999999999999',
  672: '999999999999999',
  673: '999999999999999',
  674: '999999999999999',
  675: '999999999999999',
  676: '999999999999999',
  677: '999999999999999',
  678: '999999999999999',
  679: '999999999999999',
  680: '999999999999999',
  681: '999999999999999',
  682: '999999999999999',
  683: '999999999999999',
  686: '999999999999999',
  687: '999999999999999',
  688: '999999999999999',
  689: '999999999999999',
  691: '999999999999999',
  692: '999999999999999',
  850: '999999999999999',
  852: '999999999999999',
  853: '999999999999999',
  855: '999999999999999',
  856: '999999999999999',
  880: '999999999999999',
  886: '999999999999999',
  960: '999999999999999',
  961: '999999999999999',
  962: '999999999999999',
  963: '999999999999999',
  964: '999999999999999',
  965: '999999999999999',
  966: '999999999999999',
  967: '999999999999999',
  968: '999999999999999',
  969: '999999999999999',
  971: '999999999999999',
  972: '999999999999999',
  973: '999999999999999',
  974: '999999999999999',
  975: '999999999999999',
  976: '999999999999999',
  977: '999999999999999',
  992: '999999999999999',
  993: '999999999999999',
  994: '999999999999999',
  996: '999999999999999',
  998: '999999999999999',
  1242: '999999999999999',
  1246: '999999999999999',
  1264: '999999999999999',
  1268: '999999999999999',
  1345: '999999999999999',
  1441: '999999999999999',
  1473: '999999999999999',
  1649: '999999999999999',
  1664: '999999999999999',
  1758: '999999999999999',
  1787: '999999999999999',
  1809: '999999999999999',
  1868: '999999999999999',
  1869: '999999999999999',
  1876: '999999999999999',
  7880: '999999999999999'
}
