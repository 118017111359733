import { allTrainingToView } from '../../../../data/usecases/contents-to-view/all-training-to-view'
import { FolderContentModel } from '../../../../domain/models'
import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'

export const makeFavoriteCurrentCustomerFolderContents = async (): Promise<FolderContentModel[]> => {
  try {
    const response = await api.get<AxiosResponse>('/current_customer_favorite_contents')
    return allTrainingToView.build(response.data['hydra:member'])
  } catch (error) {
    return []
  }
}
