import React from 'react'
import { Box } from '@chakra-ui/core'
import Script from 'react-load-script'
import axios from 'axios'
import { settings } from '../../config/settings'

const MobileSambaPlayer = ({ match }):JSX.Element => {
  const idPlayer = 'player'
  let flag = false
  const { contentId, playerKey, progress, reference, token } = match.params

  const clearPlayer = () => {
    const item = window.document.getElementById(idPlayer)
    if (item) {
      item.innerHTML = ''
    }
  }

  const dispatchActionView = async (currentProgress: number, duration: number): Promise<void> => {
    if (contentId && !flag && currentProgress > progress) {
      flag = true
      const percent = currentProgress / (duration / 100000)
      if (Math.round(percent) > 50) {
        await axios.post(`${settings.API_URL}/user_collection_views`, {
          content: `/v1/contents/${contentId}`,
          percent: Math.round(percent) || 100,
          progress: currentProgress
        }, {
          headers: {
            Authorization: token
          }
        })
      }
    }
  }

  const eventListener = async (player): Promise<void> => {
    const eventParam = parseInt(player.eventParam, 10)

    switch (player.event) {
      case 'onListen':
        if (!flag && eventParam > 1 && eventParam % 15 === 0) {
          await dispatchActionView(eventParam, player.duration)
        } else if (flag && eventParam > 1 && eventParam % 16 === 0) {
          flag = false
        }
        break
      case 'onFinish':
        await dispatchActionView(player.duration / 1000, player.duration)
        break
      default: break
    }
  }

  const init = () => {
    const playerParams = {
      enableShare: false,
      resume: process
    }
    clearPlayer()
    // eslint-disable-next-line no-new
    new (window as typeof globalThis).SambaPlayer(idPlayer, {
      ph: playerKey,
      m: reference,
      playerParams,
      events: {
        '*': eventListener
      }
    })
  }

  return (
    <Box pos="relative" overflow="hidden" w="100%" h="100vh">
      <Script url="https://player.sambatech.com.br/v3/samba.player.api.js" onLoad={init} />
      <Box pos="absolute" left={0} right={0} bottom={0} top={0} w="100%" id={idPlayer} />
    </Box>
  )
}

export default MobileSambaPlayer
