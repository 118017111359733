import React, { useState } from 'react'
import { ButtonRedOutlineGreen, Container } from '../ui/foundation'
import { Box, Flex } from '@chakra-ui/core'
import SocialNetwork from '../social-network/social-network'
import NavFooter from '../nav-footer/nav-footer'
import styled from '@emotion/styled'
import { useModal } from '../ui/layout'
import { agreeUseCookies, readAgreeUseCookie } from '../../utils/helpers/storage/agree-use-cookies'

const Footer: React.FC = () => {
  const { alertBox } = useModal()
  const [agreeUseCookie, setAgreeUseCookie] = useState(readAgreeUseCookie())

  const handleModal = () => {
    alertBox(
      <Box p={8}>
        <iframe width="100%" height="500px" src="/politica-de-privacidade" title="Política de privacidade" />
      </Box>
    )
  }

  const handleClick = () => {
    agreeUseCookies()
    setAgreeUseCookie(true)
  }

  return (
    <>
      <Box bg="gray.700" color="white" pt={8} pb={agreeUseCookie ? 8 : 32}>
        <Container>
          <Flex
            alignItems="center"
            w={['90%', '', '80%']}
            justifyContent="space-between"
            mx="auto"
            flexWrap="wrap"
          >
            <Box w={['100%', '', '70%']} mb={[6, 6, 0]}>
              <NavFooter />
            </Box>
            <Box w={['100%', '', '30%']}>
              <SocialNetwork />
            </Box>
          </Flex>
        </Container>
      </Box>
      {!agreeUseCookie && (
        <DisclaimerCookies>
          <Container>
            <DisclaimerDescription>
              <div>
                <strong>GAIZ e os cookies:</strong> a gente usa cookies para personalizar anúncios e melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa{' '}
                <span onClick={handleModal}>política de privacidade</span>.
              </div>
              <ButtonRedOutlineGreen mt={[30, 30, 0]} onClick={handleClick}>Continuar e fechar</ButtonRedOutlineGreen>
            </DisclaimerDescription>
          </Container>
        </DisclaimerCookies>
      )}
    </>
  )
}

export default Footer

const DisclaimerCookies = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 999;
`
const DisclaimerDescription = styled.div`
  background: #fff;
  padding: 15px 45px 15px 15px;
  font-size: 14px;

  @media (min-width: 768px) {
    display: flex;
  }

  border-radius: 8px;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);

  span {
    color: #2576bf;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`
