import { addLocalStorage, getLocalStorage } from '../../infra/storage/localStorage'
import { ProfileModel } from '../../domain/models'

export const getUser = (): ProfileModel => {
  const user = getLocalStorage('user')
  return JSON.parse(user)
}

export const setUser = async (user) => {
  await addLocalStorage('user', JSON.stringify(user))
}

export const getFirstName = (name: string): string => {
  const firstName = name.trim().split(' ')[0]
  return firstName.length > 10 ? `${firstName.slice(0, 10)}...` : firstName
}
