import React from 'react'
import { Flex } from '@chakra-ui/core'

type Props = {
  image: string
  color: string
}
const CardSplitScreen = ({ image, color }: Props): JSX.Element => {
  return (
    <Flex position="absolute" top={0} left={0} right={0} bottom={0} flexDir={'row-reverse'} flexGrow={[]}>
      <Flex w={['100%', '100%', '50%']} background={`url('${image}') no-repeat top center`} bgSize={'cover'} />
      <Flex display={['none', 'none', 'flex']} position="relative" bg={color} width={['100%', '100%', '50%']} />
    </Flex>
  )
}

export default CardSplitScreen
