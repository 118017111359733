import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

type LinkerProps = LinkProps & {
  action?: string
}

const Linker: React.FC<LinkerProps> = ({ to, action = '_self', children, className }) => {
  if (typeof to === 'string' && (to.indexOf('http') !== -1 || to.indexOf('www') !== -1)) {
    return (
      <a href={to || '/'} target={action || '_self'} rel="noopener noreferrer" className={className}>
        {children}
      </a>
    )
  }
  return (
    <Link to={to} target={action} className={className}>
      {children}
    </Link>
  )
}

export default Linker
