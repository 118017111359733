export const priceFormat = (value: number) => {
  return `R$ ${value.toFixed(2).replace('.', ',')}`
}

export const applyDiscount = (value: number, coupon: any): number => {
  if (!coupon) {
    return 0
  }

  switch (coupon.discountType) {
    case 'percentage':
      return value * (coupon.percentage / 100)
    case 'amount':
      return coupon.amount <= 0 ? 0 : coupon.amount
    default:
      return value
  }
}
