import React from 'react'
import { Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import customTheme from '../../../../styles/theme'

interface StepsDotsProps {
  currentDot: number
}

const StepsDots = ({ currentDot }: StepsDotsProps) => {
  const arr = [...new Array(4)]

  return (
    <Flex justifyContent="center" mb={8}>
      {arr.map((_item, idx) => (
        <BoxItem
          key={`step_${idx}`}
          className={(idx + 1) <= currentDot ? 'active' : ''}
        >{(idx + 1)}</BoxItem>
      ))}
    </Flex>
  )
}

export default StepsDots

const BoxItem = styled.div`
  width: 30px;
  font-weight: bold;
  height: 30px;
  border: 2px solid #c7c7c7;
  text-align: center;
  border-radius: 50%;
  color: #c7c7c7;
  position: relative;
  margin-right: 40px;
  &.active {
    border-color: ${customTheme.colors.green['700']};
    color: ${customTheme.colors.green['700']};
  }
  
  &:before {
    content: "→";
    position: absolute;
    left: 35px;
    font-size: 28px;
    color: #c7c7c7;
    top: 50%;
    line-height: 0;
    margin-top: -3px;
  }
  
  &:last-of-type {
    margin-right: 0;
    &::before{
      display: none;
    }
  }
`
