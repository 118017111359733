import { TestimonialGroupsToViewInterface } from '../../../domain/usecases/testimonial-groups-to-view/testimonial-groups-to-view'
import { BlockResultModel, TestimonialGroupsModel } from '../../../domain/models'
import { getImage } from '../../../utils/helpers/get-image'

export class TestimonialGroupsToView implements TestimonialGroupsToViewInterface {
  build ({ content }: BlockResultModel, type: string): TestimonialGroupsModel|null {
    if (type === 'php') {
      return {
        '@id': content['@id'],
        id: content.id,
        name: content.name,
        testimonials: content.testimonials.map((testimonial: any) => ({
          '@id': testimonial['@id'],
          author: testimonial.author,
          avatar: getImage(testimonial.avatar),
          companyJobRole: testimonial.companyJobRole,
          description: testimonial.description,
          rating: testimonial.rating,
          videoReference: testimonial.videoReference
        }))
      }
    }
    return null
  }
}

export const testimonialGroupsToView = new TestimonialGroupsToView()
