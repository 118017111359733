import React from 'react'
import { FormControl, InputCustom, InputError } from './styles'
import { FormLabel } from '@chakra-ui/core'
import { Field, ErrorMessage, FieldAttributes } from 'formik'

interface InputProps extends FieldAttributes<any>{
  title: string
  name: string
  placeholder?: string
  lowercase?: boolean
}

const Input = ({ name, title, placeholder, lowercase, ...el }: InputProps) => {
  return (
    <FormControl>
      <FormLabel htmlFor={name}>{title}</FormLabel>
      <Field
        name={name}
        id={name}
        placeholder={placeholder || ''}
        onChange={(val) => {
          if (el.setFieldValue) {
            el.setFieldValue(name, val.target.value)
          }
        }}
        as={InputCustom}
        style={{
          textTransform: lowercase ? 'lowercase' : 'none'
        }}
      />
      <ErrorMessage name={name} component={InputError} />
    </FormControl>
  )
}

export default Input
