import { Typography } from '../ui/foundation'
import { priceFormat } from '../../utils/helpers/price'
import React from 'react'
import { PlanItemModel } from '../../domain/models'
import { getStorePlanSession, setStorePlanSession } from '../../utils/helpers/store-plan-session'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/core'
import customTheme from '../../styles/theme'

interface PlanItemProps {
  item: PlanItemModel
  selectable: boolean
}

export const PlanItem = ({ item, selectable }: PlanItemProps): JSX.Element => {
  const handleCheck = async (e) => {
    const { value } = e.target
    const storePlan = getStorePlanSession()
    const planItems = storePlan.planItems

    if (planItems.includes(value)) {
      await setStorePlanSession({
        ...storePlan,
        planItems: planItems.filter(item => item !== value)
      })
    } else {
      const newPlanItem = planItems
      newPlanItem.push(value)
      await setStorePlanSession({
        ...storePlan,
        planItems: newPlanItem
      })
    }
  }

  return (
    <CustomBox key={item['@id']}>
      {selectable && (
        <>
          <input
            type="checkbox"
            id={`product-${item.product.id}`}
            value={item['@id']}
            defaultChecked={true}
            onClick={handleCheck}
          />
          <label htmlFor={`product-${item.product.id}`} />
        </>
      )}
      <Typography type="xl" as="h3" mb={2} color="purple.500">{item.product.name}</Typography>
      {item.product.description && (
        <Typography dangerouslySetInnerHTML={{ __html: item.product.description }} />
      )}
      {item.showPrice && item.price && (
        <Typography type="lg">{priceFormat(item.price)}</Typography>
      )}
    </CustomBox>
  )
}

const CustomBox = styled(Box)`
  position: relative;
  box-shadow: 0 5px 10px rgba(0,0,0,.3);
  padding: 20px;
  border-radius: 10px;
  border-left: 4px solid ${customTheme.colors.pink[200]};
  
  > input {
    display: none;
    
    &:checked ~ label {
      background: #3898ec;
    }  
  }
  
  > label {
    border: 2px solid #3898ec;
    background: #fff;
    color: #fff;
    width: 25px;
    height: 25px;
    display: block;
    line-height: 22px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    font-size: 18px;
    margin-bottom: 10px;
    
    &:before {
      content: "\\2713";
    }
  }
`
