import React from 'react'
import styled from '@emotion/styled/macro'
import { BlockResultModel, SimpleContentModel } from '../../../domain/models'
import { Container, Typography } from '../../ui/foundation'
import { PseudoBoxCustom } from '../../ui/layout'
import { AspectRatioBox, Box, Image } from '@chakra-ui/core'
import { simpleContentToView } from '../../../data/usecases/contents-to-view/simple-content-to-view'
import { Link } from 'react-router-dom'

import { ReactComponent as Play } from '../../ui/icons/play-solid-cycle.svg'

import customTheme from '../../../styles/theme'

const NextTraining = (props: BlockResultModel) => {
  const { block } = props
  const content: SimpleContentModel[] = simpleContentToView.build(props.content['hydra:member'])

  if (!content) {
    return null
  }

  return (
    <PseudoBoxCustom
      id={`block-${block.id} asdasdas`}
      pos="relative"
      py={[16, 24, 32]}
      background={'white url(/images/bg-rio.jpg) no-repeat center bottom'}
      backgroundSize="contain"
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: '#37dc96',
        bottom: -1,
        height: '35px',
        clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)'
      }}>
      <Container>
        <Box w={['100%', '80%', '80%']} mx="auto">
          <Typography
            type="4xl"
            as="h2"
            textAlign="center"
            mb={4}>{block.title}</Typography>
          <Typography
            type="sm"
            as={'div'}
            textAlign="center"
            mb={8}
            dangerouslySetInnerHTML={{ __html: block.description }}/>

          {content.map(item => (
            <BoxItem key={item['@id']} borderRadius="md" >
              <Link to={`/treino/${item.folder.slug}/${item.weekDay}`}>
                <AspectRatioBox ratio={16 / 9} borderRadius="md">
                  <Image objectFit="cover" src={item.thumb} alt={item.folder.title} />
                </AspectRatioBox>
                <MetaInfo>
                  <Play />
                  <Typography type="2xl" as="h3" mb={1} isTruncated>{item.folder.title}</Typography>
                </MetaInfo>
              </Link>
            </BoxItem>
          ))}
        </Box>
      </Container>
    </PseudoBoxCustom>
  )
}

export default NextTraining

const MetaInfo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(10deg, #313131 0%, transparent 40%);
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  svg {
    position: absolute;
    width: 70px;
    top: 50%;
    margin-top: -35px;
    left: 50%;
    margin-left: -35px;
    transition: color .3s ease;
    color: ${customTheme.colors.pink['200']};
  }
  
  &:hover {
    svg {
      color: ${customTheme.colors.green['700']};    
    }  
  }
`

const BoxItem = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.53);
  transition: transform 0.3s ease-in-out;
`
