import { addLocalStorage, getLocalStorage } from '../../../infra/storage/localStorage'

export const currentLastUpdated = (): string => {
  return new Date().toLocaleString().slice(0, 13)
}

export const getLastUpdated = (): string => {
  return getLocalStorage('lastUpdated')
}

export const setLastUpdated = async (): Promise<void> => {
  await addLocalStorage('lastUpdated', currentLastUpdated())
}

export const outdatedCache = async (): Promise<boolean> => {
  const updateCache = getLastUpdated() !== currentLastUpdated()

  if (updateCache) {
    await setLastUpdated()
  }

  return updateCache
}
