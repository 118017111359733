import React from 'react'
import { Button as ButtonChakra, ButtonProps } from '@chakra-ui/core'

export type ButtonUIProps = ButtonProps & {

}

const Button = ({ children, ...props }: ButtonUIProps): JSX.Element => {
  return (
    <ButtonChakra
      h={10}
      px={30}
      rounded="lg"
      fontSize="sm"
      {...props}
    >
      {children}
    </ButtonChakra>
  )
}

export const ButtonWhiteGreen = ({ children, ...props }: ButtonUIProps): JSX.Element => {
  return (
    <Button
      h={12}
      px={12}
      borderStyle="solid"
      borderWidth="1px"
      borderColor="white"
      bg="transparent"
      color="white"
      _hover={{
        bg: 'green.700',
        borderColor: 'green.700'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export const ButtonGreen = ({ children, ...props }: ButtonUIProps): JSX.Element => {
  return (
    <Button
      borderStyle="solid"
      borderWidth="1px"
      borderColor="gray.700"
      bg="green.700"
      color="gray.700"
      _hover={{
        bg: 'green.800'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export const ButtonRedBlack = ({ children, ...props }: ButtonUIProps): JSX.Element => {
  return (
    <Button
      bg="pink.200"
      color="white"
      _hover={{
        bg: 'black'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export const ButtonRedGreen = ({ children, ...props }: ButtonUIProps): JSX.Element => {
  return (
    <Button
      h={12}
      px={12}
      bg="pink.200"
      color="white"
      fontSize="md"
      _hover={{
        bg: 'green.700',
        color: 'gray.700'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
export const ButtonRedOutlineGreen = ({ children, ...props }: ButtonUIProps): JSX.Element => {
  return (
    <Button
      h={12}
      px={12}
      bg="white"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="pink.200"
      color="pink.200"
      fontSize="md"
      _hover={{
        bg: 'green.700',
        color: 'gray.700',
        borderColor: 'green.700'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export const ButtonRedPurple = ({ children, ...props }: ButtonUIProps): JSX.Element => {
  return (
    <Button
      h={12}
      px={12}
      bg="white"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="pink.500"
      color="pink.500"
      fontSize="md"
      _hover={{
        borderColor: 'purple.500',
        bg: 'purple.500',
        color: 'white'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
