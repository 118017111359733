import { ReactComponent as Instagram } from './brands-instagram.svg'
import { ReactComponent as Twitter } from './brands-twitter.svg'
import { ReactComponent as Facebook } from './brands-facebook.svg'
import { ReactComponent as Spotify } from './brands-spotify.svg'
import { ReactComponent as WhatsappBallon } from './brands-whatsapp-ballon.svg'

import React from 'react'
import { FlexProps } from '@chakra-ui/core'

type RenderIconProps = FlexProps & {
  name: string
}

export const RenderIcon = ({ name }: RenderIconProps): JSX.Element => {
  switch (name) {
    case 'instagram': return <Instagram />
    case 'twitter': return <Twitter />
    case 'facebook': return <Facebook />
    case 'spotify': return <Spotify />
    case 'whatsapp': return <WhatsappBallon />
    default: return null
  }
}
