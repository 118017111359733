import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import { Box, Grid } from '@chakra-ui/core'
import bg1 from '../../assets/images/bgs/bg-1.jpg'
import { Container, Typography } from '../../component/ui/foundation'
import { CardAuthor } from '../../component/ui/layout'
import { AuthorModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { makeTeachersList } from '../../main/factories/usecases/teachers/teachers-list'

const ListTeachers = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<AuthorModel[]>(null)

  useEffect(() => {
    (async () => {
      try {
        const content = await makeTeachersList()
        setData(content)
        setLoading(false)
      } catch (_e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <Loading/>
  }

  return (
    <CustomBox py={16}>
      <Container>
        <Typography type="4xl" as="h1" textAlign="center" textTransform="uppercase">Professores</Typography>

        <Grid templateColumns={['repeat(2, 1fr)', '', 'repeat(4, 1fr)']} gap={5} my={10}>
          {data?.map((author) => (
            <CardAuthor author={author} key={author['@id']} />
          ))}
        </Grid>
      </Container>
    </CustomBox>
  )
}

export default ListTeachers

const CustomBox = styled(Box)`
  background: ${`url(${bg1}) no-repeat top center`};
  background-size: cover;
`
