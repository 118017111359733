type WebexProps = {
  guestToken: string
  destinationId: string
  spaceId: string
}
export const initWebex = (widgetWebexId: string, params: WebexProps): void => {
  const widgetEl = document.getElementById(widgetWebexId)
  window.webex.widget(widgetEl).spaceWidget({
    ...params,
    destinationType: 'sip',
    spaceActivities: { files: false, meet: true, message: false, people: true },
    initialActivity: 'meet',
    secondaryActivitiesFullWidth: false,
    composerActions: { attachFiles: false },
    startCall: true
  })
}
