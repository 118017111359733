import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import customTheme from '../../styles/theme'
import FolderContent from '../../component/folder-content/folder-content'
import { Box } from '@chakra-ui/core'
import { Container, Typography } from '../../component/ui/foundation'
import { FolderContentModel } from '../../domain/models'
import { makeCurrentCustomerFolderContents } from '../../main/factories/usecases/current-customer/folder-contents'
import Loading from '../../component/loading/loading'

import bg from '../../assets/images/bgs/bg-0.jpg'

const FilteredTraining = ({ match }) => {
  const { slug } = match.params
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<FolderContentModel[]>(null)

  useEffect(() => {
    (async () => {
      try {
        const content = await makeCurrentCustomerFolderContents({
          folder: slug
        })
        setData(content)
        setLoading(false)
      } catch (_e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [slug])

  if (loading) {
    return <Loading/>
  }

  return (
    <BoxFolderItems>
      {data && data.length > 0 ? data?.map((item) => (
        <FolderContent item={item} key={item['@id']} />
      )) : (
        <Box py={16}>
          <Container>
            <Typography type="lg" as="p">Você não possui nenhum treino ativo</Typography>
          </Container>
        </Box>
      )}
    </BoxFolderItems>
  )
}

export default FilteredTraining

const BoxFolderItems = styled.div`
  > div {
    padding: 45px 0;
    @media (min-width: ${customTheme.breakpoints[2]}) {
      padding: 90px 0;
    }
    background: ${`url(${bg}) no-repeat top center`};
    background-size: cover;
  }
`
