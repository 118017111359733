import React from 'react'
import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay
} from '@chakra-ui/core'
import {
  AlertBoxContextInterface,
  useAlertBox
} from './alert-box-context'
import { ButtonRedGreen } from '../../foundation'

const AlertBox: React.FC = () => {
  const { content, open, setContent, setOpen, action, setAction }: AlertBoxContextInterface = useAlertBox()

  const onClose = (): void => {
    setOpen(false)
    setContent(null)
    if (action && typeof action === 'function') {
      action()
      setAction(null)
    }
  }

  if (!open) {
    return null
  }

  return (
    <>
      <AlertDialogOverlay onClick={onClose}/>
      <AlertDialogContent maxW={420}>
        <AlertDialogHeader/>
        <AlertDialogBody>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </AlertDialogBody>
        <AlertDialogFooter>
          <ButtonRedGreen onClick={onClose}>OK</ButtonRedGreen>
        </AlertDialogFooter>
      </AlertDialogContent>
    </>
  )
}

export default AlertBox
