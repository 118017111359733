import { createContext, useContext } from 'react'

type CouponContextProps = {
  setCoupon: any
  coupon: any
  handleCoupon: any
  couponActive: boolean
  couponDetails: any
}

export const CouponContext = createContext<CouponContextProps>(null)

export const useCoupon = (): CouponContextProps => {
  const context = useContext(CouponContext)
  if (!context) { throw new Error('useModal must be used within a provider CouponContext.Provider') }
  return ({ ...context })
}
