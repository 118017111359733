import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'

type PopUpContentsProps = { 'startAt[before]': string, 'endAt[after]' : string}

export const makePopUpContents = async (
  params: PopUpContentsProps
) => {
  try {
    const response = await api.get<AxiosResponse>('/popups', {
      params: {
        ...params
      }
    })
    return response.data['hydra:member']
  } catch (error) {
    return []
  }
}
