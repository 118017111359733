import React from 'react'
import { BlockResultModel, SimpleContentModel } from '../../../domain/models'
import { simpleContentToView } from '../../../data/usecases/contents-to-view/simple-content-to-view'
import { Container, Typography } from '../../ui/foundation'
import { Box } from '@chakra-ui/core'
import { CardFolderItem, Carousel } from '../../ui/layout'
import styled from '@emotion/styled'

const News = (props: BlockResultModel) => {
  const { block } = props
  const content: SimpleContentModel[] = simpleContentToView.build(props.content['hydra:member'])

  if (!content) {
    return null
  }

  return (
    <Box id={`block-${block.id}`} pos="relative" bg="green.700" py={[16, 24, 32]}>
      <Container>
        <Box w={['100%', '80%', '80%']} mx="auto">
          <Typography type="4xl" as="h2" textAlign="center" mb={4}>{block.title}</Typography>
          <Typography type="sm" textAlign="center" mb={8} dangerouslySetInnerHTML={{ __html: block.description }} />
        </Box>
        <BoxCustom>
          <Carousel
            centerMode
            infinite
            centerPadding="0"
            responsive={[
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 575,
                settings: {
                  slidesToShow: 1
                }
              }
            ]}
          >
            {content.map((item) => (
              <Box key={item['@id']} p={3}>
                <CardFolderItem
                  publicContent={{
                    '@id': item['@id'],
                    title: item.folder.title,
                    description: item.folder.description,
                    url: `/treino/${item.folder.slug}/${item.weekDay}`,
                    image: item.folder.thumb
                  }}
                />
              </Box>
            ))}
          </Carousel>
        </BoxCustom>
      </Container>
    </Box>
  )
}

export default News

const BoxCustom = styled.div`
  color: #ffffff;
  .slick-current {
    transform: scale(1.1);
  }
`
