import { SettingModel } from '../../../domain/models'
import { addLocalStorage, getLocalStorage } from '../../../infra/storage/localStorage'

export const getSettings = (): SettingModel[] => {
  const user = getLocalStorage('settings')
  return JSON.parse(user)
}

export const setSettings = async (settings): Promise<void> => {
  await addLocalStorage('settings', JSON.stringify(settings))
}
