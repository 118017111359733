import React from 'react'
import { Footer } from './index'
import Header from './header'

const BaseLayout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default BaseLayout
