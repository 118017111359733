import { LiveModel } from '../../../../domain/models'
import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'

export const makeLive = async (): Promise<LiveModel> => {
  try {
    const response: AxiosResponse = await api.get('/lives')
    const data = response.data['hydra:member'] || []
    const resToken: AxiosResponse = await api.get('/current_customer_webex_tokens')
    const dataToken = resToken.data['hydra:member'] || []

    if (data.length && dataToken.length) {
      return {
        ...data[0],
        token: dataToken[0].token
      }
    }
    return null
  } catch (e) {
    return null
  }
}
