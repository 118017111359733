export const toDateBr = (date: string): string => {
  return date.slice(0, 10).slice(0, 10).split('-').reverse().join('/')
}

export const getExpiredCard = (date: string): string => {
  return `${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`
}

export function getTrialsUntil (trialDays: number): string {
  const now = new Date()
  now.setDate(now.getDate() + trialDays - 1)
  return now.toLocaleDateString('pt-br')
}

export const secondsToHour = (time: number): string => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = time % 60

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}

export const changeWeekDayToIndexNumber = (weekday: number): number => {
  switch (weekday) {
    case 0: return 6 // d
    case 1: return 0 // s
    case 2: return 1 // t
    case 3: return 2 // q
    case 4: return 3 // q
    case 5: return 4 // s
    case 6: return 5 // s
  }
}
