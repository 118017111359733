import React from 'react'
import { Flex, Text } from '@chakra-ui/core'
import { Container, Typography } from '../../ui/foundation'
import { CardSplitScreen, PseudoBoxCustom } from '../../ui/layout'
import { BannerModel, BlockResultModel } from '../../../domain/models'
import { bannerToView } from '../../../data/usecases/banner-to-view/banner-to-view'

const Slider = (props: BlockResultModel): JSX.Element|null => {
  const content: BannerModel = bannerToView.build(props, 'php')

  if (!content && !content.images.length) {
    return null
  }
  const banner = content.images[0]

  return (
    <PseudoBoxCustom
      pos="relative" h={['100vh', '', 'inherit']}
      _after={{
        content: '""',
        position: 'absolute',
        bg: 'white',
        right: 0,
        bottom: -1,
        left: 0,
        height: 35,
        clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)'
      }}>
      <CardSplitScreen image={banner.image} color="gray.700"/>
      <Flex
        pos="relative"
        w="100%"
        bg={['rgb(0, 0, 0, 0.3)', '', 'transparent']}
        alignItems="center"
        h="100%">
        <Container>
          <Flex flexDir="column" w={['100%', '', '50%']} color="white" pr={[0, 10, 16]} py={20}>
            <Typography type="5xl" as="h2">{banner.title}</Typography>
            {banner.description && (
              <Text
                fontSize="lg"
                fontWeight="thin"
                lineHeight={1.2}
                as="div"
                dangerouslySetInnerHTML={{ __html: banner.description }}
              />
            )}
          </Flex>
        </Container>
      </Flex>
    </PseudoBoxCustom>
  )
}

export default Slider
