import React from 'react'
import { Auth, I18n } from 'aws-amplify'
import { ButtonRedGreen, Typography } from '../../component/ui/foundation'
import { Box } from '@chakra-ui/core'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { InputPassword } from '../../component/form-elements'
import { useHistory } from 'react-router'
import {
  AlertBoxContextInterface,
  useAlertBox
} from '../../component/ui/layout/alert-box/alert-box-context'
import { goToUrl } from '../../utils/helpers/go-to-url'
import { exceptionsError } from '../../utils/helpers/cognito-erros'
import { settings } from '../../config/settings'

const CreateNewPassword: React.FC = () => {
  const history = useHistory()
  const { alertBox }: AlertBoxContextInterface = useAlertBox()
  const states: {
    email?: string
    password?: string
  } = history.location.state
  const email: string = states ? states.email : ''
  const password: string = states ? states.password : ''

  const initialValues = {
    password: '',
    confirmPassword: ''
  }

  const onSubmit = async (values) => {
    try {
      const user = await Auth.signIn(email, password)
      try {
        await Auth.completeNewPassword(user, values.password)
        try {
          await Auth.signIn(email, values.password)
          goToUrl(history, settings.dashboardRoute)
        } catch (error) {
          goToUrl(history, '/login')
        }
      } catch (err) {
        alertBox(exceptionsError(err.code, err.message))
      }
    } catch (e) {
      goToUrl(history, '/login')
    }
  }

  return (
    <>
      <Box textAlign="center">
        <Typography type="4xl" as="h2" textTransform="uppercase">Cadastrar nova senha</Typography>
      </Box>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={
          Yup.object().shape({
            password: Yup.string()
              .required(I18n.get('ValidateRequiredField'))
              .min(8, I18n.get('ValidateMinPassword'))
              .max(24, I18n.get('ValidateMaxPassword'))
              .matches(/[a-z]/, I18n.get('ValidateOneLowerChar'))
              .matches(/[A-Z]/, I18n.get('ValidateOneUpperChar'))
              .matches(/[0-9]/, I18n.get('ValidateOneNumber'))
              .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, I18n.get('ValidateOneSpecialChar')),
            confirmPassword: Yup.string().when('password', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('password')], I18n.get('ValidatePasswordNotEquals'))
                .required(I18n.get('ValidateRequiredField'))
            })
          })
        }
        onSubmit={onSubmit}
      >
        {(actions) => (
          <Form>
            <InputPassword title="Nova Senha" name="password" {...actions} />
            <InputPassword title="Confirmar Senha" name="confirmPassword" {...actions} />

            <ButtonRedGreen
              type="submit"
              isFullWidth
              isLoading={actions.isSubmitting}
              loadingText="Cadastrando"
              isDisabled={actions.isSubmitting}
              mb={8}
            >
              Cadastrar
            </ButtonRedGreen>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateNewPassword
