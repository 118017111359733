import React from 'react'
import {
  BlockResultModel
} from '../../../domain/models'
import { Container, Typography } from '../../ui/foundation'
import { Box } from '@chakra-ui/core'
import { CardFolderItem, Carousel, PseudoBoxCustom } from '../../ui/layout'
import { publicContentToView } from '../../../data/usecases/public-content-to-view/public-content-to-view'

const Recommended = (props: BlockResultModel) => {
  const { block } = props
  const content = publicContentToView.build(props)

  if (!content) {
    return null
  }

  return (
    <PseudoBoxCustom
      id={`block-${block.id}`}
      pos="relative"
      bg="green.700"
      background={'white url(/images/bg-4.jpg) no-repeat center bottom'}
      backgroundSize="cover"
      py={[16, 24, 32]}
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'green.700',
        top: -1,
        height: '10%',
        clipPath: 'polygon(100% 0, 0 0, 100% 100%)'
      }}>
      <Container>
        <Box w={['100%', '80%', '80%']} mx="auto">
          <Typography type="4xl" as="h2" textAlign="center" mb={4}>{block.title}</Typography>
          <Typography type="sm" textAlign="center" mb={8} dangerouslySetInnerHTML={{ __html: block.description }} />
        </Box>
        <Carousel
          infinite={false}
          responsive={[
            {
              breakpoint: 9999,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]}
        >
          {content.map((item) => (
            <Box key={item['@id']} p={3}>
              <CardFolderItem
                publicContent={{
                  '@id': item['@id'],
                  title: item.title,
                  description: item.description,
                  previewUrl: '',
                  image: item.thumb,
                  url: `/treino/${item.slug}`
                }}
              />
            </Box>
          ))}
        </Carousel>
        {!content.length && (
          <Typography type="md" textAlign="center" mb={8}>
            Sem nenhum conteúdo para mostrar
          </Typography>
        )}
      </Container>
    </PseudoBoxCustom>
  )
}

export default Recommended
