import React from 'react'
import { BlockResultModel, FeatureGroupsModel, FeatureModel } from '../../../domain/models'
import styled from '@emotion/styled'
import { featureGroupsToView } from '../../../data/usecases/feature-group-to-view/feature-group-to-view'
import { ButtonRedGreen, Container, Typography } from '../../ui/foundation'
import { AspectRatioBox, Box, Flex, Image } from '@chakra-ui/core'
import { getVideoUrlEmbed } from '../../../utils/helpers/get-video-url-embed'

import bg0 from '../../../assets/images/bgs/bg-4.jpg'
import customTheme from '../../../styles/theme'

interface InnerAspectProps {
  feature: FeatureModel
}

const Services = (props: BlockResultModel): JSX.Element => {
  const content: FeatureGroupsModel = featureGroupsToView.build(props, 'php')
  const { block } = props

  if (!content) {
    return null
  }

  const InnerAspect = ({ feature }: InnerAspectProps) => {
    if (feature.link) {
      return <Iframe src={getVideoUrlEmbed(feature.link)}/>
    } else if (feature.image) {
      return <Image
        src={feature.image} alt={feature.title}
        objectFit="cover" pos="absolute"
        top={0} left={0} w="100%" h="100%"
      />
    }
    return <div />
  }

  return (
    <Box my={-16}>
      {content.features.map((feature, idx) => (
        <CustomBox key={feature['@id']} className={idx % 2 === 0 ? 'even' : 'odd'}>
          <Container>
            {idx === 0 && (<Typography type="4xl" as="h1" mb={12} textAlign="center">{block.title}</Typography>)}
            <Flex flexWrap="wrap" alignItems="center" mx={-2} flexDir={idx % 2 === 0 ? 'row' : 'row-reverse'}>
              <Box w={['100%', '100%', '50%']} px={2} pos="relative">
                <Typography type="2xl" as="h2" mb={8}>{feature.title}</Typography>
                {feature.description && (
                  <Typography mb={4} dangerouslySetInnerHTML={{ __html: feature.description }}/>
                )}
                <ButtonRedGreen>Em breve</ButtonRedGreen>
              </Box>
              <Box w={['100%', '100%', '50%']} px={2} >
                <AspectRatioBox ratio={16 / 9}>
                  <InnerAspect feature={feature} />
                </AspectRatioBox>
              </Box>
            </Flex>
          </Container>
        </CustomBox>
      ))}
    </Box>
  )
}

export default Services

const CustomBox = styled.div`
  background: ${`url(${bg0}) no-repeat top center`};
  background-size: cover;
  padding: 5rem 0;
  position: relative;
  z-index: 1;
  &.odd {
    background: ${customTheme.colors.gray['700']};
    color: #ffffff;
    z-index: 2;
  }
  &:before,
  &:after {
    content: '';
    background: ${customTheme.colors.gray['700']};
    position: absolute;
    left: 0;
    width: 100%;
    height: 45px;
  }
  &:before {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%);
    top: -45px;
  }
  &:after {
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    bottom: -45px;
  }
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
`
