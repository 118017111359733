import React, { FormEvent, useEffect, useState } from 'react'
import { AnamnesisModel } from '../../domain/models'
import { Redirect } from 'react-router'
import Loading from '../../component/loading/loading'
import {
  makeAnamnesis,
  sendAnamnesis
} from '../../main/factories/usecases/anamnesis/make-anamnesis'
import { ButtonRedGreen, Container, Typography } from '../../component/ui/foundation'
import { Box, FormLabel } from '@chakra-ui/core'
import { FormControl, InputCustom } from '../../component/form-elements/styles'
import { useAlertBox } from '../../component/ui/layout/alert-box/alert-box-context'
import { settings } from '../../config/settings'
import { StepsDots } from '../../component/ui/layout'

const Anamnesis = ({ match, history }): JSX.Element => {
  const { slug } = match.params
  const { alertBox } = useAlertBox()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<AnamnesisModel>(null)
  const [state, setState] = useState({})
  const [stateOthers, setStateOthers] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(() => {
    (async () => {
      try {
        const content = await makeAnamnesis({ slug })
        setData(content)
        setLoading(false)
      } catch (_e) {
        setData(null)
        setLoading(false)
      }
    })()
  }, [slug])

  if (loading) {
    return <Loading/>
  }

  if (!data) {
    return <Redirect to="/404" />
  }

  const handleChangeInt = (e: FormEvent<HTMLInputElement>) => {
    const { name, value, dataset, validationMessage } = e.currentTarget

    setErrors(prev => ({ ...prev, [name]: validationMessage }))
    setState(prev => ({
      ...prev,
      [name]: {
        anamnesis_question_id: parseInt(dataset.question, 10),
        open_answer: parseInt(value.replace(/\D/g, ''), 10)
      }
    }))
  }

  const handleChangeFloat = (e: FormEvent<HTMLInputElement>) => {
    const { validationMessage, name, value, dataset } = e.currentTarget
    setErrors(prev => ({ ...prev, [name]: validationMessage }))
    const val = parseFloat(value.replace(/[^,.0-9]/g, '').replace(/,/g, '.'))
    setState(prev => ({
      ...prev,
      [name]: {
        anamnesis_question_id: parseInt(dataset.question, 10),
        open_answer: (val % 1 === 0) ? val + 0.001 : val
      }
    }))
  }

  const handleChangeText = (e: FormEvent<HTMLInputElement>) => {
    const { validationMessage, name, value, dataset } = e.currentTarget
    setErrors(prev => ({ ...prev, [name]: validationMessage }))
    setState(prev => ({
      ...prev,
      [name]: {
        anamnesis_question_id: parseInt(dataset.question, 10),
        open_answer: value
      }
    }))
  }
  const handleChangeOptionalText = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setStateOthers(prev => ({
      ...prev,
      [name]: value || null
    }))
  }

  const handleChangeRadio = (e: FormEvent<HTMLInputElement>) => {
    const { validationMessage, name, value, dataset } = e.currentTarget
    setErrors(prev => ({ ...prev, [name]: validationMessage }))
    setState(prev => ({
      ...prev,
      [name]: {
        anamnesis_question_id: parseInt(dataset.question, 10),
        anamnesis_question_alternative_id: parseInt(value, 10)
      }
    }))
  }

  const handleChangeCheckbox = (e: FormEvent<HTMLInputElement>) => {
    const { validationMessage, name, value, dataset, checked } = e.currentTarget
    setErrors(prev => ({ ...prev, [name]: validationMessage }))
    setState(prev => ({
      ...prev,
      [name]: {
        anamnesis_question_id: parseInt(dataset.question, 10),
        anamnesis_question_alternative_id: parseInt(value, 10),
        checked: checked
      }
    }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = []
    Object.keys(state).forEach(key => {
      if ('checked' in state[key] && state[key].checked) {
        const obj = {
          anamnesis_question_id: state[key].anamnesis_question_id,
          anamnesis_question_alternative_id: state[key].anamnesis_question_alternative_id
        }

        if (key in stateOthers) {
          Object.assign(obj, { open_answer: stateOthers[key] })
        }

        form.push(obj)
      } else if (!('checked' in state[key])) {
        const obj = {
          ...state[key]
        }
        if (`optional-${key}-alternative-${state[key].anamnesis_question_alternative_id}` in stateOthers) {
          Object.assign(obj, { open_answer: stateOthers[`optional-${key}-alternative-${state[key].anamnesis_question_alternative_id}`] })
        }
        form.push(obj)
      }
    })

    const result = await sendAnamnesis(data.id, form)
    if (result) {
      alertBox('Cadastrada com sucesso')
      history.push(settings.dashboardRoute)
    } else {
      alertBox('Ocorreu um erro ao cadastrar sua anamnese')
    }
  }

  return (
    <Box py={16}>
      <Container>

        <StepsDots currentDot={settings.anamnesis_steps[slug]} />

        <Box w={['100%', '100%', '80%']} mx="auto">
          <Typography type="4xl" as="h1" mb={8} textAlign="center">{data.title}</Typography>
          {data.description && (
            <Typography type="md" mb={8} dangerouslySetInnerHTML={{ __html: data.description }} />
          )}

          <form onSubmit={handleSubmit}>
            {data.activeQuestions.map((question) => (
              <FormControl key={`question-${question.id}`}>
                <FormLabel htmlFor={`question-${question.id}`}>
                  {question.title}{question.verifiedRequirement && '*'}
                </FormLabel>

                {question.type === 'integer' && (
                  <InputCustom
                    type="number" id={`question-${question.id}`}
                    name={`anamnesis_question_id-${question.id}`}
                    data-question={question.id}
                    min={0} step={1} maxLength={3} onChange={handleChangeInt}
                    required={question.verifiedRequirement}
                  />
                )}

                {question.type === 'float' && (
                  <InputCustom
                    type="number" id={`question-${question.id}`}
                    name={`anamnesis_question_id-${question.id}`}
                    data-question={question.id}
                    min={0} step={0.01} maxLength={8} onChange={handleChangeFloat}
                    required={question.verifiedRequirement}
                  />
                )}

                {question.type === 'text' && (
                  <InputCustom
                    type="text" id={`question-${question.id}`}
                    name={`anamnesis_question_id-${question.id}`}
                    data-question={question.id}
                    onChange={handleChangeText}
                    required={question.verifiedRequirement}
                  />
                )}

                {question.type === 'choice' && !!(question.verifiedAllowedAnswers <= 1) && (
                  <>
                    {question.activeAlternatives.map((alternative) => (
                      <Box key={`${question.id}-${alternative.id}`}>
                        <FormLabel fontWeight="normal" htmlFor={`question-${question.id}-alternative-${alternative.id}`}>
                          <input
                            type="radio" id={`question-${question.id}-alternative-${alternative.id}`}
                            name={`anamnesis_question_id-${question.id}`}
                            value={alternative.id}
                            data-question={question.id}
                            data-complement={alternative.acceptComplement}
                            onChange={handleChangeRadio}
                          />{' '}{alternative.title}</FormLabel>
                        {alternative.acceptComplement && (
                          <InputCustom
                            type="text" id={`question-${question.id}-alternative-${alternative.id}`}
                            name={`optional-anamnesis_question_id-${question.id}-alternative-${alternative.id}`}
                            onChange={handleChangeOptionalText}
                          />
                        )}
                      </Box>
                    ))}
                  </>
                )}

                {question.type === 'choice' && !!(question.verifiedAllowedAnswers > 1) && (
                  <>
                    {question.activeAlternatives.map((alternative) => (
                      <Box key={`${question.id}-${alternative.id}`}>
                        <FormLabel fontWeight="normal" htmlFor={`question-${question.id}-alternative-${alternative.id}`}>
                          <input
                            type="checkbox" id={`question-${question.id}-alternative-${alternative.id}`}
                            name={`anamnesis_question_id-${question.id}-alternative-${alternative.id}`}
                            value={alternative.id}
                            data-question={question.id}
                            data-complement={alternative.acceptComplement}
                            onChange={handleChangeCheckbox}
                          />{' '}{alternative.title}</FormLabel>
                      </Box>
                    ))}
                  </>
                )}
                <div className="error">{errors[`anamnesis_question_id-${question.id}`]}</div>
              </FormControl>
            ))}

            <ButtonRedGreen type="submit">Enviar</ButtonRedGreen>

          </form>

        </Box>
      </Container>
    </Box>
  )
}

export default Anamnesis
