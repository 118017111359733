import { Auth } from 'aws-amplify'
import { api } from '../../../../infra/http/remote'
import { CustomerModel, MeModel, SettingModel } from '../../../../domain/models'
import { AxiosResponse } from 'axios'
import { profileToView } from '../../../../data/usecases/profile-to-view/profile-to-view'
import { outdatedCache } from '../../../../utils/helpers/storage/date'
import { getUser, setUser } from '../../../../utils/helpers/user'
import { getSettings, setSettings } from '../../../../utils/helpers/storage/settings'

export const makeWithAuth = async () => {
  try {
    const auth = await Auth.currentSession()
    const { email } = auth.getIdToken().decodePayload()
    const lastUpdated = await outdatedCache()
    const user = getUser()

    if (lastUpdated || !user || !user.info.answeredAllActiveForms || user.user?.email !== email) {
      const meResponse: AxiosResponse = await api.get('/me')
      const me: MeModel = meResponse.data
      const profileResponse: AxiosResponse = await api.get(me.user['@id'].replace('/v1/', '/'))
      const customer: CustomerModel = profileResponse.data

      const profile = profileToView.build({ me, profile: customer })
      await setUser(profile)
    }

    if (lastUpdated || !getSettings()) {
      const settingsResponse: AxiosResponse = await api.get('/settings')
      const settings: SettingModel[] = settingsResponse.data['hydra:member']
      await setSettings(settings)
    }
  } catch (e) {
    throw new Error('User no authenticated')
  }
}
