import React from 'react'
import { BlockResultModel, TestimonialGroupsModel } from '../../../domain/models'
import { testimonialGroupsToView } from '../../../data/usecases/testimonial-groups-to-view/testimonial-groups-to-view'
import { Box } from '@chakra-ui/core'
import { Typography, ButtonRedGreen } from '../../ui/foundation'
import { goToUrl } from '../../../utils/helpers/go-to-url'
import { useHistory } from 'react-router'
import TestimonialItem from './testimonial-item'

const TestimonialGroups = (props: BlockResultModel): JSX.Element|null => {
  const history = useHistory()
  const content: TestimonialGroupsModel|null = testimonialGroupsToView.build(props, 'php')
  const { block } = props

  if (!content) {
    return null
  }

  return (
    <Box
      background="url('/images/bg-block-testimonial.jpg') no-repeat top center"
      backgroundSize="cover"
      py={[16, 24, 32]}
      id={`block-${block.id}`}
    >
      <Box w={['90%', '', '80%']} mx="auto">
        <Box maxW={['100%', '', '600px']} mx="auto">
          <Typography type="4xl" as="h2" textAlign="center">{block.title} - Mudou</Typography>
          <Typography
            type="sm"
            as={'div'}
            textAlign="center"
            mb={10}
            dangerouslySetInnerHTML={{ __html: block.description }}/>
        </Box>

        {content.testimonials.map((testimonial, index) => (
          <TestimonialItem
            key={testimonial['@id']}
            index={index}
            testimonial={testimonial}
          />
        ))}
        <Box textAlign="center">
          <ButtonRedGreen onClick={() => goToUrl(history, '/cadastre-se')}>Quero ser Aluno</ButtonRedGreen>
        </Box>
      </Box>
    </Box>
  )
}

export default TestimonialGroups
