import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { clearStorage } from '../../utils/helpers/clear-storage'
import { useHistory } from 'react-router'
import queryString from 'query-string'

const Logout = (): JSX.Element|null => {
  const history = useHistory()
  const search = queryString.parse(history.location.search)
  const isAdmin = 'adm' in search

  useEffect(() => {
    (async () => {
      try {
        await Auth.signOut({
          global: true
        })
        clearStorage()
      } catch (e) {
        clearStorage()
      }
      if (isAdmin) {
        history.replace('/login?adm=true')
      } else {
        history.replace('/')
      }
    })()
  }, [history, isAdmin])

  return null
}

export default Logout
