import React, { useEffect, useState } from 'react'
import { PageResultModel } from '../domain/models'
import Loading from '../component/loading/loading'
import { makePage } from '../main/factories/usecases/page/page'

const Privacy = () => {
  const [content, setContent] = useState<PageResultModel>(null)
  useEffect(() => {
    (async () => {
      try {
        const data = await makePage('politica-de-privacidade')
        setContent(data)
      } catch (e) {
        throw new Error('Ocorreu um erro ao processar a página')
      }
    })()
  }, [])

  if (!content) {
    return <Loading />
  }

  return <div dangerouslySetInnerHTML={{ __html: content.content }} />
}

export default Privacy
