import React, { useState } from 'react'
import { BlockResultModel, FaqModel } from '../../../domain/models'
import { faqToView } from '../../../data/usecases/faq-to-view/faq-to-view'
import styled from '@emotion/styled'
import { Typography } from '../../ui/foundation'
import customTheme from '../../../styles/theme'
import { ReactComponent as AngleDownLight } from '../../ui/icons/angle-down-light.svg'
import { ReactComponent as AngleRightLight } from '../../ui/icons/angle-right-light.svg'

const Faq = ({ content }: BlockResultModel): JSX.Element|null => {
  const faqs: FaqModel[] = faqToView.build(content)
  const [toggle, setToggle] = useState<number>(0)

  const handleToggle = (id: number) => {
    setToggle((prevValue) => (prevValue !== id ? id : 0))
  }
  if (!faqs) {
    return null
  }

  return (
    <>
      {faqs.map((faq, index) => (
        <Wrap key={faq['@id']}>
          <Question onClick={() => handleToggle(faq.id)}>
            <Number>{index + 1}</Number>
            <Typography type="lg" as="h3" mb={0} fontWeight="normal">{faq.question}</Typography>
            <QuestionIcon>
              {faq.id === toggle ? (
                <AngleRightLight />
              ) : (
                <AngleDownLight />
              )}
            </QuestionIcon>
          </Question>
          <Answer className={faq.id === toggle ? 'in' : ''} dangerouslySetInnerHTML={{ __html: faq.answer }} />
        </Wrap>
      ))}
    </>
  )
}

export const Wrap = styled.div`
  border: 1px solid ${customTheme.colors.gray['300']};
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
`

export const Question = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const Number = styled.div`
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid ${customTheme.colors.gray['300']};
  border-radius: 50%;
  margin-right: 15px;
`

export const QuestionIcon = styled.div`
  margin-left: auto;
`

export const Answer = styled.div`
  padding-left: 45px;
  font-size: 16px;
  margin-top: 30px;
  display: none;
  &.in{
    display: block;
  }
`

export default Faq
