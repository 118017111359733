import React, { createContext, useContext } from 'react'

export interface ModalContextInterface {
  open: boolean
  setOpen: any
  content: React.ReactNode
  setContent: any
  alertBox: any
}

const ModalContext = createContext<ModalContextInterface|null>(null)

export const ModalProvider:React.FC = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [content, setContent] = React.useState<JSX.Element|string>('')

  const alertBox = (content: any): void => {
    setOpen(true)
    setContent(content)
  }
  return (
    <ModalContext.Provider value={{
      open,
      setOpen,
      content,
      setContent,
      alertBox
    }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = ():ModalContextInterface => {
  const context = useContext(ModalContext)

  if (!context) { throw new Error('useModal must be used within a provider ModalProvider') }

  return {
    ...context
  }
}
