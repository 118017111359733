import { AuthorModel } from '../../../../domain/models'
import { authorToView } from '../../../../data/usecases/author-to-view/author-to-view'
import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'

type FilteredTeacher = {
  slug: string
  perPage: number
}

export const makeTeachersList = async (params?: FilteredTeacher): Promise<AuthorModel[]> => {
  try {
    const response: AxiosResponse = await api.get('/authors', {
      params: {
        ...params
      }
    })
    return authorToView.build(response.data)
  } catch (error) {
    return []
  }
}
