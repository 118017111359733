import React from 'react'
import { FormControl, InputCustom, InputError } from './styles'
import { FormLabel } from '@chakra-ui/core'
import { Field, ErrorMessage, FieldAttributes } from 'formik'
import InputMask from 'react-input-mask'

interface InputProps extends FieldAttributes<any>{
  title: string
  name: string
  mask: string
  placeholder?: string
}

const InputMasked = ({ name, title, placeholder, mask, ...el }: InputProps) => {
  return (
    <FormControl>
      <FormLabel htmlFor={name}>{title}</FormLabel>
      <Field name={name}>
        {({ field }) => (
          <InputMask
            {...field}
            placeholder={placeholder || ''}
            maskChar=""
            mask={mask}
            onChange={(val: any) => {
              if (el.setFieldValue) {
                el.setFieldValue(name, val.target.value)
              }
            }}
          >
            {(inputProps: any) => <InputCustom {...inputProps} />}
          </InputMask>
        )}
      </Field>
      <ErrorMessage name={name} component={InputError} />
    </FormControl>
  )
}

export default InputMasked
