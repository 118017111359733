import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Input, InputCreditCard, InputMasked } from '../form-elements'
import { Box, Checkbox, Flex, FormLabel, Image } from '@chakra-ui/core'
import styled from '@emotion/styled'
import cardsFlags from '../../assets/images/bandeiras.png'
import { isCPF } from '../../utils/yup/identity'
import { ButtonRedGreen } from '../ui/foundation'
import { registerPayment } from '../../main/factories/usecases/payment/payment'
import { useAlertBox } from '../ui/layout/alert-box/alert-box-context'
import { useHistory } from 'react-router'
import { settings } from '../../config/settings'
import { FormControl, SelectCustom } from '../form-elements/styles'
import { priceFormat } from '../../utils/helpers/price'
import Coupon from './coupon'
import { useModal } from '../ui/layout'

interface PaymentMethodsProps {
  hasCoupon?: boolean
  installments?: number
  currentPrice?: number
  hasUpdate?: boolean
  handleReloadPage?: () => void
}
const PaymentMethods = ({ hasCoupon, installments, currentPrice, hasUpdate, handleReloadPage }: PaymentMethodsProps): JSX.Element => {
  const history = useHistory()
  const { alertBox } = useAlertBox()
  const { setOpen, alertBox: alertModal } = useModal()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [currentInstallments, setCurrentInstallments] = useState<number>(1)
  const initialValues = {
    cpf: '',
    cardNumber: '',
    cardName: '',
    dateExp: '',
    cvv: ''
  }

  const handleInstallments = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentInstallments(parseInt(event.currentTarget.value))
  }

  const Installments = (): JSX.Element => {
    const num = [...new Array(installments)]

    return (
      <>
        {num.map((_n, i) => (
          <option key={i} value={i + 1}>{i + 1}x de {priceFormat(currentPrice / (i + 1))}</option>
        ))}
      </>
    )
  }

  Yup.addMethod(Yup.string, 'cpf', function (message) {
    return Yup.mixed().test('cpf', message, (value) => isCPF(value))
  })

  const onSubmit = async (values) => {
    const opts = {
      customerIdentity: values.cpf
    }

    if (installments) {
      Object.assign(opts, {
        installments: currentInstallments
      })
    }
    const response = await registerPayment({ creditCard: values }, opts, hasUpdate)
    if (hasUpdate) {
      setOpen(false)
      handleReloadPage()
    }
    console.error(response)
    if (typeof response === 'string') {
      alertBox(response, () => history.replace(settings.dashboardRoute))
    } else {
      if ('status' in response && response.status === 201) {
        alertBox(response.message, history.replace(settings.dashboardRoute))
      } else if ('code' in response && response.code === 5) {
        alertBox(response.message)
      } else {
        alertBox(JSON.stringify(response))
      }
    }
  }

  const hasDisabled = (isSubmitting) => {
    return (!hasUpdate && !acceptTerms) ? !acceptTerms : isSubmitting
  }

  const openTerms = () => {
    alertModal(
      <Box p={8}>
        <iframe width="100%" height="500px" src="/termos-e-condicoes" title="Termos e Condições" />
      </Box>
    )
  }

  return (
    <Box>
      <Box>
        <Image src={cardsFlags} alt="Bandeiras dos cartões" maxW={200} ml="auto" />
        <Box textAlign="right" color="green.700">
          <p>Não aceitamos cartões virtuais e pagamentos via boletos bancários</p>
        </Box>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          cpf: Yup.string().required(I18n.get('ValidateRequiredField')).cpf('CPF Inválido'),
          cardNumber: Yup.string().required(I18n.get('ValidateRequiredField')),
          cardName: Yup.string().required(I18n.get('ValidateRequiredField')),
          dateExp: Yup.string().required(I18n.get('ValidateRequiredField')),
          cvv: Yup.string().required(I18n.get('ValidateRequiredField'))
        })}
      >
        {(actions) => (
          <Form>
            <InputMasked mask="999.999.999-99" title="CPF" name="cpf" {...actions} />
            <InputCreditCard title="Número do cartão" name="cardNumber" {...actions} />

            <Input title="Nome igual ao cartão" name="cardName" {...actions} />

            <FlexCustom justifyContent="space-between">
              <Box w={['100%', '100%', installments && installments > 1 ? '25%' : '50%']}>
                <InputMasked mask="99/9999" title="MM/AAAA" name="dateExp" {...actions} />
              </Box>
              <Box w={['100%', '100%', installments && installments > 1 ? '25%' : '50%']}>
                <InputMasked mask="9999" title="CVV" name="cvv" {...actions} />
              </Box>
              {installments && installments > 1 && (
                <Box w={['100%', '100%', '50%']}>
                  <FormControl>
                    <FormLabel htmlFor="installments">Número de Parcelas</FormLabel>
                    <SelectCustom name="installments" id="installments" value={currentInstallments} onChange={handleInstallments}>
                      <Installments />
                    </SelectCustom>
                  </FormControl>
                </Box>
              )}
            </FlexCustom>

            {hasCoupon && (
              <Coupon />
            )}

            {!hasUpdate && (
              <Checkbox variantColor="green" onChange={() => setAcceptTerms(prev => !prev)} defaultIsChecked={acceptTerms}>
                Li e aceito todos os <GoToTerms onClick={openTerms}>termos e condições</GoToTerms> de adesão de aluno
              </Checkbox>
            )}

            <Box textAlign="center">
              <ButtonRedGreen
                mt={8}
                type="submit"
                isLoading={actions.isSubmitting}
                loadingText="Registrando"
                isDisabled={hasDisabled(actions.isSubmitting)}
              >CONTINUAR</ButtonRedGreen>
            </Box>

          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default PaymentMethods

const FlexCustom = styled(Flex)`
  margin-left: -10px;
  margin-right: -10px;
  > div {
    padding: 0 10px;
  }
`

const GoToTerms = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: #2576bf;
`
