import React from 'react'
import { BlockResultModel } from '../../../domain/models'
import { PseudoBoxCustom } from '../../ui/layout'
import { Typography } from '../../ui/foundation'
import { Box, Flex, Image } from '@chakra-ui/core'
import B1 from './images/bloco1.png'
import B2 from './images/bloco2.png'
import B3 from './images/bloco3.png'
import styled from '@emotion/styled'

const TryItFree: React.FC<BlockResultModel> = ({ block }: BlockResultModel) => {
  const items = [
    {
      icon: B1,
      text: 'Escolha qualquer um dos planos'
    }, {
      icon: B2,
      text: 'Comece a treinar grátis imediatamente'
    }, {
      icon: B3,
      text: 'uma semana para curtir e se apaixonar'
    }
  ]

  return (
    <PseudoBoxCustom
      pos="relative"
      py={16}
      bg="green.700"
      color="white"
      _before={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'green.700',
        top: '-33px',
        height: '35px',
        clipPath: 'polygon(0% 100%,100% 100%,0% 0%)',
        zIndex: 9
      }}
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'green.700',
        bottom: '-33px',
        height: '35px',
        clipPath: 'polygon(110% 0%,100% 100%,100% 0%)'
      }}>
      <Box w={['90%', '', '80%']} mx="auto">
        <Typography
          type="4xl"
          as="h2"
          textAlign="center"
          textTransform={'uppercase'}
          mb={4}>{block.title}</Typography>
        <Typography
          type="sm"
          as={'div'}
          textAlign="center"
          mb={8}
          dangerouslySetInnerHTML={{ __html: block.description }}/>
        <Flex flexWrap="wrap" mb={[4, 16]} mx={-2} justifyContent="center">
          {items.map((item, index) => (
            <Box key={index} w={['50%', '33.3333%']} px={2} mb={4}>
              <Item active={index !== items.length - 1}>
                <Image src={item.icon} ignoreFallback alt={item.text} />
                <Title>{item.text}</Title>
              </Item>
            </Box>
          ))}
        </Flex>
      </Box>
    </PseudoBoxCustom>
  )
}

export default TryItFree

const Item = styled.div<{
  active: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 45px;

    &:after {
      ${({ active }) => active && ({
        content: '""',
        position: 'absolute',
        right: '-16px',
        top: '50%',
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid #fff',
        marginTop: '-8px'
      })}  
    }
  }
`

const Title = styled.div`
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 20px;
    width: 150px;
    margin: 0 auto;
  }
`
