import React, { useEffect, useState } from 'react'
import { Container } from '../../component/ui/foundation'
import { Box, Grid } from '@chakra-ui/core'
import NavProfile from '../../component/nav-profile/nav-profile'
import { CurrentPlanModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { makeCurrentPlan } from '../../main/factories/usecases/subscriptions/current-plans'
import { CardMyPlanItem } from '../../component/ui/layout/plan/card-my-plan-item'

const MyPlans = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<CurrentPlanModel[]>(null)
  const [reload, setReload] = useState<boolean>(false)

  const handleReloadPage = (): void => {
    setReload(prev => !prev)
  }

  useEffect(() => {
    (async () => {
      try {
        const content = await makeCurrentPlan()
        setData(content)
        setLoading(false)
      } catch (_e) {
        setData(null)
        setLoading(false)
      }
    })()
  }, [reload])

  if (loading) {
    return <Loading />
  }

  return (
    <Box py={8}>
      <Container>
        <Grid templateColumns={['1fr', '1fr', '1fr 4fr']} gap={5} my={10}>
          <Box>
            <NavProfile urlActive={'/meus-planos'} />
          </Box>
          <Box>
            <Grid templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']} gap={5}>
              {data.map(plan => (
                <CardMyPlanItem key={plan['@id']} plan={plan} handleReloadPage={handleReloadPage} />
              ))}
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default MyPlans
