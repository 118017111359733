import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Box, Checkbox, FormLabel } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { isCPF } from '../../utils/yup/identity'
import { ButtonRedGreen } from '../ui/foundation'
import { useAlertBox } from '../ui/layout/alert-box/alert-box-context'
import { useHistory } from 'react-router'
import { FormControl, SelectCustom } from '../form-elements/styles'
import { priceFormat } from '../../utils/helpers/price'
import Coupon from './coupon'
import { useModal } from '../ui/layout'
import { useCoupon } from './coupon-context'
import { makeCurrentPlan, makeChangePlan } from '../../main/factories/usecases/subscriptions/current-plans'

interface ChangePlanMethodsProps {
  hasCoupon?: boolean
  installments?: number
  currentPrice?: number
  hasUpdate?: boolean
}
const ChangePlanMethods = ({ hasCoupon, installments, currentPrice, hasUpdate }: ChangePlanMethodsProps): JSX.Element => {
  const history = useHistory()
  const { alertBox } = useAlertBox()
  const { alertBox: alertModal } = useModal()
  const [loading, setLoading] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [currentInstallments, setCurrentInstallments] = useState<number>(1)
  const [currentPlan, setCurrentPlan] = useState({ id: null })
  const { couponDetails } = useCoupon()

  const handleInstallments = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentInstallments(parseInt(event.currentTarget.value))
  }

  const Installments = (): JSX.Element => {
    const num = [...new Array(installments)]

    return (
      <>
        {num.map((_n, i) => (
          <option key={i} value={i + 1}>{i + 1}x de {priceFormat(currentPrice / (i + 1))}</option>
        ))}
      </>
    )
  }

  Yup.addMethod(Yup.string, 'cpf', function (message) {
    return Yup.mixed().test('cpf', message, (value) => isCPF(value))
  })

  const onSubmit = async () => {
    setLoading(true)

    try {
      const body = {
        plan: JSON.parse(localStorage.getItem('planItem')).plan
      }

      if (installments) Object.assign(body, { installments: currentInstallments })
      if (couponDetails) Object.assign(body, { coupon: couponDetails['@id'] })

      await makeChangePlan(currentPlan?.id, body)
      alertBox('Plano alterado com sucesso', () => history.replace('/meus-planos'))
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const openTerms = () => {
    alertModal(
      <Box p={8}>
        <iframe width="100%" height="500px" src="/termos-e-condicoes" title="Termos e Condições" />
      </Box>
    )
  }

  useEffect(() => {
    (async () => {
      try {
        const plan = await makeCurrentPlan()
        setCurrentPlan(plan[0])
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  return (
    <Box>
      {installments && installments > 1 && (
        <Box w={['100%', '100%']}>
          <FormControl>
            <FormLabel htmlFor="installments">Número de Parcelas</FormLabel>
            <SelectCustom name="installments" id="installments" value={currentInstallments} onChange={handleInstallments}>
              <Installments />
            </SelectCustom>
          </FormControl>
        </Box>
      )}

      {hasCoupon && (
        <Coupon />
      )}

      {!hasUpdate && (
        <Checkbox variantColor="green" onChange={() => setAcceptTerms(prev => !prev)} defaultIsChecked={acceptTerms}>
          Li e aceito todos os <GoToTerms onClick={openTerms}>termos e condições</GoToTerms> de adesão de aluno
        </Checkbox>
      )}

      <Box textAlign="center">
        <ButtonRedGreen
          mt={8}
          type="submit"
          onClick={onSubmit}
          isLoading={loading}
          loadingText="Registrando"
          isDisabled={!acceptTerms}
        >CONTINUAR</ButtonRedGreen>
      </Box>
    </Box>
  )
}

export default ChangePlanMethods

const GoToTerms = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: #2576bf;
`
