import React from 'react'
import { Auth, I18n } from 'aws-amplify'
import { ButtonRedGreen, Typography } from '../../component/ui/foundation'
import { Box, Flex } from '@chakra-ui/core'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Input, InputMasked, InputPassword } from '../../component/form-elements'
import { Link } from 'react-router-dom'
import {
  AlertBoxContextInterface,
  useAlertBox
} from '../../component/ui/layout/alert-box/alert-box-context'
import queryString from 'query-string'
import { settings } from '../../config/settings'
import { exceptionsError } from '../../utils/helpers/cognito-erros'

const PasswordRecovery = ({ history }) => {
  const query = queryString.parse(history.location.search)
  const state = history.location.state
  const email = state.email || query.email as string
  const initialValues = {
    email: email || '',
    code: query.code || '',
    password: '',
    confirmPassword: ''
  }
  const { alertBox }: AlertBoxContextInterface = useAlertBox()

  return (
    <>
      <Box textAlign="center">
        <Typography type="4xl" as="h2" textTransform="uppercase">Recuperar senha</Typography>
      </Box>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField')),
            code: Yup.string().required(I18n.get('ValidateRequiredField')).matches(/[0-9]/, I18n.get('ValidateOnlyNumbers')),
            password: Yup.string()
              .required(I18n.get('ValidateRequiredField'))
              .min(8, I18n.get('ValidateMinPassword'))
              .max(24, I18n.get('ValidateMaxPassword'))
              .matches(/[a-z]/, I18n.get('ValidateOneLowerChar'))
              .matches(/[A-Z]/, I18n.get('ValidateOneUpperChar'))
              .matches(/[0-9]/, I18n.get('ValidateOneNumber'))
              .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, I18n.get('ValidateOneSpecialChar')),
            confirmPassword: Yup.string().when('password', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('password')], I18n.get('ValidatePasswordNotEquals'))
                .required(I18n.get('ValidateRequiredField'))
            })
          })
        }
        onSubmit={async (values) => {
          const email = values.email.toLowerCase()
          const code = values.code.toString()
          const { password } = values

          try {
            await Auth.forgotPasswordSubmit(email, code, password)
            alertBox(I18n.get('PasswordUpdatedSuccess'))
            await Auth.signIn(email, password)
            history.replace(settings.dashboardRoute)
          } catch (error) {
            alertBox(exceptionsError(error.code, error.message))
          }
        }}
      >
        {(actions) => (
          <Form>
            <Input title="Email" name="email" defaultValue={initialValues.email} {...actions}/>
            <InputMasked title="Código" name="code" mask="999999" defaultValue={initialValues.code} {...actions} />
            <InputPassword title="Senha" name="password" {...actions} />
            <InputPassword title="Confirmar senha" name="confirmPassword" {...actions} />
            <ButtonRedGreen
              type="submit"
              isFullWidth
              isLoading={actions.isSubmitting}
              loadingText="Criando..."
              isDisabled={actions.isSubmitting}
              mb={8}
            >Criar nova senha</ButtonRedGreen>
          </Form>
        )}
      </Formik>

      <Flex justifyContent={'space-around'} mb={8}>
        <Box><Link to="/login">Fazer login</Link></Box>
        <Box><Link to="/esqueci-minha-senha">Reenviar código</Link></Box>
      </Flex>
    </>
  )
}

export default PasswordRecovery
