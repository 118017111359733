import Payment from 'payment'
import { getSettings } from '../../../../utils/helpers/storage/settings'
import { vindiErrors, vindiPayment } from '../../../../infra/payment/vindi'
import { api } from '../../../../infra/http/remote'
import { getStorePlanSession } from '../../../../utils/helpers/store-plan-session'
import { getLocalStorage } from '../../../../infra/storage/localStorage'
import { PaymentProfilesModel } from '../../../../domain/models'

interface PaymentMethodProps {
  creditCard?: {
    cpf?: string
    cardNumber: string
    cardName: string
    dateExp: string
    cvv: string
  }
}

const getFlagCreditCard = (cardNumber: string): string => {
  const flag = Payment.fns.cardType(cardNumber.replace(/\D/g, ''))
  switch (flag) {
    case 'mastercard': return 'mastercard'
    case 'visa': return 'visa'
    case 'elo': return 'elo'
    case 'amex': return 'american_express'
    case 'hipercard': return 'hipercard'
    case 'dinersclub': return 'diners_club'
    case 'jcb': return 'jcb'
    default: return null
  }
}
const isValidCreditCard = (cardNumber: string): boolean => {
  return !!getFlagCreditCard(cardNumber)
}

const getVindiSettings = (): any => {
  try {
    const settings = getSettings()
    const vindi = settings.filter(setting => setting.section === 'vindi')
    const obj = {}
    vindi.forEach(item => {
      Object.assign(obj, {
        [item.name]: item.value
      })
    })
    return obj
  } catch (_e) {
    return null
  }
}

type checkoutFinishProps = {
  customerToken?: string
  customerIdentity?: string
  customerPhone?: string
  installments?: number,
  trial?: boolean
}

const checkoutUpdate = async (gatewayToken: string): Promise<any> => {
  return await api.post('/payment_profiles', {
    gatewayToken
  })
}

const checkoutFinish = async (values: checkoutFinishProps): Promise<any> => {
  try {
    const coupon = JSON.parse(getLocalStorage('coupon'))
    const plan = getStorePlanSession()
    const data = { ...values }
    if (values.installments) {
      Object.assign(data, { installments: values.installments || 1 })
    }

    if (coupon) {
      Object.assign(data, { coupon: coupon['@id'] })
    }

    if (plan.plan) {
      Object.assign(data, { plan: plan.plan })
      if ('planItems' in plan && plan.planItems.length) {
        Object.assign(data, { planItems: plan.planItems })
      }

      const response = await api.post('/checkout', data)

      return response.data.message
    } else {
      return 'Erro ao registrar plano'
    }
  } catch (_e) {
    return 'Erro ao finalizar o pedido'
  }
}

type registerPaymentProps = {
  trial?: boolean,
  customerIdentity?: string
  customerPhone?: string
  installments?: number
}

export const registerPayment = async (payment: PaymentMethodProps, values: registerPaymentProps, hasUpdate: boolean): Promise<any> => {
  if (values.trial) {
    try {
      return await checkoutFinish({
        installments: values.installments
      })
    } catch (e) {
      return {
        code: 5,
        message: 'Ocorreu um erro ao registrar o pagamento em nossa plataforma'
      }
    }
  } else if ('creditCard' in payment) {
    const { creditCard } = payment
    if (isValidCreditCard(creditCard.cardNumber)) {
      const settingVindi = getVindiSettings()
      if (settingVindi) {
        try {
          const flag = getFlagCreditCard(creditCard.cardNumber)
          if (flag) {
            try {
              const vindi = await vindiPayment(settingVindi.vindi_api_url, {
                holder_name: creditCard.cardName.toUpperCase(),
                card_expiration: creditCard.dateExp.trim(),
                card_number: creditCard.cardNumber.replace(/\D/g, ''),
                card_cvv: creditCard.cvv.replace(/\D/g, ''),
                payment_company_code: flag,
                payment_method_code: 'credit_card'
              }, settingVindi.vindi_public_key)

              const gatewayToken = vindi.data.payment_profile.gateway_token
              if (hasUpdate) {
                try {
                  const response = await checkoutUpdate(gatewayToken)
                  const message = []
                  if ('chargedSubscriptions' in response.data) {
                    response.data.chargedSubscriptions.forEach(item => {
                      message.push(item.message)
                    })
                  }
                  if (!message.length) {
                    return 'Novo cartão de crédito foi adicionado'
                  }
                  return message.join('\n')
                } catch (e) {
                  return {
                    code: 5,
                    message: 'Erro ao registrar o cartão'
                  }
                }
              } else {
                try {
                  return await checkoutFinish({
                    customerToken: gatewayToken,
                    customerIdentity: values.customerIdentity.replace(/\D/g, ''),
                    installments: values.installments
                  })
                } catch (e) {
                  return {
                    code: 5,
                    message: 'Ocorreu um erro ao registrar o pagamento em nossa plataforma'
                  }
                }
              }
            } catch (e) {
              return {
                code: 5,
                message: 'Ocorreu um erro, verifique os dados inseridos.'
              }
            }
          } else {
            return {
              code: 5,
              message: 'Bandeira de cartão não suportada'
            }
          }
        } catch (e) {
          return vindiErrors(e)
        }
      }
      return {
        code: 5,
        message: 'As configurações de pagamento não estão corretas'
      }
    }
    return {
      code: 5,
      message: 'Cartão de crédito inválido'
    }
  }
}

export const getPaymentProfiles = async (): Promise<PaymentProfilesModel[]> => {
  try {
    const response = await api.get('/payment_profiles')
    return response.data['hydra:member']
  } catch (_e) {
    return []
  }
}
