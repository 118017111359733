import { BlockModel, BlockResultModel } from '../../../../domain/models'
import { api } from '../../../../infra/http/remote'

export const makeBlock = async (block: BlockModel): Promise<BlockResultModel|any> => {
  let blockContent: BlockModel = null
  try {
    if (block.endpoint) {
      const response = await api.get(block.endpoint.replace('/v1/', '/'))
      blockContent = response.data
    }

    return {
      position: block.position,
      container: block.container,
      componentName: block.component.name,
      content: blockContent,
      block: {
        id: block.id,
        title: block.title,
        description: block.description || null
      }
    }
  } catch (_e) {
    return null
  }
}
