import React from 'react'
import { BlockResultModel } from '../../domain/models'
import { sortBy } from '../../utils/helpers'
import ComponentFactory from '../component-factory/component-factory'

type LoadBlocksProps = {
  containers: BlockResultModel[]
  locale: string
  sort?: string
}

const LoadBlocks = ({ containers, locale, sort = 'position' }: LoadBlocksProps): JSX.Element => {
  return (
    <>
      {containers
        .filter(({ container }) => container === locale)
        .sort(sortBy(sort))
        .map((block, index) => (
          <ComponentFactory key={`${locale}-${index}`} {...block} />
        ))
      }
    </>
  )
}

export default LoadBlocks
