import React, { useCallback, useState } from 'react'
import { ProfileModel } from '../../domain/models'
import { AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogOverlay, Avatar, Box, Flex } from '@chakra-ui/core'

import { ReactComponent as IconCamera } from '../ui/icons/camera-solid.svg'
import styled from '@emotion/styled'

import { getRotatedImage, ORIENTATION_TO_ANGLE, readFile } from './utils'
import { getOrientation } from 'get-orientation/browser'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'

import { ButtonRedGreen } from '../ui/foundation'
import { useAlertBox } from '../ui/layout/alert-box/alert-box-context'
import getCroppedImg from './crop-image'
import { setUser } from '../../utils/helpers/user'
import { api } from '../../infra/http/remote'

interface AvatarUploadProps {
 user: ProfileModel
}

type AvatarStateProps = {
  image?: string
  crop: {
    x: number
    y: number
  }
  zoom: number
  aspect: number
  rotation: number
  croppedAreaPixels?: any
  showModal: boolean
  preview?: string
}

const AvatarUpload = ({ user }: AvatarUploadProps): JSX.Element => {
  const { alertBox } = useAlertBox()
  const initialValues = {
    image: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    rotation: 0,
    croppedAreaPixels: null,
    showModal: false,
    preview: null
  }
  const [state, setState] = useState<AvatarStateProps>(initialValues)

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setState((old) => ({ ...old, croppedAreaPixels }))
  }, [])

  const onCropChange = (crop) => {
    setState((old) => ({ ...old, crop }))
  }

  const onZoomChange = (zoom) => {
    setState((old) => ({ ...old, zoom }))
  }

  const onRotationChange = (rotation) => {
    setState((old) => ({ ...old, rotation }))
  }

  const onSliderZoom = (_event, zoom) => {
    onZoomChange(zoom)
  }

  const onSliderRotate = (_event, rotation) => {
    onRotationChange(rotation)
  }

  const closeModal = () => {
    setState(initialValues)
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]

      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }

      setState(prev => ({
        ...prev,
        image: imageDataUrl,
        showModal: true
      }))
    }
  }

  const onSubmitAvatar = async (dataAvatar) => {
    try {
      const file = new File([dataAvatar], `${new Date().getTime()}.jpg`, { type: 'image/jpeg' })
      const data = new FormData()
      data.append('image', file)

      await api.post('/current_customer_avatars', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      return e
    }
  }

  const uploadFile = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        state.image,
        state.croppedAreaPixels,
        state.rotation
      )
      const urlPreview = URL.createObjectURL(croppedImage)

      try {
        await onSubmitAvatar(croppedImage)
        const updatedUser = {
          ...user,
          user: { ...user.user, avatar: urlPreview },
          profile: { ...user.profile, avatar: urlPreview }
        }
        await setUser(updatedUser)

        setState({
          ...initialValues,
          preview: urlPreview
        })
      } catch (e) {
        alertBox('Ocorreu um erro ao fazer o upload da imagem')
      }
    } catch (_e) {
      alertBox('Ocorreu um erro ao salvar a imagem')
    }
  }, [state, user, initialValues, alertBox])

  return (
    <>
      <CustomAvatar>
        <Avatar size="2xl" src={state.preview || user.user.avatar}>
          <IconCustom>
            <IconCamera />
          </IconCustom>
        </Avatar>
        <AvatarForm>
          <AvatarFile type="file" onChange={onFileChange} />
        </AvatarForm>
      </CustomAvatar>

      {state.showModal && state.image && (
        <Box>
          <AlertDialogOverlay />
          <AlertDialogContent maxW={['90%', '90%', 600]}>
            <Flex px="1.5rem" flexDir="row-reverse">
              <ModalClose onClick={closeModal}>x</ModalClose>
            </Flex>
            <AlertDialogBody>
              <Box pos="relative" h="240px" mb="15px" borderRadius="5px" overflow="hidden">
                <Cropper
                  image={state.image}
                  crop={state.crop}
                  zoom={state.zoom}
                  aspect={state.aspect}
                  rotation={state.rotation}
                  onCropChange={onCropChange}
                  onZoomChange={onZoomChange}
                  onRotationChange={onRotationChange}
                  onCropComplete={onCropComplete}
                />
              </Box>
              <Flex alignItems="center" mb="5px">
                <Box mr="15px" fontSize="xs" w="50px" textAlign="right">Zoom:</Box>
                <Slider value={state.zoom} min={1} max={5} step={0.1} aria-labelledby="Zoom" onChange={onSliderZoom} />
              </Flex>
              <Flex alignItems="center" mb="5px">
                <Box mr="15px" fontSize="xs" w="50px" textAlign="right">Girar:</Box>
                <Slider value={state.rotation} min={0} max={360} step={0.1} aria-labelledby="Rotation" onChange={onSliderRotate} />
              </Flex>
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonRedGreen onClick={uploadFile}>Salvar imagem</ButtonRedGreen>
            </AlertDialogFooter>
          </AlertDialogContent>
        </Box>
      )}
    </>
  )
}

export default AvatarUpload

const CustomAvatar = styled.div`
  position: relative;
  text-align: center;
`

const IconCustom = styled.div`
  position: absolute;
  color: #fff;
  background: #000;
  padding: 5px;
  border-radius: 50%;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  svg {
    width: 16px;
  }
`

const AvatarForm = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: hidden;
  margin: auto;
  max-width: 8rem;
`

const AvatarFile = styled.input`
  position: absolute;
  top: 0;
  opacity: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`

const ModalClose = styled.div`
  width: 25px;
  height: 25px;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 0.9;
  margin-top: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 25px;
  font-family: monospace;
`
