import { SimpleContentModel } from '../../../domain/models'
import { getImage } from '../../../utils/helpers/get-image'
import { enumWeekDaySlug } from '../../../utils/enums/week-day'

export class SimpleContentToView {
  build (content: any): SimpleContentModel[] {
    return content.map((item: any) => ({
      '@id': item['@id'],
      ...item,
      folder: {
        ...item.folder,
        logo: null,
        // logo: getImage(item.folder.logo),
        thumb: getImage(item.folder.thumb)
      },
      author: {
        ...item.author,
        avatar: getImage(item.author?.avatar)
      },
      thumb: getImage(item.thumb),
      weekDay: enumWeekDaySlug(item.weekDay),
      weekDayEn: item.weekDay
    })) || []
  }
}

export const simpleContentToView = new SimpleContentToView()
