import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { Container, Typography } from '../../component/ui/foundation'
import { Box } from '@chakra-ui/core'
import { clearStorage } from '../../utils/helpers/clear-storage'

const NoAuthorization = () => {
  useEffect(() => {
    (async () => {
      try {
        await Auth.signOut({ global: true })
        clearStorage()
      } catch (e) {
        clearStorage()
      }
    })()
  }, [])
  return (
    <Box py={16}>
      <Container>
        <Box textAlign="center">
          <Typography type="4xl" as="h1" mb={8}>Sem autorização</Typography>
          <Typography mb={8}>Você não tem permissão para acessar essa página.</Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default NoAuthorization
