import React from 'react'
import { HelpContentsModel } from '../../../../domain/models'
import { AspectRatioBox, Box, Flex, Image } from '@chakra-ui/core'
import { Typography } from '../../foundation'
import { ModalContextInterface, useModal } from '../modal/modal-context'
import SambaPlayer from '../../../samba-player/samba-player'

interface CardHelpContentItemProps {
  content: HelpContentsModel
}

const CardHelpContentItem = ({ content }: CardHelpContentItemProps): JSX.Element => {
  const { setOpen, setContent }: ModalContextInterface = useModal()

  const handleOpen = () => {
    setOpen(true)
    if (content.contentReference) {
      setContent(
        <SambaPlayer reference={content.contentReference} modalPlayer/>
      )
    } else {
      setContent(<Typography type="md" as="p" mb={1}>Tipo de mídia desconhecido</Typography>)
    }
  }

  return (
    <Flex mb={6}>
      <Box w="170px" mr="15px" onClick={handleOpen} cursor="pointer">
        <AspectRatioBox ratio={16 / 9} borderRadius="md" overflow="hidden">
          {content.thumb ? (
            <Image
              objectFit="cover"
              src={content.thumb}
              alt={content.title}
              ignoreFallback
            />
          ) : <div/>}
        </AspectRatioBox>
      </Box>
      <Box>
        <Typography type="md" as="h4" color="purple.500" mb={2} onClick={handleOpen} cursor="pointer">
          {content.title}
        </Typography>
        {/* <Typography type="sm" as="p" color="gray.500">{content.subtitle}</Typography> */}
      </Box>
    </Flex>
  )
}

export default CardHelpContentItem
