import React, { useEffect, useState } from 'react'
import { makePlan } from '../../main/factories/usecases/subscriptions/make-plans'
import { PlanDetailModel, PlanItemModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { Redirect } from 'react-router'
import { Box, Flex, Grid } from '@chakra-ui/core'
import { ButtonRedGreen, ButtonWhiteGreen, Typography } from '../../component/ui/foundation'
import { CardPlanItem } from '../../component/ui/layout'
import styled from '@emotion/styled'
import customTheme from '../../styles/theme'
import { useAlertBoxBlack } from '../../component/ui/layout/alert-box-blank/alert-box-black-context'
import { goToUrl } from '../../utils/helpers/go-to-url'
import { addStorePlanSession } from '../../utils/helpers/store-plan-session'
import { PlanItem } from '../../component/plans/plan-item'

const Plan = ({ match, history }) => {
  const { id } = match.params
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<PlanDetailModel>(null)

  const { alertBox, setOpen } = useAlertBoxBlack()

  const goTo = (url: string) => {
    setOpen(false)
    goToUrl(history, url)
  }

  const goToPay = () => {
    setOpen(false)
    goToUrl(history, '/pagamento')
  }

  useEffect(() => {
    (async () => {
      try {
        const content = await makePlan(id)
        await addStorePlanSession(content, content.selectable)
        setData(content)
        setLoading(false)
      } catch (e) {
        setData(null)
        setLoading(false)
      }
    })()
  }, [id])

  if (loading) {
    return <Loading/>
  }

  if (!data) {
    return <Redirect to="/404" />
  }

  const handleClose = () => {
    alertBox(
      <Box textAlign="center">
        <Typography type="md" mb={8}>Deseja ver outros planos ou assinar este?</Typography>
        <Flex>
          <ButtonWhiteGreen mx={2} onClick={() => goTo('/escolha-seu-plano')}>Ver outros planos</ButtonWhiteGreen>
          <ButtonRedGreen mx={2} onClick={goToPay}>Quero escolher este</ButtonRedGreen>
        </Flex>
      </Box>
    )
  }

  return (
    <Box py={8}>
      <CloseButton onClick={handleClose}>&times;</CloseButton>
      <Box textAlign="center" w={['100%', '', '70%']} mx="auto" mb={12}>
        {data.firstInnerText && (
          <Typography type="4xl" as="h1" mb={4}>{data.firstInnerText}</Typography>
        )}
        <Typography type="sm" as="p" mb={4}>Veja alguns dos benefícios</Typography>
      </Box>
      <Box w={['100%', '70%', '33.33333%', '25%']} mx="auto" mb={12}>
        <CardPlanItem dark data={{
          title: data.name,
          description: data.description,
          image: data.image,
          selectable: data.selectable,
          totalPrice: data.totalPrice,
          url: `/plano/${data.id}`,
          intervalCount: data.intervalCount
        }} />
      </Box>

      {Object.keys(data.groupItems).length ? (
        <Box mb={12}>
          <Box textAlign="center">
            {data.secondInnerText && (
              <Typography type="4xl" as="h2" mb={8}>{data.secondInnerText}</Typography>
            )}
          </Box>
          <Flex flexWrap="wrap">
            {Object.keys(data.groupItems).map((item, idx) => idx === 0 ? (
              <React.Fragment key={item}>
                <Box w={['100%', '100%', '20%']}>
                  {data.planItems.filter(filter => filter.showPlanItem && filter.productGroup['@id'] === item)
                    .map((planItem: PlanItemModel) => (
                      <PlanItem key={planItem['@id']} item={planItem} selectable={data.selectable} />
                    ))}
                </Box>
                <Box w={['100%', '100%', '5%']} fontSize="65px" fontWeight="bold" textAlign="center">+</Box>
              </React.Fragment>
            ) : (
              <BoxItems w={['100%', '100%', '75%']} key={item}>
                <Box textAlign="center">
                  {data.groupItems[item].showTitle && (
                    <>
                      <Typography type="3xl" as="h2" mb={8}>{data.groupItems[item].title}</Typography>
                      <Typography mb={8}>Você pode escolher todos os treinos ou selecionar <br/>apenas os que são da sua preferência</Typography>
                    </>
                  )}
                </Box>
                <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={5} mb={16}>
                  {data.planItems.filter(filter => filter.showPlanItem && filter.productGroup['@id'] === item)
                    .map((planItem: PlanItemModel) => (
                      <PlanItem key={planItem['@id']} item={planItem} selectable={data.selectable} />
                    ))}
                </Grid>
              </BoxItems>
            ))}
          </Flex>
        </Box>
      ) : (
        <Box w={['100%', '', '70%']} mx="auto" mb={12} display={data.planItems.filter(item => item.showPlanItem).length ? 'block' : 'none'}>
          <Box textAlign="center">
            {data.secondInnerText && (
              <Typography type="4xl" as="h2" mb={8}>{data.secondInnerText}</Typography>
            )}
          </Box>
          <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={5} mb={16}>
            {data.planItems.filter(item => item.showPlanItem).map(item => (
              <PlanItem key={item['@id']} item={item} selectable={data.selectable} />
            ))}
          </Grid>
        </Box>
      )}

      <Box w={['100%', '70%', '33.33333%', '25%']} mx="auto" mb={12}>
        <ButtonRedGreen w="100%" mt={8} onClick={goToPay}>Escolha este plano</ButtonRedGreen>
      </Box>
    </Box>
  )
}

export default Plan

const CloseButton = styled.div`
  position: fixed;
  top: 70px;
  right: 30px;
  font-size: 80px;
  width: 55px;
  line-height: 0.5;
  height: 55px;
  z-index: 9999;
  cursor: pointer;
  &:hover {
    color: #676767; 
  }
`

const BoxItems = styled(Box)`
  position: relative;
  box-shadow: 0 5px 10px rgba(0,0,0,.3);
  padding: 20px;
  border-radius: 10px;
  border-left: 4px solid ${customTheme.colors.pink[200]};
`
