import React, { useState } from 'react'
import { FormControl, IcoView, InputCustom, InputError, RelativeField } from './styles'
import { FormLabel } from '@chakra-ui/core'
import { Field, ErrorMessage, FieldAttributes } from 'formik'

interface InputProps extends FieldAttributes<any>{
  title: string
  name: string
  placeholder?: string
}

const InputPassword = ({ name, title, placeholder, ...el }: InputProps) => {
  const [viewPass, setViewPass] = useState(false)

  const enableInput = (e) => {
    e.target.readOnly = false
  }

  return (
    <FormControl>
      <FormLabel htmlFor={name}>{title}</FormLabel>
      <RelativeField>
        <Field
          {...el}
          type={viewPass ? 'text' : 'password'}
          name={name}
          id={name}
          placeholder={placeholder || ''}
          onChange={(val) => {
            if (el.setFieldValue) {
              el.setFieldValue(name, val.target.value)
            }
          }}
          readOnly
          onFocus={enableInput}
          autoComplete="off"
          as={InputCustom}
        />
        <IcoView
          role="button"
          aria-label="Exibir conteúdo"
          className={viewPass && 'slash'}
          onClick={() => setViewPass((prev) => !prev)}
        />
      </RelativeField>
      <ErrorMessage name={name} component={InputError} />
    </FormControl>
  )
}

export default InputPassword
