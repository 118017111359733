import React, { createContext, useContext } from 'react'

export interface AlertBoxBlackContextInterface {
  open: boolean
  setOpen: any
  content: React.ReactNode
  setContent: any,
  alertBox: any
}

const AlertBoxBlackContext = createContext<AlertBoxBlackContextInterface|null>(null)

export const AlertBoxBlackProvider:React.FC = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [content, setContent] = React.useState<JSX.Element|string>('')

  const alertBox = (content: any): void => {
    setOpen(true)
    setContent(content)
  }
  return (
    <AlertBoxBlackContext.Provider value={{
      open,
      setOpen,
      content,
      setContent,
      alertBox
    }}>
      {children}
    </AlertBoxBlackContext.Provider>
  )
}

export const useAlertBoxBlack = ():AlertBoxBlackContextInterface => {
  const context = useContext(AlertBoxBlackContext)

  if (!context) { throw new Error('useAlertBox must be used within a provider AlertBoxBlackProvider') }

  return {
    ...context
  }
}
