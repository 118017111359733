import { PlanDetailModel } from '../../domain/models'
import { addLocalStorage, getLocalStorage } from '../../infra/storage/localStorage'

type StorePlanSession = {
  plan: string
  planItems?: string[]
}

const keyPlan = 'planItem'

export const addStorePlanSession = async (plan: PlanDetailModel, selectable: boolean) => {
  if (selectable) {
    await addLocalStorage(keyPlan, JSON.stringify({
      plan: plan['@id'],
      planItems: plan.planItems.map(item => item['@id'])
    }))
  } else {
    await addLocalStorage(keyPlan, JSON.stringify({
      plan: plan['@id']
    }))
  }
}

export const getStorePlanSession = (): StorePlanSession => {
  return JSON.parse(getLocalStorage(keyPlan))
}

export const setStorePlanSession = async (plan: StorePlanSession): Promise<void> => {
  await addLocalStorage(keyPlan, JSON.stringify(plan))
}
