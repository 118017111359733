export const enumStatus = (status: string): string => {
  switch (status) {
    case 'created': return 'Criado'
    case 'active': return 'Ativo'
    case 'canceled': return 'Cancelado'
    case 'future': return 'Trial'
    case 'expired': return 'Expirado'
    default: return status
  }
}

export const enumBillingStatus = (status: string): string => {
  switch (status) {
    case 'waiting': return 'Esperado'
    case 'review': return 'Revisão'
    case 'pending': return 'Pendente'
    case 'paid': return 'Pago'
    case 'canceled': return 'Cancelado'
    case 'scheduled': return 'Agendado'
    default: return status
  }
}

export const enumPaymentProfile = (status: string): string => {
  switch (status) {
    case 'active': return 'Ativo'
    case 'canceled': return 'Cancelado'
    default: return status
  }
}
