import React from 'react'
import { Container, Typography } from '../../component/ui/foundation'
import { Box } from '@chakra-ui/core'

const PageNotFound = () => {
  return (
    <Box py={16}>
      <Container>
        <Box textAlign="center">
          <Typography type="4xl" as="h1" mb={8}>Página não encontrada</Typography>
        </Box>
        <Typography type="sm" as={'div'} mb={8}>A página não existe ou foi excluída</Typography>
      </Container>
    </Box>
  )
}

export default PageNotFound
