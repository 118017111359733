import React, { useState } from 'react'
import { ReactComponent as Favorite } from '../ui/icons/favorite.svg'
import { ReactComponent as FavoriteStrong } from '../ui/icons/favorite-strong.svg'
import styled from '@emotion/styled/macro'
import { useRefreshContent } from './favorite-context'
import { api } from '../../infra/http/remote'
import { useAlertBox } from '../ui/layout/alert-box/alert-box-context'

interface FavoriteEvent {
  favorite: boolean
  folderIri: string
}
const FavoriteEvent: React.FC<FavoriteEvent> = ({ favorite, folderIri }) => {
  const { alertBox } = useAlertBox()
  const [status, setStatus] = useState<boolean>(favorite)
  const context = useRefreshContent()

  const handleFavorite = async (): Promise<void> => {
    try {
      await api.post('user_collection_favorites', {
        folder: folderIri
      })
      setStatus(prev => !prev)
      if (context) {
        context.handleRefresh()
      }
    } catch (e) {
      alertBox('Ocorreu um erro a guardar o treino no favorito')
    }
  }

  return (
    <FavoriteIcon onClick={handleFavorite}>
      {status ? (
        <FavoriteBorder>
          <FavoriteStrong/>
        </FavoriteBorder>
      ) : (
        <Favorite/>
      )}
    </FavoriteIcon>
  )
}

const FavoriteIcon = styled.div`
  position: relative;
  cursor: pointer;
  svg {
    color: #489554;
    width: 25px;
  }
  .true {
    position: relative;
    z-index: 2;
    svg {
      color: #b3bdb4;
    }
  }
`
const FavoriteBorder = styled.div`
  position: absolute;
  top: 1px;
`

export default FavoriteEvent
