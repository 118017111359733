import { BlockResultModel, FeatureGroupsModel } from '../../../domain/models'
import { FeatureGroupsToViewInterface } from '../../../domain/usecases/feature-group-to-view/feature-to-view'
import { getImage } from '../../../utils/helpers/get-image'

export class FeatureGroupsToView implements FeatureGroupsToViewInterface {
  build ({ content }: BlockResultModel, type: string): FeatureGroupsModel {
    if (type) {
      return {
        '@id': content['@id'],
        id: content.id,
        name: content.name,
        description: content.description,
        features: content.features.map((feature) => ({
          '@id': feature['@id'],
          id: feature.id,
          title: feature.title,
          description: feature.description,
          image: getImage(feature.image),
          link: feature.link
        }))
      }
    }
  }
}

export const featureGroupsToView = new FeatureGroupsToView()
