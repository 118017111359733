import React, { useEffect, useState } from 'react'
import { PageResultModel } from '../domain/models'
import { makePage } from '../main/factories/usecases/page/page'
import Loading from '../component/loading/loading'
import LoadBlocks from '../component/load-blocks/load-blocks'
import { Box } from '@chakra-ui/core'
import { Container, Typography } from '../component/ui/foundation'
import { Redirect } from 'react-router'

const DynamicPage = ({ match }): JSX.Element => {
  const { slug } = match.params
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<PageResultModel>(null)

  useEffect(() => {
    (async () => {
      try {
        const data = await makePage(slug)
        setPage(data)
        setLoading(false)
      } catch (e) {
        throw new Error('Ocorreu um erro ao processar a página')
      }
    })()
    return () => {
      setLoading(true)
    }
  }, [slug])

  if (loading) {
    return <Loading />
  }

  if (!page) {
    return <Redirect to="/404" />
  }

  return (
    <Box py={16}>
      <Container>
        <Box textAlign="center">
          {!page.hideTitle && (
            <Typography type="4xl" as="h1" mb={8}>{page.title}</Typography>
          )}
        </Box>
        {page.content && (
          <Typography type="sm" as={'div'} mb={8} dangerouslySetInnerHTML={{
            __html: page.content
              .replace(/<iframe/g, '<div class="embed-responsive"><iframe')
              .replace(/<\/iframe>/g, '</iframe></div>')
          }}/>
        )}

        <LoadBlocks containers={page.containers} locale="main" />
      </Container>

      <LoadBlocks containers={page.containers} locale="mainWithoutContainer" />
    </Box>
  )
}

export default DynamicPage
