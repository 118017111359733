import styled from '@emotion/styled/macro'
import customTheme from '../../styles/theme'
import icoEye from '../../assets/images/ico-eye.png'

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 10px;
`

export const RelativeField = styled.div`
  position: relative;
`

export const InputCustom = styled.input`
  background-color: ${customTheme.colors.white};
  border: 1px solid ${customTheme.colors.gray['200']};
  width: 100%;
  height: 38px;
  padding: 0 20px;
  font-size: ${customTheme.fontSizes.md};
  border-radius: ${customTheme.radii.lg};
  transition: all .3s ease;
  &:focus {
    border-color: ${customTheme.colors.green['700']};
  }
  &:hover {
    border-color: ${customTheme.colors.gray['300']};
  }
`

export const TextareaCustom = styled.textarea`
  background-color: ${customTheme.colors.white};
  border: 1px solid ${customTheme.colors.gray['200']};
  width: 100%;
  height: 80px;
  padding: 15px 20px;
  font-size: ${customTheme.fontSizes.md};
  border-radius: ${customTheme.radii.sm};
  transition: all .3s ease;
  &:focus {
    border-color: ${customTheme.colors.green['700']};
  }
  &:hover {
    border-color: ${customTheme.colors.gray['300']};
  }
`

export const SelectCustom = styled.select`
  background-color: ${customTheme.colors.white};
  border: 1px solid ${customTheme.colors.gray['200']};
  width: 100%;
  height: 38px;
  padding: 0 20px;
  font-size: ${customTheme.fontSizes.md};
  border-radius: ${customTheme.radii.lg};
  transition: all .3s ease;
  &:focus {
    border-color: ${customTheme.colors.green['700']};
  }
  &:hover {
    border-color: ${customTheme.colors.gray['300']};
  }
`

export const InputError = styled.div`
  font-size: 12px;
  color: #990000;
  text-align: left;
  margin-top: 3px;
`

export const IcoView = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url('${icoEye}') no-repeat top center;
  &.slash {
    background-position: bottom center;
  }
`
