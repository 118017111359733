import React from 'react'
import Script from 'react-load-script'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { api } from '../../infra/http/remote'

const VimeoVideo = ({ reference, view, contentId }) => {
  const vimeoIdPlayer = 'vimeo-player'
  let flag = false

  const onFinishContent = async (eventParams) => {
    try {
      await api.post('/user_collection_views', {
        content: contentId,
        percent: 100,
        progress: Math.round(eventParams.duration)
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  const onListenContent = async (type, eventParams) => {
    const timePassed = parseInt(type === 'video' ? eventParams.seconds : eventParams.target.currentTime, 10)
    const timeToUpdate = 15

    if (contentId && !flag && Math.round(timePassed) > view.progress) {
      if (Math.round(timePassed) % timeToUpdate === 0 && !flag && timePassed > 1) {
        if (eventParams.percent > 0.5) {
          flag = true
          await api.post('/user_collection_views', {
            content: contentId,
            percent: Math.round(eventParams.percent * 100) || 100,
            progress: Math.round(timePassed)
          })
        }
      } else if (timePassed % (timeToUpdate + 1) === 0 && flag && timePassed > 1) {
        flag = false
      }
    }
  }

  const initVimeo = async () => {
    const VimeoPlayer = new (window as typeof globalThis).Vimeo.Player(vimeoIdPlayer, {
      id: reference,
      playerParams: {
        enableShare: false,
        resume: true
      }
    })

    VimeoPlayer.on('timeupdate', e => onListenContent('video', e))
    VimeoPlayer.on('ended', e => onFinishContent(e))
  }

  return (
    <FrameBox>
      <Script url="https://player.vimeo.com/api/player.js" onLoad={initVimeo} />
      <iframe
        title="IframeVimeo"
        id={vimeoIdPlayer}
        src={`https://player.vimeo.com/video/${reference}`}
        frameBorder="0"
        allowFullScreen
      />
    </FrameBox>
  )
}

export default VimeoVideo

VimeoVideo.propTypes = {
  reference: PropTypes.string.isRequired,
  onListenContent: PropTypes.func.isRequired,
  onFinishContent: PropTypes.func.isRequired
}

const FrameBox = styled.div`
  position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    width: 100%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
`
