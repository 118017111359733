export const convertHexToRgbA = (hex: string, opacity?: number): string => {
  let ret: any

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    ret = hex.slice(1)
    ret = ret.split('')

    if (ret.length === 3) {
      const ar = []
      ar.push(ret[0])
      ar.push(ret[0])
      ar.push(ret[1])
      ar.push(ret[1])
      ar.push(ret[2])
      ar.push(ret[2])
      ret = ar
    }

    ret = '0x' + ret.join('')
    const r = (ret >> 16) & 255
    const g = (ret >> 8) & 255
    const b = ret & 255

    return `rgba(${[r, g, b].join(',')}, ${opacity || 1})`
  }

  return hex
}
