import React from 'react'
import { Auth, I18n } from 'aws-amplify'
import { ButtonRedGreen, Typography } from '../../component/ui/foundation'
import { Box, Flex } from '@chakra-ui/core'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Input } from '../../component/form-elements'
import { Link } from 'react-router-dom'
import {
  AlertBoxContextInterface,
  useAlertBox
} from '../../component/ui/layout/alert-box/alert-box-context'
import { goToUrl } from '../../utils/helpers/go-to-url'

const ForgotPassword = ({ history }) => {
  const initialValues = {
    email: ''
  }
  const { alertBox }: AlertBoxContextInterface = useAlertBox()

  const changePasswordSubmit = async (values) => {
    try {
      await Auth.forgotPassword(values.email.toLowerCase())
      alertBox(I18n.get('AlertSendCode'))
      history.push({
        pathname: '/recuperar-senha',
        search: history.location.search,
        state: {
          email: values.email.toLowerCase()
        }
      })
    } catch (e) {
      if (e.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
        alertBox(I18n.get('AlertUserNotVerify'))
        goToUrl(history, '/confirmar-usuario', { email: values.email.toLowerCase() })
      } else {
        alertBox(I18n.get('AlertSendCodeError'))
      }
    }
  }

  return (
    <>
      <Box textAlign="center">
        <Typography type="4xl" as="h2" textTransform="uppercase">Esqueci minha senha</Typography>
      </Box>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField'))
          })
        }
        onSubmit={async (values) => {
          try {
            await Auth.signIn(values.email, Math.random().toString(30).replace(/[^A-z0-9]+/g, '').substr(0, 8))
          } catch (e) {
            if (e.code === 'UserLambdaValidationException') {
              history.push({
                pathname: '/nova-senha',
                search: history.location.search,
                state: { email: values.email }
              })
            } else {
              await changePasswordSubmit(values)
            }
          }
        }}
      >
        {(actions) => (
          <Form>
            <Input title="Email" name="email" {...actions} />

            <ButtonRedGreen
              type="submit"
              isFullWidth
              isLoading={actions.isSubmitting}
              loadingText="Enviando"
              isDisabled={actions.isSubmitting}
              mb={8}
            >Enviar código</ButtonRedGreen>
          </Form>
        )}
      </Formik>

      <Flex justifyContent={'space-around'} mb={8}>
        <Box><Link to="/login">Fazer login</Link></Box>
      </Flex>
    </>
  )
}

export default ForgotPassword
