import React, { useEffect, useState } from 'react'
import { Auth, I18n } from 'aws-amplify'
import { Box, Flex } from '@chakra-ui/core'
import { ButtonRedGreen, Typography } from '../../component/ui/foundation'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Input, InputPassword } from '../../component/form-elements'
import {
  AlertBoxContextInterface,
  useAlertBox
} from '../../component/ui/layout/alert-box/alert-box-context'
import { exceptionsError } from '../../utils/helpers/cognito-erros'
import { useHistory } from 'react-router'
import { goToUrl } from '../../utils/helpers/go-to-url'
import { clearStorage } from '../../utils/helpers/clear-storage'
import { settings } from '../../config/settings'
import Loading from '../../component/loading/loading'
import { Link } from 'react-router-dom'
import { createCookieAdmin } from '../../utils/helpers/cookies'
import queryString from 'query-string'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLoginButton, FacebookLoginButton } from 'react-social-login-buttons'
import axios, { AxiosResponse } from 'axios'

interface AuthResponse {
    token: string;
    user: User;
}

interface User {
    _id: string;
    name: string;
    email: string;
    avatar: string;
}

const SignIn: React.FC = () => {
  const history = useHistory()
  const search = queryString.parse(history.location.search)
  const isAdmin = 'adm' in search
  const isRedirect = 'urlR' in search
  const [loading, setLoading] = useState<boolean>(true)
  const { alertBox }: AlertBoxContextInterface = useAlertBox()
  const initialValues = {
    email: '',
    password: ''
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await Auth.currentSession()
        createCookieAdmin(response.getIdToken().getJwtToken())

        if (isAdmin) {
          window.location.href = settings.ADMIN_URL
        } else {
          goToUrl(history, settings.dashboardRoute)
        }
      } catch (e) {
        clearStorage()
        setLoading(false)
      }
    })()
  }, [history, isAdmin])

  const onSubmit = async (values) => {
    if (values) {
      try {
        const user = await Auth.signIn(values.email.toLowerCase(), values.password)
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          alertBox(exceptionsError('NewPasswordRequired'))
          goToUrl(history, '/criar-nova-senha', values)
        } else if (user.challengeName !== 'PASSWORD_VERIFIER') {
          if (isAdmin) {
            window.location.href = settings.ADMIN_URL
          } else if (isRedirect) {
            window.location.href = search.urlR.toString()
          } else {
            goToUrl(history, settings.dashboardRoute)
          }
        }
      } catch (error) {
        if (error.code === 'UserNotConfirmedException') {
          goToUrl(history, '/confirmar-usuario', values)
        } else if (error.code === 'UserLambdaValidationException') {
          goToUrl(history, '/confirmar-usuario', {
            email: values.email
          })
        } else {
          alertBox(exceptionsError(error.code, error.message))
        }
      }
    }
  }

  if (loading) {
    return <Loading/>
  }

  const onSuccess = async (res: any) => {
    console.log(res)
    try {
      const result: AxiosResponse<AuthResponse> = await axios.post(`${settings.API_URL}/auth`, {
        data: res
      })
      console.log(result)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  return (
    <>
      <Box textAlign="center">
        <Typography type="4xl" as="h2" textTransform="uppercase">Login</Typography>
        <Typography type="md" as="p" mb={8}>Faça login para acessar os conteúdos</Typography>
      </Box>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField')),
            password: Yup.string().min(8, I18n.get('ValidateMinPassword')).required(I18n.get('ValidateRequiredField'))
          })
        }
        onSubmit={onSubmit}
      >
        {(actions) => (
          <Form>
            <Input title="Email" name="email" {...actions} />
            <InputPassword title="Senha" name="password" {...actions} />
            <ButtonRedGreen
              type="submit"
              as="button"
              isFullWidth
              isLoading={actions.isSubmitting}
              loadingText="Entrando"
              isDisabled={actions.isSubmitting}
              mb={8}
            >
                            Entrar
            </ButtonRedGreen>
          </Form>
        )}
      </Formik>

      <Flex justifyContent={'space-around'} mb={8}>
        <Box><Link to="/esqueci-minha-senha">Esqueceu a senha?</Link></Box>
        <Box><Link to="/cadastre-se">Cadastre-se</Link></Box>
      </Flex>

      <Box textAlign="center" d="none">
        <Flex mb={8} alignItems="center" justifyContent="center">
          <Box h="1px" bg="gray.200" w="25%"/>
          <Typography type="md" as="p" mb={0} mx={4}>OU</Typography>
          <Box h="1px" bg="gray.200" w="25%"/>
        </Flex>
      </Box>
      <Box textAlign="center" display={'none'}>
        <FacebookLogin
          appId={`${settings.FACEBOOK_APP_ID}`}
          autoLoad={false}
          callback={onSuccess}
          render={renderProps => (
            <FacebookLoginButton style={{
              fontSize: '15px',
              borderRadius: '25px'
            }} text="Login com o Facebook" onClick={renderProps.onClick}></FacebookLoginButton>
          )}
        />
        <GoogleLogin
          clientId={`${settings.GOOGLE_APP_ID}`}
          onSuccess={onSuccess}
          render={renderProps => (
            <GoogleLoginButton style={{
              fontSize: '15px',
              borderRadius: '25px',
              marginTop: '10px'
            }} text="Login com o Google" onClick={renderProps.onClick}></GoogleLoginButton>
          )}
        />
      </Box>
    </>
  )
}

export default SignIn
