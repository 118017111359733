import React from 'react'
import { BlockResultModel, FeatureGroupsModel } from '../../../domain/models'
import { PseudoBoxCustom } from '../../ui/layout'
import { Container, Typography } from '../../ui/foundation'
import { Flex } from '@chakra-ui/core'
import { featureGroupsToView } from '../../../data/usecases/feature-group-to-view/feature-group-to-view'
import styled from '@emotion/styled'

const About = (props: BlockResultModel): JSX.Element|null => {
  const content: FeatureGroupsModel = featureGroupsToView.build(props, 'php')
  if (!content) {
    return null
  }

  return (
    <PseudoBoxCustom
      id={`block-${props.block.id}`}
      pos="relative"
      py={[16, 24, 32]}
      background={'white url(\'/images/bg-rio.jpg\') no-repeat center bottom'}
      backgroundSize="contain"
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'white',
        bottom: -1,
        height: 35,
        clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)'
      }}>
      <Container>
        <Flex flexDir="column" w={['100%', '80%', '60%']} mx="auto">
          <Typography
            type="4xl"
            as="h2"
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: content.name }} />
          <CustomTypography>
            <Typography type="xl"
              as={'div'}
              dangerouslySetInnerHTML={{ __html: content.description }}/>
          </CustomTypography>
        </Flex>
      </Container>
    </PseudoBoxCustom>
  )
}

const CustomTypography = styled.div`
  p {
    margin-bottom: 30px;
  }
`

export default About
