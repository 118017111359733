import React from 'react'
import { I18n } from 'aws-amplify'
import { Avatar, Box, Flex } from '@chakra-ui/core'
import { getUser } from '../../utils/helpers/user'
import { ErrorMessage, FastField, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { ButtonRedPurple } from '../ui/foundation'
import styled from '@emotion/styled/macro'
import { AlertBoxContextInterface, useAlertBox } from '../ui/layout/alert-box/alert-box-context'
import { InputError } from '../form-elements/styles'
import { api } from '../../infra/http/remote'
import { useCommentProvider } from './contexts/comment-provider'

interface CommentPostProps {
  contentUri: string
}

const CommentPost = ({ contentUri }: CommentPostProps) => {
  const { alertBox }: AlertBoxContextInterface = useAlertBox()
  const user = getUser()
  const { setCommentParams } = useCommentProvider()

  const onSubmit = async (values, actions) => {
    try {
      await api.post('/comments', {
        text: values.text,
        content: contentUri
      })
      setCommentParams({ id: contentUri.replace('/v1/contents/', ''), date: new Date() })

      alertBox('Obrigado pelo seu comentário')
      actions.resetForm()
    } catch (error) {
      alertBox('Não foi possível salvar seu comentário')
    }
  }

  return (
    <Flex>
      <Avatar src={user.profile.avatar} />

      <Formik
        initialValues={{ text: '' }}
        validationSchema={Yup.object().shape({
          text: Yup.string().required(I18n.get('ValidateRequiredField'))
        })}
        onSubmit={onSubmit}
      >
        {(actions) => (
          <Form style={{ width: '100%' }}>
            <Flex>
              <CustomField>
                <FastField name="text" placeholder="Escreva um comentário" />
                <ErrorMessage name="text" component={InputError} />
              </CustomField>
              <ButtonRedPurple
                type="submit"
                isLoading={actions.isSubmitting}
                loadingText="Enviando"
                isDisabled={actions.isSubmitting}
              >Enviar</ButtonRedPurple>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export default CommentPost

const CustomField = styled(Box)`
  width: 100%;
  margin: 0 15px;
  position: relative;

  input {
    border-bottom: 1px solid #313131;
    width: 100%;
    padding: 5px 10px;
  }
`
