import React, { createContext, useContext } from 'react'

type RefreshContentProviderProps = {
  handleRefresh: () => void
}

const RefreshContentContext = createContext<RefreshContentProviderProps>(null)

export const RefreshContentProvider: React.FC<RefreshContentProviderProps> = ({ children, handleRefresh }) => {
  return (
    <RefreshContentContext.Provider value={{ handleRefresh }}>
      {children}
    </RefreshContentContext.Provider>
  )
}

export const useRefreshContent = (): RefreshContentProviderProps => {
  const context = useContext<RefreshContentProviderProps>(RefreshContentContext)
  if (!context) {
    return null
  }

  return { ...context }
}
