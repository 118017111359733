import React from 'react'
import { Auth, I18n } from 'aws-amplify'
import { ButtonRedGreen, Typography } from '../../component/ui/foundation'
import { Box, Button, Flex } from '@chakra-ui/core'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  AlertBoxContextInterface,
  useAlertBox
} from '../../component/ui/layout/alert-box/alert-box-context'
import queryString from 'query-string'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Input, InputMasked } from '../../component/form-elements'
import { exceptionsError } from '../../utils/helpers/cognito-erros'
import { goToUrl } from '../../utils/helpers/go-to-url'
import { settings } from '../../config/settings'

type ConfirmSignUpProps = {
  email?: string
  redirect?: string
  password?: string
  code?: string
}

const ConfirmSignUp = (): JSX.Element => {
  const history = useHistory()
  const { alertBox }: AlertBoxContextInterface = useAlertBox()
  const query: ConfirmSignUpProps = queryString.parse(history.location.search)
  const state: ConfirmSignUpProps = history.location.state
  const email = state ? state.email : query.email
  const password = state ? state.password : ''

  const initialValues = {
    email: email || '',
    code: query.code || ''
  }

  const resendConfirmationCode = async () => {
    try {
      const currentEmail = document.getElementById('email') as HTMLInputElement
      await Auth.resendSignUp(email || currentEmail.value)
      alertBox(I18n.get('ResendSignUpSuccess'))
    } catch (error) {
      alertBox(I18n.get('ResendSignUpFail'))
    }
  }

  const onSubmit = async (values, actions) => {
    try {
      await Auth.confirmSignUp(values.email, values.code)
      if (password) {
        await Auth.signIn(values.email, password)
        goToUrl(history, settings.dashboardRoute)
      } else {
        alertBox(I18n.get('UserSuccessfullyVerified'))
        goToUrl(history, '/login', { email: values.email })
      }
    } catch (e) {
      actions.setFieldValue('code', '')
      alertBox(exceptionsError(e.code, e.message))
    }
  }

  return (
    <>
      <Box textAlign="center">
        <Typography type="4xl" as="h2" textTransform="uppercase">Confirmar código</Typography>
      </Box>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField')),
            code: Yup.string().required(I18n.get('ValidateRequiredField')).matches(/[0-9]/, I18n.get('ValidateOnlyNumbers'))
          })
        }
        onSubmit={onSubmit}
      >
        {(actions) => (
          <Form>
            <Input title="Email" name="email" {...actions} defaultValue={initialValues.email} disabled={!!email} />
            <InputMasked title="Código" name="code" mask="999999" {...actions} defaultValue={initialValues.code} />

            <ButtonRedGreen
              type="submit"
              isFullWidth
              isLoading={actions.isSubmitting}
              loadingText="Entrando"
              isDisabled={actions.isSubmitting}
              mb={8}
            >
              Entrar
            </ButtonRedGreen>
          </Form>
        )}
      </Formik>

      <Flex justifyContent={'space-around'} mb={8}>
        <Box>
          <Button variant="link" onClick={resendConfirmationCode}>Reenviar código?</Button>
        </Box>
        <Box>
          <Link to="/login">
            <Button variant="link">Fazer Login</Button>
          </Link>
        </Box>
      </Flex>
    </>
  )
}

export default ConfirmSignUp
