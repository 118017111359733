import React, { useEffect, useState } from 'react'
import { getUser } from '../../utils/helpers/user'
import { makePopUpContents } from '../../main/factories/usecases/current-customer/popup'
import PopUpItem from './popup-item'
import Cookie from 'js-cookie'
import { useLocation } from 'react-router'

const PopUpNotification = () => {
  const [data, setData] = useState<any>([])
  const [dataTotal, setDataTotal] = useState<any>(0)
  const [popUp, setPopUp] = useState<any>({})
  const { pathname } = useLocation()

  const closePopUp = () => {
    if (dataTotal > 0) {
      setDataTotal(prev => prev - 1)
      setData(data.filter((item, i) => {
        if (i !== 0) return item
        return null
      }))
    }
  }

  useEffect(() => {
    setPopUp(data[0])
  }, [data])

  useEffect(() => {
    (async () => {
      if (getUser() && pathname === '/dashboard') {
        const atualDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
        const data = await makePopUpContents({ 'startAt[before]': atualDate, 'endAt[after]': atualDate })

        const popUpList = Cookie.get('popUpList') ? JSON.parse(Cookie.get('popUpList')) : []
        const dataFiltered = data.filter(item => !popUpList.includes(item.id))

        setData(dataFiltered)
        setPopUp(dataFiltered[0])
        setDataTotal(dataFiltered.length)
      }
    })()
  }, [pathname])

  return !!data.length && (
    <PopUpItem
      content={popUp}
      closePopUp={closePopUp}
    />
  )
}

export default PopUpNotification
