import React from 'react'
import { BlockResultModel, FolderModel } from '../../../domain/models'
import { publicContentToView } from '../../../data/usecases/public-content-to-view/public-content-to-view'
import { CardFolderItem, Carousel, PseudoBoxCustom } from '../../ui/layout'
import { Container, Typography } from '../../ui/foundation'
import { Box } from '@chakra-ui/core'

const SliderActivities = (props: BlockResultModel): JSX.Element|null => {
  const { block } = props
  const content: FolderModel[] = publicContentToView.build(props)
  if (!content) {
    return null
  }

  return (
    <PseudoBoxCustom
      id={`block-${block.id}`}
      pos="relative"
      bg="gray.700"
      color="white"
      mb={8}
      py={16}
      zIndex={2}
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'gray.700',
        height: 35,
        bottom: -34,
        clipPath: 'polygon(0% 0, 100% 0, 100% 100%)'
      }}>
      <Container>
        <Typography
          type="4xl"
          as="h2"
          textAlign="center"
          textTransform={'uppercase'}
          mb={4}>{block.title}</Typography>
        <Typography
          type="sm"
          as={'div'}
          textAlign="center"
          mb={8}
          dangerouslySetInnerHTML={{ __html: block.description }}/>
        <Carousel
          responsive={[
            {
              breakpoint: 9999,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]}
        >
          {content.map((item) => (
            <Box key={item['@id']} p={3}>
              <CardFolderItem
                publicContent={{
                  '@id': item['@id'],
                  title: item.title,
                  description: item.description,
                  previewUrl: item.videoPreviewUrl,
                  image: item.thumb
                }}
              />
            </Box>
          ))}
        </Carousel>
      </Container>
    </PseudoBoxCustom>
  )
}

export default SliderActivities
