import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'
import { AnamnesisModel } from '../../../../domain/models'

type AnamnesisProps ={
  slug: string
}

export const makeAnamnesis = async ({ slug }: AnamnesisProps): Promise<AnamnesisModel> => {
  try {
    const response: AxiosResponse = await api.get(`/anamnesis_forms/slug/${slug}`)
    return response.data
  } catch (_error) {
    return null
  }
}

export const sendAnamnesis = async (id: number, values): Promise<boolean> => {
  try {
    await api.post(`/anamnesis_forms/${id}/answers`, values)
    return true
  } catch (_error) {
    return false
  }
}
