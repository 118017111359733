import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/core'

const NavFooter: React.FC = () => {
  return (
    <div>
      <CustomBox justifyContent={['center', '', 'flex-start']}>
        <Link to="/duvidas-frequentes">
                    Dúvidas frequentes
        </Link>
        <Link to="/quem-somos">
                    Quem somos
        </Link>
        <Link to="/contato">
                    Contato
        </Link>
      </CustomBox>
      <CopyrightBox>
                Copyright 2021: CP TRAINING ONLINE TREINAMENTO DE PESSOAL LTDA. CNPJ 38.027.543/0001-75
      </CopyrightBox>
    </div>
  )
}

const CustomBox = styled(Box)<BoxProps>`
  display: flex;
  a {
    padding: 0 10px;
    border-right: 1px solid #fff;
    line-height: 15px;
    opacity: 0.5;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: 1;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
`
const CopyrightBox = styled(Box)<BoxProps>`
  position:relative;
  top:10px;
  left:10px;
  font-size:10px;
  color:#aaa
`

export default NavFooter
