import React from 'react'
import { BlockResultModel, FeatureGroupsModel } from '../../../domain/models'
import { PseudoBoxCustom } from '../../ui/layout'
import { Container, Typography } from '../../ui/foundation'
import { featureGroupsToView } from '../../../data/usecases/feature-group-to-view/feature-group-to-view'
import { Box, Flex, Grid, Image } from '@chakra-ui/core'

const FeatureGroupServices = (props: BlockResultModel): JSX.Element|null => {
  const content: FeatureGroupsModel = featureGroupsToView.build(props, 'php')
  if (!content) {
    return null
  }

  return (
    <PseudoBoxCustom
      pos="relative"
      py={16}
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'gray.700',
        bottom: -1,
        height: 35,
        clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)'
      }}>
      <Container>
        <Flex flexDir="column" w={['90%', '', '80%']} mx="auto">
          <Typography type="4xl" as="h2" textAlign="center">{content.name}</Typography>
          <Grid templateColumns={['repeat(2, 1fr)', '', 'repeat(4, 1fr)']} gap={5}>
            {content.features.map((feature) => (
              <Box key={feature['@id']} w="100%">
                {feature.image && <Image src={feature.image} ignoreFallback alt={feature.title} mb={4} />}
                <Typography type="2xl" as="h3" mb={4}>{feature.title}</Typography>
                {feature.description && (
                  <Typography type="sm" as={'div'} dangerouslySetInnerHTML={{ __html: feature.description }}/>
                )}
              </Box>
            ))}
          </Grid>
        </Flex>
      </Container>
    </PseudoBoxCustom>
  )
}

export default FeatureGroupServices
