import React from 'react'
import { Auth, I18n } from 'aws-amplify'
import { ButtonRedGreen, Container } from '../../component/ui/foundation'
import { Box, Grid } from '@chakra-ui/core'
import NavProfile from '../../component/nav-profile/nav-profile'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { InputPassword } from '../../component/form-elements'
import { useAlertBox } from '../../component/ui/layout/alert-box/alert-box-context'
import { getUser } from '../../utils/helpers/user'
import { changePassword } from '../../main/factories/usecases/user/user'
import { clearStorage } from '../../utils/helpers/clear-storage'

const UpdatePassword = ({ history }) => {
  const { alertBox } = useAlertBox()
  const user = getUser()
  const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: ''
  }

  const handleSubmit = async (values, actions) => {
    try {
      await Auth.signIn(user.user.email, values.oldPassword)
      try {
        await changePassword({
          plainPassword: values.password
        })
        alertBox('Senha alterada com sucesso')
        clearStorage()
        history.push('/login')
      } catch (e) {
        alertBox('Ocorreu um erro ao atualizar sua senha')
      }
    } catch (e) {
      alertBox('A sua senha está incorreta')
    }
    actions.resetForm({ values: initialValues })
  }

  return (
    <Box py={8}>
      <Container>
        <Grid templateColumns={['1fr', '1fr', '1fr 4fr']} gap={5} my={10}>
          <Box>
            <NavProfile urlActive={'/alterar-senha'} />
          </Box>
          <Box>
            <Box w={['100%', '100%', '40%']}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                  Yup.object().shape({
                    oldPassword: Yup.string().required(I18n.get('ValidateRequiredField')),
                    password: Yup.string()
                      .required(I18n.get('ValidateRequiredField'))
                      .min(8, I18n.get('ValidateMinPassword'))
                      .max(24, I18n.get('ValidateMaxPassword'))
                      .matches(/[a-z]/, I18n.get('ValidateOneLowerChar'))
                      .matches(/[A-Z]/, I18n.get('ValidateOneUpperChar'))
                      .matches(/[0-9]/, I18n.get('ValidateOneNumber'))
                      .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, I18n.get('ValidateOneSpecialChar')),
                    confirmPassword: Yup.string().when('password', {
                      is: (val) => val && val.length >= 8,
                      then: Yup.string()
                        .oneOf([Yup.ref('password')], I18n.get('ValidatePasswordNotEquals'))
                        .required(I18n.get('ValidateRequiredField'))
                    })
                  })
                }
                onSubmit={handleSubmit}
              >
                {(actions) => (
                  <Form>
                    <InputPassword title="Senha atual" value={actions.values.oldPassword} name="oldPassword" {...actions} />
                    <InputPassword title="Nova senha" value={actions.values.password} name="password" {...actions} />
                    <InputPassword title="Confirmar senha" value={actions.values.confirmPassword} name="confirmPassword" {...actions} />
                    <ButtonRedGreen
                      type="submit"
                      isLoading={actions.isSubmitting}
                      loadingText="Alterando"
                      isDisabled={actions.isSubmitting}
                    >
                      SALVAR
                    </ButtonRedGreen>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default UpdatePassword
