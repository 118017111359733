import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { makeNav } from '../../main/factories/usecases/auth/make-nav'
import { MenuModel } from '../../domain/models/menu'
import { RenderIcon } from '../ui/icons/render-icon'
import { outdatedCache } from '../../utils/helpers/storage/date'
import { getSocialNetworkMenu, setSocialNetworkMenu } from '../../utils/helpers/storage/menu'

const SocialNetwork: React.FC = () => {
  const [menu, setMenu] = useState<MenuModel>(null)

  useEffect(() => {
    (async () => {
      const isValid = await outdatedCache()
      const socialNetworkMenu = getSocialNetworkMenu()
      if (isValid || !socialNetworkMenu) {
        const data: MenuModel = await makeNav('redes-sociais')
        await setSocialNetworkMenu(data)
        setMenu(data)
      } else {
        setMenu(socialNetworkMenu)
      }
    })()
  }, [])

  if (!menu) {
    return null
  }

  return (
    <>
      <Flex justifyContent={['center', '', 'flex-end']}>
        {menu.menuItems.filter(item => item.icon !== 'whatsapp').map(item => (
          <Icon key={item['@id']} href={item.url} target="_blank">
            <RenderIcon name={item.icon} />
          </Icon>
        ))}
      </Flex>
      <Box>
        {menu.menuItems.filter(item => item.icon === 'whatsapp').map(item => (
          <IconFixed key={item['@id']} href={item.url} target="_blank">
            <RenderIcon name={item.icon} />
          </IconFixed>
        ))}
      </Box>
    </>
  )
}

const IconFixed = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  width: 50px;
`

const Icon = styled.a`
  padding: 5px;
  margin: 0 5px;
  transition: opacity .2s ease-in-out;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

export default SocialNetwork
