import React from 'react'

import { useFormContext } from 'react-hook-form'

export default function ShortAnswerInput ({ field }) {
  const { register } = useFormContext()

  return (
    <div>
      <input type='text' {...register(field.id, { required: field.required })} />
    </div>
  )
}
