import React, { useState, useEffect } from 'react'
import { ButtonRedPurple, Typography } from '../ui/foundation'
import styled from '@emotion/styled/macro'
import { Avatar, Box, Flex, PseudoBox } from '@chakra-ui/core'
import { getUser } from '../../utils/helpers/user'
import { I18n } from 'aws-amplify'
import { AxiosResponse } from 'axios'
import { api } from '../../infra/http/remote'
import { AlertBoxContextInterface, useAlertBox } from '../ui/layout/alert-box/alert-box-context'
import { useCommentProvider } from './contexts/comment-provider'
import { FastField, Form, Formik } from 'formik'
import * as Yup from 'yup'

interface CommentItemProps {
  index: any
  comment: any,
  contentUri: string,
}

const CommentItem = ({ index, comment, contentUri }: CommentItemProps) => {
  const { alertBox }: AlertBoxContextInterface = useAlertBox()
  const user = getUser()
  const [replyComments, setReplyComments] = useState([])
  const [replyOpen, setReplyOpen] = useState(false)
  const { setCommentParams } = useCommentProvider()

  const handleRemoveComment = async (idComment: number, reply = false) => {
    try {
      await api.delete(`/comments/${idComment}`)
      if (reply) {
        setReplyComments(prev => (prev.filter(item => item.id !== idComment)))
      } else {
        setCommentParams({ id: contentUri.replace('/v1/contents/', ''), date: new Date() })
      }

      alertBox('Comentário excluído com sucesso')
    } catch (e) {
      alertBox('Não foi possível excluir seu comentário')
    }
  }

  const onSubmitReply = async (values, actions) => {
    try {
      const res:AxiosResponse = await api.post('/comments', {
        text: values.text,
        content: contentUri,
        parent: `/v1/comments/${comment.id}`
      })
      setReplyComments(prev => ([
        ...prev,
        res.data
      ]))
      alertBox('Obrigado pelo seu comentário')
      setReplyOpen(false)
      actions.resetForm()
    } catch (e) {
      alertBox('Não foi possível responder seu comentário')
    }
  }

  useEffect(() => {
    setReplyComments(comment.children || [])
  }, [comment])

  if (!comment) return null

  return !comment.parentId && (
    <>
      <Flex
        key={comment['@id']}
        mt={5}
        bg={index % 2 === 0 ? '#eee' : '#f9f9f9'}
        p={4}
        borderRadius="4px"
        pos="relative"
      >
        <Avatar src={comment.user.avatar} title={comment.user.name} />
        <Box w="100%" ml="10px">
          <Typography type="xs" mb={0}><b>{comment.user.name}</b></Typography>
          {comment.text}

          {replyOpen && <Formik
            initialValues={{ text: '' }}
            validationSchema={Yup.object().shape({
              text: Yup.string().required(I18n.get('ValidateRequiredField'))
            })}
            onSubmit={onSubmitReply}
          >
            {(actions) => (
              <Form style={{ width: '100%', marginTop: '10px' }}>
                <Flex>
                  <CustomField>
                    <FastField name="text" placeholder="Escreva um comentário" />
                  </CustomField>
                  <ButtonRedPurple
                    h={8}
                    px={8}
                    type="submit"
                    isLoading={actions.isSubmitting}
                    loadingText="Enviando"
                    isDisabled={actions.isSubmitting}
                  >Enviar</ButtonRedPurple>
                </Flex>
              </Form>
            )}
          </Formik>}

          <Flex>
            {comment.user.id === user.user.id && (
              <PseudoBox
                mt={4}
                fontSize="xs"
                color="#999"
                cursor="pointer"
                onClick={() => handleRemoveComment(comment.id)}
                _hover={{
                  color: '#313131'
                }}
              >Excluir</PseudoBox>
            )}
            <PseudoBox
              mt={4}
              ml={2}
              fontSize="xs"
              color="#999"
              cursor="pointer"
              onClick={() => setReplyOpen(!replyOpen)}
              _hover={{
                color: '#313131'
              }}
            >Responder</PseudoBox>
          </Flex>
        </Box>
      </Flex>

      {replyComments.map((reply, index) => (
        <Flex
          key={reply['@id']}
          bg={index % 2 === 0 ? '#eee' : '#f9f9f9'}
          p={2}
          borderRadius="4px"
          pos="relative"
          className="reply-comment"
        >
          <Avatar src={reply.user.avatar} className="sub-avatar" title={reply.user.name} />
          <Box w="100%" ml="10px">
            <Typography type="xs" mb={0}><b>{reply.user.name}</b></Typography>
            {reply.text}

            {reply.user.id === user.user.id && (
              <PseudoBox
                mt={1}
                fontSize="xs"
                color="#999"
                cursor="pointer"
                onClick={() => handleRemoveComment(reply.id, true)}
                _hover={{
                  color: '#313131'
                }}
              >Excluir</PseudoBox>
            )}
          </Box>
        </Flex>
      ))}
    </>
  )
}

export default CommentItem

const CustomField = styled(Box)`
  width: 100%;
  position: relative;
  margin-right: 10px;

  input {
    border: none;
    width: 100%;
    padding: 5px 15px;
    height: 32px;
    margin-right: 15px;
    border-radius: 32px;
  }
`
