import React from 'react'
import { BannerModel, BlockResultModel } from '../../../domain/models'
import { Box, Flex, Image } from '@chakra-ui/core'
import { Container, Typography } from '../../ui/foundation'
import { bannerToView } from '../../../data/usecases/banner-to-view/banner-to-view'
import { Carousel } from '../../ui/layout'
import styled from '@emotion/styled'

const Partners = (props: BlockResultModel): JSX.Element|null => {
  const { block } = props
  const content: BannerModel|null = bannerToView.build(props, 'php')

  if (!content) {
    return null
  }

  return (
    <Box id={`block-${block.id}`} py={[6, 8, 40]}>
      <Container>
        <Flex w={['90%', '', '80%']} mx="auto" alignItems="center" flexWrap="wrap">
          <Box w={['100%', '', '25%']}>
            <Typography type="4xl" as="h2" mb={4}>{content.title}</Typography>
            {content.description && (
              <Typography type="sm" as={'div'} mb={8} dangerouslySetInnerHTML={{ __html: content.description }}/>
            )}
          </Box>
          <CustomBox w={['100%', '', '75%']}>
            <Carousel responsive={[
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 575,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
            >
              {content.images.map((image, index) => (
                <Box key={index} px={4}>
                  <Image src={image.image} ignoreFallback alt={image.title} />
                </Box>
              ))}
            </Carousel>
          </CustomBox>
        </Flex>
      </Container>
    </Box>
  )
}

const CustomBox = styled(Box)`
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
`

export default Partners
