import React from 'react'
import { BlockResultModel } from '../../../domain/models'
import { PseudoBoxCustom } from '../../ui/layout'
import { Typography } from '../../ui/foundation'
import { Flex } from '@chakra-ui/core'

const Lettering = ({ block }: BlockResultModel): JSX.Element|null => {
  return (
    <PseudoBoxCustom
      pos="relative"
      py={16}
      bg="green.700"
      color="white"
      _before={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'green.700',
        top: '-33px',
        height: '35px',
        clipPath: 'polygon(0 100%,100% 100%,100% 0)',
        zIndex: 9
      }}
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: 'green.700',
        bottom: '-33px',
        height: '35px',
        clipPath: 'polygon(0% 0%,100% 100%,100% 0%)'
      }}>
      <Flex flexDir="column" w={['90%', '80%', '70%']} mx="auto">
        <Typography
          as="h2"
          textAlign="center"
          fontSize={['2.2rem', '2.8rem', '3.1rem']}
          lineHeight={1.3}
        >{block.title}</Typography>
      </Flex>
    </PseudoBoxCustom>
  )
}

export default Lettering
