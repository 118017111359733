import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'
import { MenuModel } from '../../../../domain/models/menu'

export const makeNav = async (slug: string): Promise<MenuModel> => {
  try {
    const response: AxiosResponse = await api.get(`/menus/slug/${slug}`)
    const { data } = response
    return {
      '@id': data['@id'],
      id: data.id,
      title: data.title,
      slug: data.slug,
      menuItems: data.menuItems.map(item => ({
        '@id': item['@id'],
        id: item.id,
        position: item.position,
        target: item.target,
        title: item.title,
        url: item.url,
        icon: item.icon ? item.icon.name : null
      }))
    }
  } catch (e) {
    return null
  }
}
