import React from 'react'
import { AuthorModel } from '../../../../domain/models'
import { Avatar, Box, Flex } from '@chakra-ui/core'
import { Typography } from '../../foundation'

interface AuthorTagProps {
  author: AuthorModel
  publishedAt: string
}
const AuthorTag = ({ author, publishedAt }: AuthorTagProps): JSX.Element => {
  return (
    <Flex alignItems="center" mb={8} mt={4}>
      <Avatar size="sm" mr={2} name={author.name} src={author.avatar} />
      <Box>
        <Typography type="sm" as="div">Por {author.name}</Typography>
        <Typography type="xs" as="div" color="gray.500">{publishedAt}</Typography>
      </Box>
    </Flex>
  )
}

export default AuthorTag
