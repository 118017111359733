import React, { useState } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled/macro'
import { useAlertBox } from '../ui/layout/alert-box/alert-box-context'
import { api } from '../../infra/http/remote'

type Rating = 0|1|2|3|4|5

interface RatingsProps {
  contentId: string
  rating: Rating
}

const Ratings: React.FC<RatingsProps> = ({ rating, contentId }) => {
  const { alertBox } = useAlertBox()
  const stars: Rating = 5
  const [currentRating, setCurrentRating] = useState<Rating>(rating)

  const handleSubmit = async (numStars: Rating) => {
    try {
      await api.post('user_collection_ratings', {
        content: contentId,
        rating: numStars
      })
      setCurrentRating(numStars)
    } catch (e) {
      alertBox('Ocorreu um erro ao avaliar o conteúdo, tente novamente mais tarde')
      setCurrentRating(prev => prev)
    }
  }

  return (
    <Box>
      <CustomFlex flexDir="row-reverse" py={4}>
        {[...Array(stars).keys()].reverse().map((star: any) => (
          <React.Fragment key={star}>
            <input
              type="radio"
              id={`${star + 1}star`}
              name="rating"
              onClick={() => handleSubmit(star + 1)}
              defaultChecked={currentRating === star + 1}
            />
            <label htmlFor={`${star + 1}star`} title={`Avaliar com ${star + 1} estrelas`} />
          </React.Fragment>
        ))}
      </CustomFlex>
    </Box>
  )
}

export default Ratings

const CustomFlex = styled(Flex)`
  > input {
    display: none;
  }
  
  > input:checked ~ label,
  &:not(:checked) > label:hover,
  &:not(:checked) > label:hover ~ label {
    color: #ffd862;
    cursor: pointer;
    &:before {
      content: '\\2605';
    }
  }

  > label {
    color: #ffd862;
    margin-left: 2px;
    &:before {
      font-family: 'fontello', sans-serif;
      font-size: 18px;
      display: inline-block;
      content: '\\2606';
    }

    &:checked + label:hover,
    &:checked ~ label:hover,
    &:hover ~ input:checked ~ label,
    &:checked ~ label:hover ~ label {
      color: #ffaa00;
    }
  }
`
