import { PageModel, BlockResultModel, PageResultModel } from '../../../../domain/models'
import { api } from '../../../../infra/http/remote'
import { makeBlock } from '../block/block'

export const makePage = async (slug: string): Promise<PageResultModel> => {
  try {
    const response = await api.get(`/pages/slug/${slug}`)
    const page: PageModel = response.data
    const containers: BlockResultModel[] = await Promise.all(page.blockPages.map(async ({ block, ...item }) =>
      await makeBlock({
        ...block,
        position: item.position,
        container: item.container.name
      })
    ))

    return {
      id: page.id,
      title: page.title,
      hideTitle: page.hideTitle,
      content: page.content,
      slug: page.slug,
      containers: containers.filter(item => item !== null)
    }
  } catch (e) {
    return {
      id: 0,
      title: 'Página não encontrada',
      hideTitle: false,
      content: '<p>A página não existe ou foi excluída</p>',
      slug: '',
      containers: []
    }
  }
}
