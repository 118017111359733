import React, { useEffect, useState } from 'react'
import { ButtonRedGreen, Container, Typography } from '../../component/ui/foundation'
import { Box, Flex, Grid } from '@chakra-ui/core'
import NavProfile from '../../component/nav-profile/nav-profile'
import PaymentMethods from '../../component/payment/payment-methods'
import { useModal } from '../../component/ui/layout'
import { getPaymentProfiles } from '../../main/factories/usecases/payment/payment'
import Loading from '../../component/loading/loading'
import { PaymentProfilesModel } from '../../domain/models'
import styled from '@emotion/styled'
import { getExpiredCard } from '../../utils/helpers/date'
import { enumPaymentProfile } from '../../utils/enums/status'

const UpdateCreditCard = () => {
  const { alertBox } = useModal()
  const [loading, setLoading] = useState<boolean>(true)
  const [profiles, setProfiles] = useState<PaymentProfilesModel[]>([])
  const [reload, setReload] = useState<boolean>(false)

  const handleReloadPage = (): void => {
    setReload(prev => !prev)
  }

  useEffect(() => {
    (async () => {
      setProfiles(await getPaymentProfiles())
      setLoading(false)
    })()
  }, [reload])

  const handleChangeCreditCard = () => {
    alertBox(
      <Box p={8}>
        <Typography type="2xl">Alterar cartão de crédito</Typography>
        <PaymentMethods hasUpdate handleReloadPage={handleReloadPage} />
      </Box>
    )
  }

  if (loading) {
    return <Loading/>
  }

  return (
    <Box py={8}>
      <Container>
        <Grid templateColumns={['1fr', '1fr', '1fr 4fr']} gap={5} my={10}>
          <Box>
            <NavProfile urlActive={'/alterar-cartao'} />
          </Box>
          <Box>
            <Flex flexWrap="wrap" mx={-4}>
              {profiles.map(item => (
                <Box key={item['@id']} w={['100%', '100%', '50%']} mb={4} px={4}>
                  <BoxItem>
                    <p><b>Titular: </b>{item.holderName}</p>
                    <p><b>Número do cartão: </b>{item.cardNumberFirstSix}*******{item.cardNumberLastFour}</p>
                    <p><b>Validade: </b>{getExpiredCard(item.cardExpiration)}</p>
                    <p><b>Status: </b>{enumPaymentProfile(item.status)}</p>
                  </BoxItem>
                </Box>
              ))}
            </Flex>
            <ButtonRedGreen mt={8} onClick={handleChangeCreditCard}>Alterar o método de pagamento</ButtonRedGreen>
          </Box>

        </Grid>
      </Container>
    </Box>
  )
}

export default UpdateCreditCard

const BoxItem = styled(Box)`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 4px;
`
