import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/core'
import { ButtonRedGreen, Typography } from '../../component/ui/foundation'
import { CardPlanItem, StepsDots } from '../../component/ui/layout'
import { PlanDetailModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { Redirect, useHistory } from 'react-router'
import { makePlan } from '../../main/factories/usecases/subscriptions/make-plans'
import { getStorePlanSession } from '../../utils/helpers/store-plan-session'
import { removeLocalStorage } from '../../infra/storage/localStorage'
import { registerPayment } from '../../main/factories/usecases/payment/payment'
import { Form, Formik } from 'formik'
import { settings } from '../../config/settings'
import { useAlertBox } from '../../component/ui/layout/alert-box/alert-box-context'

const PaymentTrial = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<PlanDetailModel>(null)
  const [currentInstallments] = useState<number>(1)
  const history = useHistory()
  const { alertBox } = useAlertBox()

  useEffect(() => {
    (async () => {
      try {
        const plan = getStorePlanSession()
        const hasPlanItem = ('planItems' in plan)
        const id = parseInt(plan.plan.replace('/v1/plans/', ''), 10)
        const content = await makePlan(id)
        removeLocalStorage('coupon')

        setData(() => ({
          ...content,
          planItems: content.planItems.filter(item => {
            if (hasPlanItem) {
              return item.showPlanItem && plan.planItems.includes(item['@id'])
            }
            return item.showPlanItem
          })
        }))

        /* setTotalPrice(() => {
          let total = 0
          if (hasPlanItem) {
            content.planItems.filter((item) => plan.planItems.includes(item['@id'])).forEach(item => {
              total += item.price
            })
            return total
          }

          return content.totalPrice
        }) */
        setLoading(false)
      } catch (e) {
        setData(null)
        setLoading(false)
      }
    })()
  }, [])

  const onSubmit = async () => {
    const opts = { trial: true }
    if (data.installments) {
      Object.assign(opts, {
        installments: currentInstallments
      })
    }
    const response = await registerPayment({ }, opts, false)
    console.error(response)
    if (typeof response === 'string') {
      alertBox(response, () => history.replace(settings.dashboardRoute))
    } else {
      if ('status' in response && response.status === 201) {
        alertBox(response.message, history.replace(settings.dashboardRoute))
      } else if ('code' in response && response.code === 5) {
        alertBox(response.message)
      } else {
        alertBox(JSON.stringify(response))
      }
    }
  }

  if (loading) {
    return <Loading/>
  }

  if (!data) {
    return <Redirect to="/escolha-seu-plano"/>
  }

  return (
    <Box py={8}>

      <StepsDots currentDot={3}/>

      <Box textAlign="center" w={['100%', '', '50%']} mx="auto" mb={12}>
        <Typography type="4xl" as="h1" mb={4}>CONFIRMAÇÃO DE CADASTRO PARA O TRIAL</Typography>
        {/* <Typography type="sm" as="p" mb={8}>
          Não se preocupe, você não está pagando nada hoje.
          Em {getTrialsUntil(7)}, a forma de pagamento que você adicionar será cobrada.
        </Typography> */}
        {/* <Typography type="md" w={['100%', '', '100%']} as="h2" mb={8} textAlign="left">Plano escolhido</Typography> */}
      </Box>

      <Box w={['100%', '70%', '33.33333%', '25%']} mx="auto" mt={-10} float="left" justifyContent="center">
        <Typography w={['100%', '', '100%']} as="h2" mb={8}>Plano escolhido:</Typography>
        <CardPlanItem dark data={{
          title: data.name,
          description: data.description,
          image: data.image,
          selectable: true,
          totalPrice: data.totalPrice,
          url: '/pagamento-trial'
        }}/>
      </Box>

      <Box w={['100%', '', '70%']} mx="auto" mb={12} mt={5} display="inline-flex" justifyContent="center">
        <Box w={['100%', '', '70%']}>
          <Formik
            onSubmit={onSubmit} initialValues={{}}>
            {(actions) => (
              <Form>
                <div style={{ height: '500px' }}>
                        Você terá 7 dias para desfrutar deste plano.

                  <ButtonRedGreen
                    mt={8}
                    display={'block'}
                    type="submit"
                    isLoading={actions.isSubmitting}
                    loadingText="Registrando"
                  >CONTINUAR</ButtonRedGreen>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentTrial
