import React, { createContext, useContext, useState, useEffect } from 'react'
import { makeComments } from '../../../main/factories/usecases/current-customer/comments'

interface CommentContextInterfaceNew {
  comments: any
  setCommentParams: any
}

const CommentContext = createContext<CommentContextInterfaceNew>(null)

export const useCommentProvider = () => useContext(CommentContext)

const CommentProvider = ({ children }) => {
  const [commentParams, setCommentParams] = useState<any>(false)
  const [comments, setComments] = useState<any>(false)

  const getComments = async ({ id }) => {
    try {
      setComments(false)
      const response = await makeComments(id)
      setComments(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (commentParams.id) getComments(commentParams)
  }, [commentParams])

  return (
    <CommentContext.Provider
      value={{ comments, setCommentParams }}
    >
      {children}
    </CommentContext.Provider>
  )
}

export default CommentProvider
