import { theme, DefaultTheme } from '@chakra-ui/core'

const customTheme: DefaultTheme = {
  ...theme,
  breakpoints: ['576px', '768px', '992px', '1200px'],
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: 'Biennale, sans-serif',
    mono: 'monospace'
  },
  fontWeights: {
    ...theme.fontWeights,
    normal: 400,
    thin: 200,
    bold: 700
  },
  radii: {
    ...theme.radii,
    sm: '8px',
    md: '16px',
    lg: '30px'
  },
  fontSizes: {
    ...theme.fontSizes,
    xs: '0.750rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.250rem', // 20px
    '2xl': '1.500rem', // 24px
    '3xl': '1.750rem', // 28px
    '4xl': '2.375rem', // 38px
    '5xl': '3.750rem' // 60px
  },
  colors: {
    ...theme.colors,
    gray: {
      ...theme.colors.gray,
      600: '#575c66',
      700: '#464b55'
    },
    green: {
      ...theme.colors.green,
      500: '#66e37a',
      700: '#37dc96',
      800: '#26cb85'
    },
    pink: {
      ...theme.colors.pink,
      200: '#fc616f'
    },
    purple: {
      ...theme.colors.purple,
      500: '#815da6'
    }
  }
}

export default customTheme
