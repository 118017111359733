import React from 'react'
import { Typography, ButtonGreen } from '../../foundation'
import {
  AspectRatioBox,
  Box, BoxProps,
  Image
} from '@chakra-ui/core'
import theme from '../../../../styles/theme'
import styled from '@emotion/styled/macro'
import { ModalContextInterface, useModal } from '../'
import { getVideoUrlEmbed } from '../../../../utils/helpers/get-video-url-embed'
import { useHistory } from 'react-router'

type CardFolderItemProps = {
  publicContent: {
    '@id': string
    title: string
    description?: string
    previewUrl?: string
    image?: string
    url?: string
  }
}

const CardFolderItem = ({ publicContent: content }: CardFolderItemProps): JSX.Element => {
  const { setOpen, setContent }: ModalContextInterface = useModal()
  const history = useHistory()

  const handleClick = () => {
    history.push(content.url)
  }
  const handleOpen = () => {
    setOpen(true)
    const validUrl = getVideoUrlEmbed(content.previewUrl)
    if (validUrl) {
      setContent(
        <AspectRatioBox ratio={16 / 9}>
          <Iframe
            as="iframe"
            title={content.title}
            src={validUrl}
            allowFullScreen
          />
        </AspectRatioBox>
      )
    } else {
      setContent(<Typography type="2xl" as="h3" mb={1}>Tipo de mídia inválida</Typography>)
    }
  }

  return (
    <BoxItem
      key={content['@id']}
      borderRadius="md"
      color="white"
    >
      <AspectRatioBox ratio={8 / 12} key={content['@id']} borderRadius="md">
        <Image
          objectFit="cover"
          src={content.image}
          alt={content.title}
          ignoreFallback
        >
        </Image>
      </AspectRatioBox>
      <MetaInfo>
        <Typography type="xl" as="h3" mb={1} isTruncated>{content.title}</Typography>
      </MetaInfo>
      {(content.description || content.previewUrl || content.url) && (
        <Infos bg="gray.700">
          <Description dangerouslySetInnerHTML={{ __html: content.description }}/>
          {content.url && (<ButtonGreen onClick={handleClick}>VAMOS JUNTOS ?</ButtonGreen>)}
          {content.previewUrl && !content.url && (<ButtonGreen onClick={handleOpen}>VAMOS JUNTOS ?</ButtonGreen>)}
        </Infos>
      )}
    </BoxItem>
  )
}

type IframeProps = BoxProps & {
  src: string
  allowFullScreen: boolean
}
const Iframe = styled(Box)<IframeProps>``

const Infos = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
`

const BoxItem = styled(Box)`
  margin: 30px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.53);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    ${Infos} {
      display: flex;
      transition: opacity 0.3s ease-in;
      opacity: 1;
    }
  }
`

const MetaInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(10deg, #313131 0%, transparent 75%);
`

const Description = styled.div`
  margin-bottom: ${theme.space['4']};
  h3 {
    font-size: ${theme.fontSizes['2xl']};
    color: ${theme.colors.white};
    font-weight: bold;
    margin-bottom: ${theme.space['3']};
  }

  h4 {
    color: ${theme.colors.green['500']};
    font-weight: bold;
    text-transform: uppercase;
  }
  
  p {
  
  }
`

export default CardFolderItem
