import React from 'react'

import { useFormContext } from 'react-hook-form'

export default function HiddenInput ({ field, value }) {
  const { register } = useFormContext()

  return (
    <input key={`K${field.id}`} type='hidden' value={value} {...register(field.id, { required: field.required })} />
  )
}
