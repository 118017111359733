import React from 'react'
import { Box, Spinner } from '@chakra-ui/core'

const Loading = (): JSX.Element => {
  return (
    <Box py={24} textAlign="center">
      <Spinner />
    </Box>
  )
}

export default Loading
