import React, { ComponentProps } from 'react'
import { BlockResultModel } from '../../domain/models'
import ComponentsList from './components-list'

const ComponentFactory = (block: BlockResultModel): JSX.Element|null => {
  const ComponentRender: ComponentProps<any> = ComponentsList[block.componentName]

  if (ComponentRender) {
    return <ComponentRender {...block} />
  }

  return null
}

export default ComponentFactory
