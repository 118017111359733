import React from 'react'
import { I18n } from 'aws-amplify'
import { BlockResultModel } from '../../../domain/models'
import { Box } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { Input, Textarea } from '../../form-elements'
import { ButtonRedGreen, Typography } from '../../ui/foundation'
import { useAlertBox } from '../../ui/layout/alert-box/alert-box-context'
import { getUser } from '../../../utils/helpers/user'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import {
  sendContact,
  SendContactValuesProps
} from '../../../main/factories/usecases/contact/send-contact'

const Contact = ({ block, content }: BlockResultModel): JSX.Element|null => {
  const { alertBox } = useAlertBox()
  const history = useHistory()
  const user = getUser()
  const initialValues: SendContactValuesProps = {
    name: '',
    email: user ? user.user.email : '',
    subject: '',
    message: ''
  }

  return (
    <Box width={['100%', '70%', '50%', '40%']} mx="auto">
      {block.description && (
        <Typography textAlign="center" type="sm" mb={8} dangerouslySetInnerHTML={{ __html: block.description }} />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(I18n.get('ValidateRequiredField')),
          subject: Yup.string().required(I18n.get('ValidateRequiredField')),
          message: Yup.string().required(I18n.get('ValidateRequiredField')),
          email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField'))
        })}
        onSubmit={async (values) => {
          const response = await sendContact(content.slug, values)

          if (response) {
            alertBox('Enviado com sucesso')
            history.push('/')
          } else {
            alertBox('Erro ao enviar formulário')
          }
        }}
      >
        {(actions) => (
          <Form>
            <Input title="Nome" name="name" {...actions} />
            <Input title="Email" name="email" {...actions} />
            <Input title="Assunto" name="subject" {...actions} />
            <Textarea title="Mensagem" name="message" {...actions} />

            <Box textAlign="center">
              <ButtonRedGreen
                type="submit"
                isLoading={actions.isSubmitting}
                loadingText="Enviando"
                isDisabled={actions.isSubmitting}
              >
                Enviar
              </ButtonRedGreen>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default Contact
