import React from 'react'
import { ColorModeProvider, CSSReset, ThemeProvider as ChakraThemeProvider } from '@chakra-ui/core'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import { Global } from '@emotion/core'
import theme from '../../styles/theme'
import { globalStyles } from '../../styles/globals'

const ThemeContainer: React.FC = ({ children }) => {
  return (
    <ChakraThemeProvider theme={theme}>
      <ColorModeProvider value="light">
        <EmotionThemeProvider theme={{}}>
          <CSSReset />
          <Global styles={globalStyles}/>
          {children}
        </EmotionThemeProvider>
      </ColorModeProvider>
    </ChakraThemeProvider>
  )
}

export default ThemeContainer
