import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { Container } from '../ui/foundation'
import NavBar from '../navbar/navbar'

const Header: React.FC = () => {
  const [pos, setPos] = useState<'fixed'|'relative'>('relative')

  useEffect(function mount () {
    const handleScroll = () => {
      const header = document.getElementById('header')
      if (
        header && window.scrollY > header.offsetHeight &&
        (document.getElementsByTagName('body')[0].offsetHeight + (header.offsetHeight * 2)) > window.outerHeight
      ) {
        setPos('fixed')
      } else {
        setPos('relative')
      }
    }
    window.addEventListener('scroll', handleScroll)

    return function unMount () {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Box
      bg="green.700"
      id="header"
      pos={pos}
      top={0}
      left={0}
      right={0}
      zIndex={99}
    >
      <Container>
        <Flex flexWrap="wrap" alignItems="center">
          <NavBar />
        </Flex>
      </Container>
    </Box>
  )
}

export default Header
