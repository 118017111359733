import styled from '@emotion/styled'
import { PseudoBox, BoxProps } from '@chakra-ui/core'

interface IPseudoBoxProps {
  _after?: BoxProps & {
    content: string;
    clipPath?: string
  };
  _before?: BoxProps & {
    content: string;
    clipPath?: string
  };
}

export type PseudoAfterProps = IPseudoBoxProps & BoxProps;

const PseudoBoxCustom = styled(PseudoBox)<PseudoAfterProps>``

export default PseudoBoxCustom
