import React, { useState } from 'react'
import { FormControl, InputCustom, InputError, SelectCustom } from './styles'
import { Box, Flex, FormLabel } from '@chakra-ui/core'
import { Field, ErrorMessage, FieldAttributes } from 'formik'
import InputMask from 'react-input-mask'
import { ddiWithMasks } from '../../utils/enums/ddi-masks'

interface InputProps extends FieldAttributes<any>{
  title: string
  name: string
  nameCode: string
  defaultCode?: string
  placeholder?: string
}

const InputPhoneMasked = ({ name, nameCode, title, placeholder, defaultCode, ...el }: InputProps) => {
  const [mask, setMask] = useState<string>(ddiWithMasks[defaultCode] || '(99) 99999-9999')
  const code = defaultCode || '55'

  const selectMask = (e: React.FormEvent<HTMLSelectElement>) => {
    setMask(ddiWithMasks[e.currentTarget.value])
    el.setFieldValue(nameCode, e.currentTarget.value)
    el.setFieldValue(name, '')
  }

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    if (el.setFieldValue) {
      el.setFieldValue(name, e.currentTarget.value)
    }
  }

  return (
    <FormControl>
      <FormLabel htmlFor={name}>{title}</FormLabel>
      <Flex>
        <Box w="35%" mr={4}>
          <SelectCustom onChange={selectMask} defaultValue={code}>
            {Object.keys(ddiWithMasks).map((item, idx) => (
              <option key={`ddi-${idx}`} value={item}>+{item}</option>
            ))}
          </SelectCustom>
        </Box>
        <Box w="75%">
          <Field name={name}>
            {({ field }) => (
              <InputMask
                {...field}
                placeholder={placeholder || ''}
                maskChar=""
                mask={mask}
                onChange={handleChange}
              >
                {(inputProps: any) => <InputCustom {...inputProps} />}
              </InputMask>
            )}
          </Field>
        </Box>
      </Flex>
      <ErrorMessage name={name} component={InputError} />
    </FormControl>
  )
}

export default InputPhoneMasked
