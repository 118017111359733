import React from 'react'
import { ButtonRedGreen, Typography } from '../ui/foundation'
import { Box, Flex } from '@chakra-ui/core'
import { InputCustom } from '../form-elements/styles'
import CouponDetails from '../coupon/coupon-details'
import { useCoupon } from './coupon-context'

const Coupon = () => {
  const { setCoupon, handleCoupon, couponActive, couponDetails } = useCoupon()

  return (
    <Box my={8}>
      <Typography mb={4}>Você tem um código promocional?</Typography>
      <Flex flexWrap="wrap">
        <Box w={['100%', '100%', '50%']}>
          <InputCustom
            placeholder="Digite seu código promocional?"
            onChange={(e) => setCoupon(e.target.value)}
          />
        </Box>
        <Box w={['100%', '100%', '20%']} ml={[0, 0, 4]}>
          <ButtonRedGreen w="100%" h={10} onClick={handleCoupon}>APLICAR</ButtonRedGreen>
        </Box>
      </Flex>
      {couponActive && (
        <Box>
          {couponDetails ? (
            <CouponDetails couponDetail={couponDetails} />
          ) : (
            <p>Cupom inválido</p>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Coupon
