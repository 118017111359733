import React from 'react'
import { FormControl, TextareaCustom, InputError } from './styles'
import { FormLabel } from '@chakra-ui/core'
import { Field, ErrorMessage, FieldAttributes } from 'formik'

interface TextAreaProps extends FieldAttributes<any>{
  title: string
  name: string
  placeholder?: string
}

const Textarea = ({ name, title, placeholder, ...el }: TextAreaProps) => {
  return (
    <FormControl>
      <FormLabel htmlFor={name}>{title}</FormLabel>
      <Field
        {...el}
        name={name}
        id={name}
        placeholder={placeholder || ''}
        onChange={(val) => {
          if (el.setFieldValue) {
            el.setFieldValue(name, val.target.value)
          }
        }}
        as={TextareaCustom}
      />
      <ErrorMessage name={name} component={InputError} />
    </FormControl>
  )
}

export default Textarea
