import React from 'react'
import { Heading, HeadingProps, Text } from '@chakra-ui/core'

type TypographyProps = HeadingProps & {
  type?: '5xl'|'4xl'|'3xl'|'2xl'|'xl'|'lg'|'md'|'sm'|'xs'
}

const Typography: React.FC<TypographyProps> = ({ children, as = 'div', type = 'md', ...props }: TypographyProps) => {
  const attrs = {
    lineHeight: 1.4
  }

  switch (type) {
    case '5xl':
      Object.assign(attrs, { lineHeight: 0.9, fontSize: ['4xl', '4xl', '5xl'] })
      break
    case '4xl':
      Object.assign(attrs, { lineHeight: 1.2, fontSize: ['3xl', '3xl', '4xl'] })
      break
    case '3xl':
      Object.assign(attrs, { lineHeight: 1.2, fontSize: ['3xl', '3xl', '3xl'] })
      break
    case '2xl':
      Object.assign(attrs, { lineHeight: 1.2, fontSize: ['xl', '2xl', '3xl'] })
      break
    case 'xl':
      Object.assign(attrs, { lineHeight: 1.2, fontSize: 'xl' })
      break
    case 'lg':
      Object.assign(attrs, { fontSize: 'lg' })
      break
    case 'sm':
      Object.assign(attrs, { fontSize: 'sm' })
      break
    case 'xs':
      Object.assign(attrs, { fontSize: 'xs' })
      break
    default:
      Object.assign(attrs, { fontSize: 'md' })
      break
  }

  if (as.toString().indexOf('h') === -1) {
    return (
      <Text
        {...attrs}
        {...props}
        as={as || 'div'}
      >{children}</Text>
    )
  }

  return (
    <Heading
      marginBottom={8}
      as={as || 'div'}
      {...attrs}
      {...props}
    >
      {children}
    </Heading>
  )
}

export default Typography
