import { Auth } from 'aws-amplify'
import axios from 'axios'
import { settings } from '../../config/settings'
import { resetSessions } from '../../main/factories/usecases/user/user'

export const api = axios.create({
  baseURL: settings.API_URL
})

api.interceptors.request.use(async (config) => {
  try {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()
    Object.assign(config, {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token
      }
    })
    return Promise.resolve(config)
  } catch (e) {
    return Promise.resolve(config)
  }
}, (error) => {
  return Promise.reject(error)
})

api.interceptors.response.use((response) => response, async (error) => {
  if ('response' in error) {
    if (error.response.status === 401 && error.response.data.detail === 'Authentication Required: Login Limit Exceed') {
      await resetSessions()
    }
    if (error.response.status === 403) {
      window.location.href = '/403'
    }
  } else {
    window.location.href = '/sair'
  }
  return Promise.reject(error)
})
