import { AllTrainingToViewInterface } from '../../../domain/usecases/contents-to-view/all-training-to-view'
import { FolderContentModel } from '../../../domain/models'
import { getImage } from '../../../utils/helpers/get-image'
import { enumWeekDay, enumWeekDaySlug } from '../../../utils/enums/week-day'

export class AllTrainingToView implements AllTrainingToViewInterface {
  build (content: any): FolderContentModel[] {
    return content.map(item => ({
      '@id': item['@id'],
      folder: {
        '@id': item.folder['@id'],
        id: item.folder.id,
        title: item.folder.title,
        slug: item.folder.slug,
        position: item.folder.position,
        description: item.folder.description,
        videoPreviewUrl: item.folder.videoPreviewUrl,
        logo: getImage(item.folder.logo, true),
        thumb: getImage(item.folder.thumb),
        views: item.folder.views,
        access: item.folder.access,
        likes: item.folder.likes,
        dislikes: item.folder.dislikes,
        favorites: item.folder.favorites,
        rating: item.folder.rating,
        currentUserCollection: {
          ...item.folder.currentUserCollection
        }
      },
      contents: item.contents.map((ctt) => ({
        '@id': ctt['@id'],
        slug: ctt.slug,
        publishAt: ctt.publishAt,
        author: {
          ...ctt.author,
          avatar: getImage(ctt.author?.avatar)
        },
        thumb: getImage(ctt.thumb),
        cover: getImage(ctt.cover),
        status: ctt.status,
        equipments: ctt.equipments,
        summary: ctt.summary,
        weekDay: enumWeekDay(ctt.weekDay),
        weekDayEn: enumWeekDaySlug(ctt.weekDay)
      }))
    })) || []
  }
}

export const allTrainingToView = new AllTrainingToView()
