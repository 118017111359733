import React from 'react'
import Script from 'react-load-script'
import { AspectRatioBox, Box } from '@chakra-ui/core'
import { api } from '../../infra/http/remote'

interface SambaPlayerProps {
  reference: string
  contentId?: string
  view?: {
    progress: number
    watched: boolean
  }
  modalPlayer?: boolean
}

const SambaPlayer = ({ reference, contentId, view, modalPlayer }: SambaPlayerProps) => {
  const idPlayer = modalPlayer ? 'modalPlayer' : 'player'
  let flag = false

   const getSettingMediaKey = (): string => {
    const data = JSON.parse(localStorage.getItem('settings'))
    const value = data.filter(item => item.name === 'sambavideos_playerKey')
    return value.length ? value[0].value : ''
  }

  const clearPlayer = () => {
    const item = window.document.getElementById(idPlayer)
    if (item) {
      item.innerHTML = ''
    }
  }

  const dispatchActionView = async (currentProgress: number, duration: number): Promise<void> => {
    if (contentId && !flag && currentProgress > view.progress) {
      flag = true
      const percent = currentProgress / (duration / 100000)
      if (Math.round(percent) > 50) {
        await api.post('/user_collection_views', {
          content: contentId,
          percent: Math.round(percent) || 100,
          progress: currentProgress
        })
      }
    }
  }

  const eventListener = async (player): Promise<void> => {
    const eventParam = parseInt(player.eventParam, 10)

    switch (player.event) {
      case 'onListen':
        if (!flag && eventParam > 1 && eventParam % 15 === 0) {
          await dispatchActionView(eventParam, player.duration)
        } else if (flag && eventParam > 1 && eventParam % 16 === 0) {
          flag = false
        }
        break
      case 'onFinish':
        await dispatchActionView(player.duration / 1000, player.duration)
        break
      default: break
    }
  }

  const init = () => {
    const playerKey = getSettingMediaKey()
    const playerParams = {
      enableShare: false
    }

    if (view && view.progress && !view.watched) {
      Object.assign(playerParams, {
        resume: view.progress
      })
    }

    clearPlayer()
    new (window as typeof globalThis).SambaPlayer(idPlayer, {
      ph: playerKey,
      m: reference,
      playerParams,
      events: {
        '*': eventListener
      }
    })
  }

  return (
    <>
      <Script
        url="https://player.sambatech.com.br/v3/samba.player.api.js"
        onLoad={init}
      />
      <AspectRatioBox ratio={16 / 9}>
        <Box id={idPlayer} />
      </AspectRatioBox>
    </>
  )
}

export default SambaPlayer
