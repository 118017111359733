import { BlockResultModel, FolderModel } from '../../../domain/models'
import { PublicContentToViewInterface } from '../../../domain/usecases/public-content-to-view/public-content-to-view'
import { getImage } from '../../../utils/helpers/get-image'

export class PublicContentToView implements PublicContentToViewInterface {
  build ({ content }: BlockResultModel): FolderModel[] {
    return content['hydra:member'].map((item: any) => ({
      '@id': item['@id'],
      id: item.id,
      title: item.title,
      slug: item.slug,
      position: item.position,
      logo: getImage(item.logo),
      thumb: getImage(item.thumb),
      description: item.description,
      videoPreviewUrl: item.videoPreviewUrl,
      views: item.views,
      access: item.access,
      likes: item.likes,
      dislikes: item.dislikes,
      favorites: item.favorites,
      rating: item.rating,
      currentUserCollection: item.currentUserCollection
    })) || []
  }
}

export const publicContentToView = new PublicContentToView()
