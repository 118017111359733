import { PlanModel } from '../../../domain/models'
import { getImage } from '../../../utils/helpers/get-image'

export class PlanToView {
  build (content: any): PlanModel[] {
    return content['hydra:member'].map((item) => ({
      ...item,
      image: getImage(item.image)
    })) || []
  }
}

export const planToView = new PlanToView()
