import React from 'react'
import { Typography } from '../ui/foundation'
import { EquipmentModal } from '../../domain/models'
import { Carousel } from '../ui/layout'
import { AspectRatioBox, Box, Flex, Image } from '@chakra-ui/core'

interface EquipmentsProps {
  equipments: EquipmentModal[]
}

const Equipments = ({ equipments }: EquipmentsProps): JSX.Element => {
  return (
    <Box mb={8}>
      <Typography type="3xl" as="h3" color="purple.500">INDICAÇÕES DE APARELHO</Typography>

      <Carousel
        infinite={false}
        responsive={[
          {
            breakpoint: 9999,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]}>
        {equipments.map((equipment) => (
          <Box key={equipment['@id']}>
            <Flex alignItems="center">
              <Box w="100px" mr="10px">
                <AspectRatioBox ratio={16 / 9} borderRadius="md" overflow="hidden">
                  {equipment.image ? (
                    <Image
                      objectFit="contain"
                      src={equipment.image}
                      alt={equipment.name}
                      ignoreFallback
                    />
                  ) : <div/>}
                </AspectRatioBox>
              </Box>
              <Box>
                <Typography type="md" as="h4" mb={0} color="purple.500">{equipment.name}</Typography>
              </Box>
            </Flex>
          </Box>
        ))}
      </Carousel>

    </Box>
  )
}

export default Equipments
