import React from 'react'
import {
  AlertDialogContent,
  AlertDialogOverlay, Box
} from '@chakra-ui/core'
import {
  AlertBoxBlackContextInterface,
  useAlertBoxBlack
} from './alert-box-black-context'
import styled from '@emotion/styled'

const AlertBoxBlack: React.FC = () => {
  const { content, open, setContent, setOpen }: AlertBoxBlackContextInterface = useAlertBoxBlack()

  const onClose = (): void => {
    setOpen(false)
    setContent(null)
  }

  if (!open) {
    return null
  }

  return (
    <>
      <AlertDialogOverlay onClick={onClose} />
      <AlertDialogContent maxW={[420, 420, 500]} bg="#464b55" borderRadius="md" mt={8}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Box p={4} color="#ffffff">
          {content}
        </Box>
      </AlertDialogContent>
    </>
  )
}

export default AlertBoxBlack

const CloseButton = styled.div`
  position: absolute;
  top: -60px;
  right: 0;
  font-size: 80px;
  width: 55px;
  line-height: 0.5;
  height: 55px;
  cursor: pointer;
  color: #ffffff;
  &:hover {
    color: #676767; 
  }
`
