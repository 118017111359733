import React, { FormEvent, useState } from 'react'
import { FormControl, InputCustom, InputError } from './styles'
import { FormLabel } from '@chakra-ui/core'
import { Field, ErrorMessage, FieldAttributes } from 'formik'
import InputMask from 'react-input-mask'
import Payment from 'payment'

interface InputProps extends FieldAttributes<any>{
  title: string
  name: string
  placeholder?: string
}

const InputCreditCard = ({ name, title, placeholder, ...el }: InputProps) => {
  const [mask, setMask] = useState<string>('9999 9999 9999 9999')

  const changeMask = (value?: string): string => {
    if (!value) {
      return '9999 9999 9999 9999'
    }

    switch (Payment.fns.cardType(value)) {
      case 'amex':
        return '9999 999999 99999'
      case 'dinersclub':
        return '9999 999999 9999'
      default:
        return '9999 9999 9999 9999'
    }
  }

  return (
    <FormControl>
      <FormLabel htmlFor={name}>{title}</FormLabel>
      <Field name={name}>
        {({ field }) => (
          <InputMask
            {...field}
            placeholder={placeholder || ''}
            maskChar=""
            mask={mask}
            onChange={(e: FormEvent<HTMLInputElement>) => {
              const { value = '' } = e.currentTarget
              setMask(() => changeMask(value))
              if (el.setFieldValue) {
                el.setFieldValue(name, value)
              }
            }}
          >
            {(inputProps: any) => <InputCustom {...inputProps} />}
          </InputMask>
        )}
      </Field>
      <ErrorMessage name={name} component={InputError} />
    </FormControl>
  )
}

export default InputCreditCard
