import React from 'react'
import { AuthorModel } from '../../../../domain/models'
import { AspectRatioBox, Image } from '@chakra-ui/core'
import styled from '@emotion/styled/macro'
import { Typography } from '../../foundation'
import customTheme from '../../../../styles/theme'
import { convertHexToRgbA } from '../../../../utils/helpers/hex-to-rgba'
import { Link } from 'react-router-dom'

interface CardAuthorProps {
  author: AuthorModel
}

const CardAuthor: React.FC<CardAuthorProps> = ({ author }) => {
  return (
    <Link to={`/professor/${author.slug}`}>
      <BoxCustom>
        <AspectRatioBox ratio={1}>
          <Image
            objectFit="cover"
            src={author.avatar}
            alt={author.name}
            ignoreFallback
          />
        </AspectRatioBox>
        <MetaInfo>
          <Typography type="2xl" as="h3" mb={1} color="green.700" isTruncated>{author.name}</Typography>
          <Typography type="lg" as="h5" mb={1} color="white" isTruncated>{author.companyJobRole}</Typography>
          {author.description && <Description dangerouslySetInnerHTML={{ __html: author.description }} />}
        </MetaInfo>
      </BoxCustom>
    </Link>
  )
}

const MetaInfo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 18% 15% 15%;
  background: ${convertHexToRgbA(customTheme.colors.pink['200'], 0.7)};
  opacity: 0;
  transition: opacity .2s ease-in-out, padding-top .4s ease-in;
`

const Description = styled.div`
  color: ${customTheme.colors.white};
  --max-lines: 4;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;  
`

const BoxCustom = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;

  &:hover ${MetaInfo} {
    opacity: 1;
    padding-top: 15%;
  } 
`

export default CardAuthor
