import React, { useState } from 'react'
import styled from '@emotion/styled/macro'
import Cookie from 'js-cookie'

const PopUpItem = ({ content, closePopUp }) => {
  const [disabled, setDisabled] = useState<any>(false)

  const disablePopUp = () => {
    closePopUp()
    if (disabled) {
      const popUpList = Cookie.get('popUpList') ? JSON.parse(Cookie.get('popUpList')) : []
      if (!popUpList.includes(content.id)) {
        Cookie.set('popUpList', JSON.stringify([...popUpList, content.id]))
      }
      setDisabled(false)
    }
  }

  return (
    <PopUpBox>
      <PopUpBg />
      <PopUpContent style={{ width: `${content?.width || 700}px`, height: `${content?.height || 400}px` }}>
        <CloseButton onClick={disablePopUp}>x</CloseButton>
        <div dangerouslySetInnerHTML={{ __html: content?.content }} />
        <DisableBox>
          <input
            id='disabledInput'
            type='checkbox'
            checked={disabled}
            onChange={() => setDisabled(prev => !prev) }
          />
          <label htmlFor='disabledInput'> Desabilitar esse popup</label>
        </DisableBox>
      </PopUpContent>
    </PopUpBox>
  )
}

export default PopUpItem

const PopUpBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`

const PopUpBg = styled.div`
  background: rgb(0 0 0 / 70%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
`

const PopUpContent = styled.div`
  background: #fff;
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  padding: 10px;
  box-shadow: 3px 3px 6px #5e5e5e;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CloseButton = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  font-family: monospace;
  width: 30px;
  background: #ddd;
  line-height: 26.5px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  z-index: 99;
  display: block;
`

const DisableBox = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  
  input {
    margin-right: 5px;
  }
`
