/**
 * Converted TSX
 * @author Flavio Lacerda
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import slugify from 'slugify'
import { isEmpty } from '../../../utils/helpers'
import { useFormContext } from 'react-hook-form'
const OTHER_OPTION = '__other_option__'
const OTHER_OPTION_RESPONSE = 'other_option_response'
const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CheckboxContainer = styled.div`
  display: flex;

  & label {
    margin: 0 10px;
  }
`
const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`

const buildId = (id:string, value: string) => {
  return `${id}-${slugify(value)}`
}

export default function CheckboxInput ({ field }) {
  const { register, getValues, setError, getFieldState, clearErrors } = useFormContext()
  const options = [...field.options]
  let customOption
  for (let i = 0, j = options.length; i < j; i++) {
    if (options[i]) {
      options[i].id = field.id
      if (options[i].custom) {
        customOption = { ...options[i] } // clone
        delete customOption.custom
        delete options[i]
        i--
        j--
      } else {
        delete options[i].custom
      }
    }
  }

  const checkSelected = () => {
    const fieldValues = getValues()
    const myCheckboxes = fieldValues[field.id] || []
    let errorObj:any = {}

    if (myCheckboxes.length <= 0) {
      errorObj = { type: 'validate', message: 'Selecione ao menos uma opção' }
    } else if (myCheckboxes.length > 3) {
      errorObj = { type: 'validate', message: 'Selecione no máximo 3 opções' }
    } else if (myCheckboxes.includes(OTHER_OPTION) && isEmpty(fieldValues[buildId(field.id, OTHER_OPTION_RESPONSE)])) {
      errorObj = { type: 'validate', message: 'Você precisa inserir um valor no campo "Outro"' }
    } else {
      clearErrors(buildId(field.id, OTHER_OPTION_RESPONSE))
      clearErrors(field.id)
      return true
    }

    setError(buildId(field.id, OTHER_OPTION_RESPONSE), errorObj)
    setError(field.id, errorObj)
    return false
  }
  const error = getFieldState(field.id).error
  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    if (!isEmpty(error)) {
      if (!isEmpty(error.message)) { setErrorMessage(error.message) }
    } else { setErrorMessage('') }
  }, [error])

  return (
    <>
      <Container>
        {options.map((o) => (
          <CheckboxContainer key={buildId(o.id, o.label)}>
            <input type='checkbox' {...register(o.id, { validate: checkSelected })} value={o.label} />
            <label htmlFor={o.id}>{o.label}</label>
          </CheckboxContainer>
        ))}
        {customOption && (
          <CheckboxContainer key={customOption.id}>
            <input
              type='checkbox'
              {...register(customOption.id, { validate: checkSelected })} value={OTHER_OPTION}
            />
            <label htmlFor={customOption.id}>Outro</label>
            <input
              type='text'
              placeholder='_____________'
              {...register(buildId(field.id, OTHER_OPTION_RESPONSE), { validate: checkSelected })}
            />
          </CheckboxContainer>
        )}
      </Container>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  )
}
