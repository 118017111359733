/**
 * Converted TSX
 * @author Flavio Lacerda
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from '../../../utils/helpers'
import queryString from 'query-string'

const Container = styled.div`
  display: flex;
  align-items: center;

  & * {
    margin: 0 10px;
  }
  margin-bottom 10px;
`

const ErrorLabel = styled.span`
  color: red;
`

export default function LinearGrid ({ field }) {
  const legend = field.legend
  const options = field.options
  const query = queryString.parse(window.location.search)

  const { register, getFieldState, setValue } = useFormContext()

  const error = getFieldState(field.id).error
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isEmpty(error)) { setErrorMessage('error') } else setErrorMessage('')
  }, [error])

  useEffect(() => {
    if (query[field.id]) {
      setValue(field.id, query[field.id])
    }
  }, [])

  return (
    <>
      <Container>
        <div>{legend.labelFirst}</div>
        <div>
          {options.map((o) => {
            o.name = field.id
            return (
              <div key={o.label} style={{ width: 30, display: 'inline-block' }} >
                <p>{o.label}</p>
                <input key={o.label} type='radio' {...o} {...register(field.id, { required: field.required })} value={o.label} />
              </div>
            )
          })}
        </div>
        <div>{legend.labelLast}</div>
      </Container>
      <ErrorLabel>{errorMessage && 'Selecione uma opção'}</ErrorLabel>
    </>
  )
}
