import { api } from '../../../../infra/http/remote'

export type SendContactValuesProps = {
  name: string
  email: string
  subject: string
  message: string
}

export const sendContact = async (slug: string, values: SendContactValuesProps) => {
  try {
    await api.post(`/contact_messages/${slug}`, values)
    return true
  } catch (_e) {
    return false
  }
}
