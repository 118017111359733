import React, { useEffect, useState } from 'react'
import { PageResultModel } from '../domain/models'
import { makePage } from '../main/factories/usecases/page/page'
import Loading from '../component/loading/loading'
import LoadBlocks from '../component/load-blocks/load-blocks'

const Dashboard = () => {
  const [content, setContent] = useState<PageResultModel>(null)
  useEffect(() => {
    (async () => {
      try {
        const data = await makePage('dashboard')
        setContent(data)
      } catch (e) {
        throw new Error('Ocorreu um erro ao processar a página')
      }
    })()
  }, [])

  if (!content) {
    return <Loading />
  }

  return <LoadBlocks containers={content.containers} locale="main" />
}

export default Dashboard
