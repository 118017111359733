import { FaqToViewInterface } from '../../../domain/usecases/faq-to-view/faq-to-view'
import { FaqModel } from '../../../domain/models'

export class FaqToView implements FaqToViewInterface {
  build (content: any): FaqModel[] {
    return content['hydra:member'].map((faq: any) => ({
      '@id': faq['@id'],
      id: faq.id,
      answer: faq.answer,
      question: faq.question
    })) || []
  }
}

export const faqToView = new FaqToView()
