import { BannerToViewInterface } from '../../../domain/usecases/banner-to-view/banner-to-view'
import { BannerImagesModel, BannerModel, BlockResultModel } from '../../../domain/models'
import { getImage } from '../../../utils/helpers/get-image'

export class BannerToView implements BannerToViewInterface {
  build ({ content }: BlockResultModel, type: string): BannerModel|null {
    if (type) {
      return {
        '@id': content['@id'],
        id: content.id,
        description: content.description,
        alternateText: content.alternateText,
        title: content.title,
        images: this.getImages(content.images)
      }
    }
    return null
  }

  getImages (images: any): BannerImagesModel[] {
    return images.map((image) => ({
      title: image.title,
      description: image.description,
      link: image.link,
      action: image.action,
      textButton: image.textButton,
      image: getImage(image.image),
      imageMobile: getImage(image.image)
    }))
  }
}
export const bannerToView = new BannerToView()
