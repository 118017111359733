import {
  ProfileToViewInterface
} from '../../../domain/usecases/profile-to-view/profile-to-view'
import { ProfileModel } from '../../../domain/models'
import { getImage } from '../../../utils/helpers/get-image'

export class ProfileToView implements ProfileToViewInterface {
  build ({ me, profile }: any): ProfileModel {
    return {
      user: {
        '@id': me.user['@id'],
        id: me.user.id,
        name: me.user.name,
        email: me.user.email,
        avatar: getImage(me.user.avatar),
        answeredAnamnesisForms: me.user.answeredAnamnesisForms
      },
      info: {
        answeredAllActiveForms: me.answeredAllActiveForms,
        hasCustomerFolders: me.hasCustomerFolders,
        hasSubscription: me.hasSubscription,
        hasActiveSubscription: me.hasActiveSubscription,
        hasTrialSubscription: me.hasTrialSubscription
      },
      profile: {
        identity: profile.identity,
        zipCode: profile.zipCode,
        street: profile.street,
        houseNumber: profile.houseNumber,
        complement: profile.complement,
        neighborhood: profile.neighborhood,
        city: profile.city,
        state: profile.state,
        country: profile.country,
        notes: profile.notes,
        phoneReference: profile.phoneReference,
        phoneType: profile.phoneType,
        phoneNumber: profile.phoneNumber,
        website: profile.website,
        company: profile.company,
        companyIdentity: profile.companyIdentity,
        companyJobRole: profile.companyJobRole,
        avatar: profile.avatar,
        foreigner: profile.foreigner,
        foreignAddress: profile.foreignAddress
      }
    }
  }
}

export const profileToView = new ProfileToView()
