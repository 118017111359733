const getIDYoutube = (url: string): string|null => {
  const regex = '(?:youtube(?:-nocookie)?\\.com\\/(?:[^\\/\\n\\s]+\\/\\S+\\/|(?:v|e(?:mbed)?)\\/|\\S*?[?&]v=)|youtu\\.be\\/)([a-zA-Z0-9_-]{11})'
  const id = url.match(regex)
  return id?.length ? `https://www.youtube.com/embed/${id[1]}` : null
}

const getIDVimeo = (url: string): string|null => {
  const regex = '/(?:www\\.|player\\.)?vimeo.com\\/(?:channels\\/(?:\\w+\\/)?|groups\\/(?:[^\\/]*)\\/videos\\/|album\\/(?:\\d+)\\/video\\/|video\\/|)(\\d+)(?:[a-zA-Z0-9_\\-]+)?'
  const id = url.match(regex)
  return id?.length ? `https://player.vimeo.com/video/${id[1]}` : null
}

const getIDSambaPlayer = (url: string): string|null => {
  return url
}

export const getVideoUrlEmbed = (url: string): string|null => {
  if (/yout/.test(url)) {
    return getIDYoutube(url)
  }
  if (/vimeo/.test(url)) {
    return getIDVimeo(url)
  }
  if (/sambaplayer/.test(url)) {
    return getIDSambaPlayer(url)
  }
  return ''
}
