import React from 'react'
import { priceFormat } from '../../utils/helpers/price'
import styled from '@emotion/styled'

interface CouponDetailsProps {
  couponDetail: any
}

const CouponDetails = ({ couponDetail }: CouponDetailsProps): JSX.Element => {
  const Benefit = ({ benefit, idx }: any): JSX.Element => {
    switch (idx) {
      case 'amount':
        return <li>Desconto de {priceFormat(benefit)}</li>
      case 'trial':
        return <li>{benefit} dias grátis.</li>
      case 'plans':
        return benefit.map((plan, idx) => (
          <li key={`plan-${idx}`}>Acesso ao plano: {plan}</li>
        ))
      case 'percentage':
        return <li>Desconto de {parseInt(benefit, 10)}%</li>
      default:
        return null
    }
  }

  return (
    <BoxCoupon>
      <p>Cupom ativado com sucesso</p>
      <p><b>Benefícios</b></p>
      <ul>
        {Object.keys(couponDetail.benefits).map(key => (
          <Benefit benefit={couponDetail.benefits[key]} key={key} idx={key} />
        ))}
      </ul>
    </BoxCoupon>
  )
}

export default CouponDetails

const BoxCoupon = styled.div`
  font-size: 13px;
  background: #fffff0;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  
  ul {
    padding-left: 10px;
    list-style: none;
  }
`
