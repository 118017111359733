import { addLocalStorage, getLocalStorage } from '../../../infra/storage/localStorage'
import { MenuModel } from '../../../domain/models/menu'

export const getSocialNetworkMenu = (): MenuModel => {
  const user = getLocalStorage('socialMenu')
  return JSON.parse(user)
}

export const setSocialNetworkMenu = async (settings): Promise<void> => {
  await addLocalStorage('socialMenu', JSON.stringify(settings))
}

export const getLoggedMenu = (): MenuModel => {
  const user = getLocalStorage('loggedMenu')
  return JSON.parse(user)
}

export const setLoggedMenu = async (settings): Promise<void> => {
  await addLocalStorage('loggedMenu', JSON.stringify(settings))
}
