import React from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { ContentModel } from '../../domain/models'
import { Typography } from '../ui/foundation'
import { AuthorTag, CardHelpContentItem } from '../ui/layout'
import SambaPlayer from '../samba-player/samba-player'
import Ratings from '../ratings/ratings'
import Equipments from '../equipments/equipments'
import styled from '@emotion/styled/macro'
import { Comments } from '../comments'
import CommentProvider from '../comments/contexts/comment-provider'
import VimeoVideo from '../vimeo-player/vimeo-player'

interface ContentViewProps {
  content: ContentModel
  removeTitle?: boolean
}

const ContentView = ({ content, removeTitle }: ContentViewProps): JSX.Element => {
  return (
    <Box py={6}>
      {!removeTitle && (
        <Typography type="lg" as="h2" color="purple.500">{content.title.toUpperCase()}</Typography>
      )}

      <Typography type="lg" as="p">{content.subtitle}</Typography>

      <AuthorTag author={content.author} publishedAt={content.publishAt}/>

      <Flex my={10} mx="-10px" flexWrap="wrap">
        <Box w={['100%', '100%', '66.66666%']} px="10px">
          <Box borderRadius="sm" overflow={'hidden'}>
            {content.contentReference && <SambaPlayer
              reference={content.contentReference}
              view={content.currentUserCollection.view}
              contentId={content['@id']}
            />}

            {content.vimeoReference && <VimeoVideo
              reference={content.vimeoReference}
              view={content.currentUserCollection.view}
              contentId={content['@id']}
            />}

            {!content.contentReference && !content.vimeoReference && <p>Nenhum vídeo cadastrado</p>}
          </Box>

          <Ratings contentId={content['@id']} rating={content.currentUserCollection.rating} />
        </Box>
        <Box w={['100%', '100%', '33.33333%']} px="10px">
          <Typography type="3xl" as="h3" color="purple.500">VIDEOS EXTRAS</Typography>

          <Box>
            {content.helpContents.map(helpContent => (
              <CardHelpContentItem key={helpContent['@id']} content={helpContent}/>
            ))}
          </Box>

        </Box>
      </Flex>

      <CustomBox maxW={['100%', '100%', '66.66666%']}>
        <Equipments equipments={content.equipments} />

        <Typography type="3xl" as="h3" color="purple.500" mt={8}>DESCRIÇÃO</Typography>
        {content.summary && <Typography type="md" dangerouslySetInnerHTML={{ __html: content.summary }}/>}

        <CommentProvider>
          <Comments contentUri={content['@id']} />
        </CommentProvider>
      </CustomBox>

    </Box>
  )
}

export default ContentView

const CustomBox = styled(Box)`
  .slick-slider {
    margin-left: 20px;  
    margin-right: 20px;  
  }
`
