export const enumWeekDay = (weekday: string): string => {
  switch (weekday.toLowerCase()) {
    case 'monday' :
      return 'Segunda'
    case 'tuesday' :
      return 'Terça'
    case 'wednesday' :
      return 'Quarta'
    case 'thursday' :
      return 'Quinta'
    case 'friday' :
      return 'Sexta'
    case 'saturday' :
      return 'Sábado'
    case 'sunday' :
    default:
      return 'Domingo'
  }
}

export const enumWeekDaySlug = (weekday: string): string => {
  switch (weekday.toLowerCase()) {
    case 'monday' :
      return 'segunda'
    case 'tuesday' :
      return 'terca'
    case 'wednesday' :
      return 'quarta'
    case 'thursday' :
      return 'quinta'
    case 'friday' :
      return 'sexta'
    case 'saturday' :
      return 'sabado'
    case 'sunday' :
    default:
      return 'domingo'
  }
}

// 0 = Monday, 1 = Tuesday, 2 = Wednesday, 3 = Thursday, 4 = Friday, 5 = Saturday, 6 = Sunday
export const enumDayToNumber = (weekday: string): number => {
  switch (weekday.toLowerCase()) {
    case 'segunda' :
      return 0
    case 'terca' :
      return 1
    case 'quarta' :
      return 2
    case 'quinta' :
      return 3
    case 'sexta' :
      return 4
    case 'sabado' :
      return 5
    case 'sunday' :
    default:
      return 6
  }
}

export const enumDateDayToWeekDay = (day: number): string => {
  switch (day) {
    case 1 :
      return 'monday'
    case 2 :
      return 'tuesday'
    case 3 :
      return 'wednesday'
    case 4 :
      return 'thursday'
    case 5 :
      return 'friday'
    case 6 :
      return 'saturday'
    case 0 :
    default:
      return 'sunday'
  }
}
