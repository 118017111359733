import { AuthorToViewInterface } from '../../../domain/usecases/author-to-view/author-to-view'
import { AuthorModel } from '../../../domain/models'
import { getImage } from '../../../utils/helpers/get-image'

export class AuthorToView implements AuthorToViewInterface {
  build (content: any): AuthorModel[] {
    return content['hydra:member'].map((item) => ({
      ...item,
      avatar: getImage(item.avatar)
    })) || []
  }
}

export const authorToView = new AuthorToView()
