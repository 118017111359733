import { ContentModel } from '../../../domain/models'
import { ContentToViewInterface } from '../../../domain/usecases/contents-to-view/content-to-view'
import { getImage } from '../../../utils/helpers/get-image'
import { enumWeekDay } from '../../../utils/enums/week-day'
import { toDateBr } from '../../../utils/helpers/date'

export class ContentToView implements ContentToViewInterface {
  build (content: any): ContentModel[] {
    return content.map((item: any) => ({
      '@id': item['@id'],
      id: item.id,
      title: item.title,
      summary: item.summary,
      position: item.position,
      publishAt: toDateBr(item.publishAt),
      unpublishAt: item.unpublishAt,
      featured: item.featured,
      preview: item.preview,
      videoPreviewUrl: item.videoPreviewUrl,
      folder: item.folder,
      author: {
        ...item.author,
        avatar: getImage(item.author.avatar)
      },
      contentReference: item.contentReference,
      vimeoReference: item.vimeoReference,
      thumb: getImage(item.thumb),
      slug: item.slug,
      duration: item.duration,
      views: item.views,
      access: item.access,
      likes: item.likes,
      dislikes: item.dislikes,
      favorites: item.favorites,
      rating: item.rating,
      currentUserCollection: {
        ...item.currentUserCollection
      },
      level: item.level,
      weekDay: enumWeekDay(item.weekDay),
      subtitle: item.subtitle,
      equipments: item.equipments.map((equipment: any) => ({
        ...equipment,
        image: getImage(equipment.image)
      })),
      helpContents: item.helpContents.map((helpContent: any) => ({
        ...helpContent,
        thumb: getImage(helpContent.thumb)
      }))
    })) || []
  }
}

export const contentToView = new ContentToView()
