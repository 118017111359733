import React from 'react'
import { Amplify, I18n } from 'aws-amplify'
import awsExports from '../../aws-exports'
import { dictionary } from '../../dictionary/dictionary'
import { AlertBoxProvider } from '../ui/layout/alert-box/alert-box-context'
import { AlertBoxBlackProvider } from '../ui/layout/alert-box-blank/alert-box-black-context'
import { AlertBox, AlertBoxBlack, Modal, ModalProvider } from '../ui/layout'

import ThemeContainer from '../../contexts/theme/theme-container'
import Route from '../routes/routes'

import '../../assets/fonts/stars/css/styles.css'
import 'slick-carousel/slick/slick.css'

Amplify.configure(awsExports)
I18n.setLanguage('pt_BR')
I18n.putVocabularies(dictionary)

const App = (): JSX.Element => {
  return (
    <ThemeContainer>
      <AlertBoxBlackProvider>
        <AlertBoxProvider>
          <ModalProvider>
            <Route />
            <Modal />
            <AlertBox />
            <AlertBoxBlack />
          </ModalProvider>
        </AlertBoxProvider>
      </AlertBoxBlackProvider>
    </ThemeContainer>
  )
}

export default App
