const ComponentsList: any = {
  BannerPrincipal: require('../blocks/slider/slider').default,
  About: require('../blocks/about/about').default,
  FeatureGroupServices: require('../blocks/feature-group-services/feature-group-services').default,
  SliderActivities: require('../blocks/slider-activities/slider-activities').default,
  Lettering: require('../blocks/lettering/lettering').default,
  BlockPlans: require('../blocks/plans/plans').default,
  BlockPlansFeature: require('../blocks/plans/plans-features').default,
  TestimonialGroup: require('../blocks/testimonial-groups/testimonial-groups').default,
  Partners: require('../blocks/partners/partners').default,
  Faq: require('../blocks/faq/faq').default,
  Contact: require('../blocks/contact/contact').default,
  Dashboard: require('../blocks/dashboards/dashboards').default,
  NextTraining: require('../blocks/next-training/next-training').default,
  News: require('../blocks/news/news').default,
  Recommended: require('../blocks/recommended/recommended').default,
  Services: require('../blocks/services/services').default,
  TryItFree: require('../blocks/try-it-free/try-it-free').default
}

export default ComponentsList
