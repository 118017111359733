/**
 * Converted TSX
 * @author Flavio Lacerda
 */

import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from '@emotion/styled'
import customTheme from '../../../styles/theme'

const TextArea = styled.textarea`
  border: 1px solid ${customTheme.colors.gray['300']};
  width: 90%;
  max-width: 100%;
  height: 80px;
  padding: 5px;
  border-radius:8px;

  @media (max-width: ${parseInt(customTheme.breakpoints[2].replace('px', ''), 10) - 1}px) {
    width: 80%;
  }
`

export default function LongAnswerInput ({ field }) {
  const { register } = useFormContext()

  return (
    <div>
      <TextArea {...field} {...register(field.id)} />
    </div>
  )
}
