import React, { createContext, useContext } from 'react'

export interface AlertBoxContextInterface {
  open: boolean
  setOpen: any
  content: any
  setContent: any,
  alertBox: any,
  action: any
  setAction: any,
}

const AlertBoxContext = createContext<AlertBoxContextInterface|null>(null)

export const AlertBoxProvider:React.FC = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [content, setContent] = React.useState<any>('')
  const [action, setAction] = React.useState<any>(null)
  const alertBox = (content: any, action?:any): void => {
    setOpen(true)
    setContent(content)
    setAction(action || null)
  }
  return (
    <AlertBoxContext.Provider value={{
      open,
      setOpen,
      content,
      setContent,
      alertBox,
      action,
      setAction
    }}>
      {children}
    </AlertBoxContext.Provider>
  )
}

export const useAlertBox = ():AlertBoxContextInterface => {
  const context = useContext(AlertBoxContext)

  if (!context) { throw new Error('useModal must be used within a provider ModalProvider') }

  return {
    ...context
  }
}
