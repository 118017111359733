import { CommentModel } from '../../../../domain/models'
import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'
import { getImage } from '../../../../utils/helpers/get-image'

export const makeComments = async (contentId: number): Promise<CommentModel[]> => {
  try {
    const response: AxiosResponse = await api.get(`/contents/${contentId}/comments?order[updatedAt]=asc`)
    const data = response.data['hydra:member'] || []
    if (data.length) {
      return response.data['hydra:member'].map((item) => ({
        '@id': item['@id'],
        id: item.id,
        user: {
          '@id': item.user['@id'],
          id: item.user.id,
          name: item.user.name,
          email: item.user.email,
          avatar: getImage(item.user.avatar),
          answeredAnamnesisForms: item.user.answeredAnamnesisForms.map((item) => ({
            ...item
          }))
        },
        text: item.text,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        content: item.content,
        childrenCount: item.childrenCount,
        parentId: item.parentId,
        children: item.children
      }))
    }

    return []
  } catch (error) {
    return []
  }
}
