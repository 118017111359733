import React, { useEffect, useState } from 'react'
import { Container, Typography } from '../component/ui/foundation'
import { AspectRatioBox, Box, Grid } from '@chakra-ui/core'
import Loading from '../component/loading/loading'
import { makeLive } from '../main/factories/usecases/live/make-live'
import { LiveModel } from '../domain/models'
import { enumDateDayToWeekDay, enumWeekDay } from '../utils/enums/week-day'
import styled from '@emotion/styled'
import { initWebex } from '../utils/helpers/webex'
import customTheme from '../styles/theme'

const Live = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<LiveModel>(null)
  const widgetWebexId = 'my-webexteams-widget'
  const weekday = enumDateDayToWeekDay(new Date().getDay())

  useEffect(() => {
    (async () => {
      try {
        const content = await makeLive()
        setData(content)
        setLoading(false)
        if (content.spaceId && content.token && content.status === 'inProgress') {
          initWebex(widgetWebexId, {
            guestToken: content.token,
            destinationId: content.sipAddress,
            spaceId: content.spaceId
          })
        }
      } catch (_e) {
        setData(null)
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Box py={16}>
      <Container>
        <Typography type="4xl" as="h1" textAlign="center">LIVE</Typography>
        {data ? (
          <Box w={['100%', '100%', '80%']} mx="auto">
            <Grid templateColumns={['repeat(2, 1fr)', '', 'repeat(5, 1fr)']} gap={5} my={10}>
              {data.schedules.map((schedule, idx) => (
                <Schedule key={`schedule-${idx}`} className={schedule.day.toLowerCase() === weekday ? 'active' : ''}>
                  <Typography type="xl" as="h3" mb={4}>{enumWeekDay(schedule.day).toUpperCase()}</Typography>
                  {schedule.scheduleItems.map((item, index) => (
                    <Box
                      key={`scheduleitems-${idx}-${index}`}
                      borderBottom="1px solid #ddd"
                      py={2}
                    >
                      <Typography type="xs">{item.description} - {item.time}</Typography>
                    </Box>
                  ))}
                </Schedule>
              ))}
            </Grid>

            {data.status === 'inProgress' ? (
              <AspectRatioBox ratio={16 / 9} mb={8}>
                <div id={widgetWebexId} />
              </AspectRatioBox>
            ) : (
              <Typography type="xl" as="h2" mb={4} color="red.700">Nenhuma live disponível no momento</Typography>
            )}

            <Typography type="4xl" as="h2">{data.title}</Typography>
            {data.description && (
              <Typography type="md" dangerouslySetInnerHTML={{ __html: data.description }} />
            )}
          </Box>
        ) : (
          <Box textAlign="center">Seu plano não tem acesso as lives disponíveis</Box>
        )}
      </Container>
    </Box>
  )
}

export default Live

const Schedule = styled.div`
  background: #f7f7f7;
  padding: 20px;
  border-radius: 15px;
  transition: margin-top .3s ease;
  &.active{
    border: 1px solid ${customTheme.colors.green['700']};      
  }
  &:hover {
    margin-top: -5px;
  }
`
