import axios from 'axios'

type ValuesProps = {
  holder_name: string
  card_expiration: string
  card_number: string
  card_cvv: string
  payment_method_code: string
  payment_company_code: string
}
export const vindiPayment = async (url: string, values: ValuesProps, token) => {
  return axios.post(`${url}public/payment_profiles`, values, {
    headers: {
      Authorization: `Basic ${btoa(token)}`
    }
  })
}

const translate = (str) => {
  switch (str) {
    case 'holder_name': return 'Nome igual ao cartão'
    case 'card_number': return 'Número do cartão'
    case 'card_cvv': return 'CVV'
    case 'card_expiration': return 'Data de expiração do cartão'
    case 'payment_company_id': return 'Banco'
    case 'payment_company_code': return 'Código da bandeira'
    default: return ''
  }
}

export const vindiErrors = (error): string => {
  const { status, data } = error.response

  switch (status) {
    case 401:
      return 'Requisição não autorizada'
    case 422:
      if (data.errors && data.errors.length) {
        let message = ''
        data.errors.forEach(e => {
          message += `<p>${translate(e.parameter)}: ${e.message}</p>`
        })
        return message
      }
      return 'Erro ao cadastrar perfil de pagamento'
    default:
      return 'Erro ao cadastrar perfil de pagamento'
  }
}
