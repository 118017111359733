import React, { useEffect, useState } from 'react'
import { ButtonRedGreen, Container } from '../../component/ui/foundation'
import { Box, Flex, Grid } from '@chakra-ui/core'
import NavProfile from '../../component/nav-profile/nav-profile'
import { useAlertBox } from '../../component/ui/layout/alert-box/alert-box-context'
import {
  removeUserSession,
  removeUserSessions,
  userSessions
} from '../../main/factories/usecases/user/user'
import { UserSessionsModel } from '../../domain/models'
import { toDateBr } from '../../utils/helpers/date'
import styled from '@emotion/styled'

const UserSessions = (): JSX.Element => {
  const { alertBox } = useAlertBox()
  const [sessions, setSessions] = useState<UserSessionsModel[]>([])

  useEffect(() => {
    (async () => {
      setSessions(await userSessions())
    })()
  }, [])

  const handleClearSession = async (id) => {
    try {
      await removeUserSession(id)
      setSessions(prev => prev.filter(item => item.id !== id))
      alertBox('Sessão removida')
    } catch (_e) {
      alertBox('Ocorreu um erro ao finalizar sessão')
    }
  }
  const handleClearSessions = async () => {
    try {
      await removeUserSessions()
      setSessions([])
      alertBox('Todas as sessões foram removidas.')
    } catch (_e) {
      alertBox('Ocorreu um erro ao finalizar sessões.')
    }
  }

  return (
    <Box py={8}>
      <Container>
        <Grid templateColumns={['1fr', '1fr', '1fr 4fr']} gap={5} my={10}>
          <Box>
            <NavProfile urlActive={'/sessoes'} />
          </Box>
          <Box>
            <Flex flexWrap="wrap" mx={-4}>
              {sessions.map(session => (
                <Box key={session['@id']} w={['100%', '50%', '33%']} mb={4} px={4}>
                  <BoxItem>
                    <p><b>IP:</b> {session.ip}</p>
                    <p><b>Origem:</b> {session.userAgentByWhichBrowser}</p>
                    <p><b>Acesso:</b> {toDateBr(session.accessedAt)}</p>
                    <ButtonRedGreen h={8} fontSize="sm" onClick={() => handleClearSession(session.id)}>Apagar sessão</ButtonRedGreen>
                  </BoxItem>
                </Box>
              ))}
            </Flex>
            <ButtonRedGreen onClick={handleClearSessions}>Apagar todas sessões</ButtonRedGreen>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default UserSessions

const BoxItem = styled.div`
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 4px;
  height: 100%;
  max-height: 140px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  p {
    font-size: 14px;
    margin-bottom: 2px;
  }
`
