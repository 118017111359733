import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { settings } from '../../config/settings'
import { initWebex } from '../../utils/helpers/webex'
import { Box } from '@chakra-ui/core'

const MobileLive = ({ match }):JSX.Element => {
  const { spaceId, token } = match.params
  const widgetWebexId = 'my-webexteams-widget'
  const [error, setError] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${settings.API_URL}/current_customer_webex_tokens`, {
          headers: {
            Authorization: token
          }
        })
        const data = response.data['hydra:member'] || []
        const response2 = await axios.get(`${settings.API_URL}/lives`, {
          headers: {
            Authorization: token
          }
        })
        const data2 = response2.data['hydra:member'] || []
        setStatus(data2[0].status)
        if (data.length && spaceId && data[0].token) {
          initWebex(widgetWebexId, {
            guestToken: data[0].token,
            destinationId: data2[0].sipAddress,
            spaceId
          })
        } else {
          setError(true)
        }
      } catch (e) {
        setError(true)
      }
    })()
  }, [token, spaceId, widgetWebexId])

  if (error) {
    return (
      <>Live não encontrada</>
    )
  }

  if (status && status !== 'inProgress') {
    return (
      <>Nenhuma live disponível no momento</>
    )
  }

  return (
    <Box pos="relative" overflow="hidden" w="100%" h="100vh">
      <Box pos="absolute" left={0} right={0} bottom={0} top={0} w="100%" id={widgetWebexId} />
    </Box>
  )
}

export default MobileLive
