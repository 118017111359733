import React from 'react'
import { GuardedRoute, GuardedRouteProps } from 'react-router-guards'
import { BaseLayout } from '../layouts'

interface AppRoute extends GuardedRouteProps {
  component: any
  layout?: any
}

export const AppRoute:React.FC<AppRoute> = ({ component: Component, layout: Layout = BaseLayout, ...rest }) => (
  <GuardedRoute {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)
