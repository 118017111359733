import React, { useEffect, useState } from 'react'
import { FolderContentModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { Box } from '@chakra-ui/core'
import { Container, Typography } from '../../component/ui/foundation'
import { RefreshContentProvider } from '../../component/favorites/favorite-context'
import FolderContent from '../../component/folder-content/folder-content'
import customTheme from '../../styles/theme'
import styled from '@emotion/styled/macro'
import { makeFavoriteCurrentCustomerFolderContents } from '../../main/factories/usecases/current-customer/favorite-folder-contents'

import bg0 from '../../assets/images/bgs/bg-0.jpg'
import bg1 from '../../assets/images/bgs/bg-1.jpg'
import bg2 from '../../assets/images/bgs/bg-2.jpg'

const FavoriteTraining = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [data, setData] = useState<FolderContentModel[]>(null)

  const handleRefresh = () => {
    setRefresh(prev => !prev)
  }

  useEffect(() => {
    (async () => {
      try {
        const content = await makeFavoriteCurrentCustomerFolderContents()
        setData(content)
        setLoading(false)
      } catch (_e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [refresh])

  if (loading) {
    return <Loading/>
  }

  return (
    <>
      <Box py={16} background={`url(${bg0}) no-repeat top center`}>
        <Container>
          <Typography type="4xl" as="h1" textAlign="center" textTransform="uppercase">Treinos favoritados</Typography>
        </Container>
      </Box>

      <RefreshContentProvider handleRefresh={handleRefresh}>
        <BoxFolderItems>
          {data && data.length > 0 ? data?.map((item) => (
            <FolderContent item={item} key={item['@id']} />
          )) : (
            <Box py={16}>
              <Container>
                <Typography type="lg" as="p">Nenhum treino foi favoritado ainda</Typography>
              </Container>
            </Box>
          )}
        </BoxFolderItems>
      </RefreshContentProvider>
    </>
  )
}

export default FavoriteTraining

const BoxFolderItems = styled.div`
  > div {
    padding: 45px 0;
    @media (min-width: ${customTheme.breakpoints[2]}) {
      padding: 90px 0;
    }
    background: ${`url(${bg0}) no-repeat top center`};
    background-size: cover;
    &:nth-of-type(odd) {
      @media (min-width: ${customTheme.breakpoints[2]}) {
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
        margin: -90px 0;
      }    
    }
    &:nth-of-type(even) {
      background-image: ${`url(${bg2})`};
    }
    &:nth-of-type(5) {
      background-image: none;
      background-color: ${customTheme.colors.gray['700']};
      h2 {
        color: #ffffff;
        + div > p {
          color: #ffffff;
        }
      }
    }
    &:first-of-type {
      background-image: ${`url(${bg1})`};
    }
    &:last-of-type:nth-of-type(odd) {
      @media (min-width: ${customTheme.breakpoints[2]}) {
        margin-bottom: 0;
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
`
