import React from 'react'
import { CardPlanItem } from '../../ui/layout'
import { BlockResultModel, PlanModel } from '../../../domain/models'
import { Box, Flex } from '@chakra-ui/core'
import { ButtonRedGreen, Container, Typography } from '../../ui/foundation'
import { goToUrl } from '../../../utils/helpers/go-to-url'
import { useHistory } from 'react-router'
import { planToView } from '../../../data/usecases/plan-to-view/plan-to-view'

const BlockPlans = (props: BlockResultModel): JSX.Element|null => {
  const history = useHistory()
  const { block } = props
  const content: PlanModel[] = planToView.build(props.content)

  if (!content) {
    return null
  }

  return (
    <Box id={`block-${block.id}`} py={[16, 24, 32]}>
      <Container>
        <Box textAlign="center">
          <Typography type="4xl" as="h2" mb={4}>{block.title}</Typography>
          <Typography
            type="sm"
            as={'div'}
            mb={8}
            dangerouslySetInnerHTML={{ __html: block.description }}/>
        </Box>

        <Flex flexWrap="wrap" mb={16} mx={-2} justifyContent="center">
          {content.map((planItem, index) => (
            <Box key={planItem['@id']} w={['100%', '50%', '33%', '25%']} px={2} mb={4}>
              <CardPlanItem dark={index === 0} data={{
                description: planItem.description,
                title: planItem.name,
                image: planItem.image,
                selectable: planItem.selectable,
                totalPrice: planItem.totalPrice,
                url: '/cadastre-se',
                intervalCount: planItem.intervalCount
              }} />
            </Box>
          ))}
        </Flex>

        <Box textAlign="center">
          <ButtonRedGreen onClick={() => goToUrl(history, '/cadastre-se')}>Assine agora!</ButtonRedGreen>
        </Box>

      </Container>
    </Box>
  )
}

export default BlockPlans
