import React, { useEffect, useState } from 'react'
import { AspectRatioBox, Box, Flex, Grid, Image } from '@chakra-ui/core'
import { Container, Typography } from '../../component/ui/foundation'
import styled from '@emotion/styled/macro'
import customTheme from '../../styles/theme'
import { AuthorModel } from '../../domain/models'
import { makeTeachersList } from '../../main/factories/usecases/teachers/teachers-list'
import Loading from '../../component/loading/loading'
import { Redirect } from 'react-router'
import { CardAuthor } from '../../component/ui/layout'
import { getVideoUrlEmbed } from '../../utils/helpers/get-video-url-embed'

import bg from '../../assets/images/bgs/bg-0.jpg'

const ViewTeacher = ({ match }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const { slug } = match.params
  const [data, setData] = useState<AuthorModel>(null)
  const [others, setOthers] = useState<AuthorModel[]>(null)

  useEffect(() => {
    (async () => {
      try {
        const content = await makeTeachersList({
          slug,
          perPage: 5
        })
        if (content.length) {
          setData(content[0])
          setOthers(content.filter((_item, index) => index !== 0))
        } else {
          setData(null)
        }
        setLoading(false)
      } catch (_e) {
        setData(null)
        setLoading(false)
      }
    })()
  }, [slug])

  if (loading) {
    return <Loading/>
  }

  if (!data) {
    return <Redirect to="/404" />
  }

  const InnerAspect = () => {
    if (data.videoUrl) {
      return <Iframe src={getVideoUrlEmbed(data.videoUrl)}/>
    } else if (data.avatar) {
      return <Image
        src={data.avatar}
        alt={data.name}
        objectFit="cover"
        pos="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
      />
    }
    return <div />
  }

  return (
    <Box>
      <Box pt="90px" pb="120px" bg="gray.700">
        <Container>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%']} pos="relative" zIndex={2}>
              <AspectRatioBox ratio={16 / 9}>
                <InnerAspect />
              </AspectRatioBox>
            </Box>
            <CustomBox w={['100%', '100%', '50%']} p="8">
              <Typography type="2xl" as="h1" mb={4} isTruncated>{data.name.toUpperCase()}</Typography>
              {data.companyJobRole && (
                <Typography type="sm" as="p" mb={8} isTruncated>{data.companyJobRole.toUpperCase()}</Typography>
              )}
              {data.description && (
                <Typography dangerouslySetInnerHTML={{ __html: data.description }} />
              )}
            </CustomBox>
          </Flex>
        </Container>
      </Box>

      <BoxOthers>
        <Container>
          <Typography type="4xl" as="h2" textAlign="center" textTransform="uppercase">
            Outros Professores
          </Typography>
          {others && (
            <Grid templateColumns={['repeat(2, 1fr)', '', 'repeat(4, 1fr)']} gap={5} my={10}>
              {others.map((author) => (
                <CardAuthor author={author} key={author['@id']} />
              ))}
            </Grid>
          )}
        </Container>
      </BoxOthers>

    </Box>
  )
}

export default ViewTeacher

const CustomBox = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: #fff;
  @media (min-width: ${customTheme.breakpoints[1]}) {
    &:before {
      content: "";
      background: #fff;
      border-radius: 4px;
      top: -2rem;
      left: -2rem;
      bottom: -2rem;
      right: 0;
      position: absolute;
      z-index: -1;
    }  
  }
`

const BoxOthers = styled.div`
  background: ${`url(${bg}) no-repeat top center`};
  background-size: cover;
  padding: 90px 0;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
  margin: -90px 0 0;
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
`
