import React, { useState } from 'react'
import { Box, Flex, Image, PseudoBox } from '@chakra-ui/core'
import { Typography } from '../../ui/foundation'
import styled from '@emotion/styled/macro'
import SambaPlayer from '../../samba-player/samba-player'

const TestimonialItem = ({ index, testimonial }): JSX.Element|null => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <Flex
        w="100%"
        mb={[12, 24]}
        alignItems="center"
        flexDir={index % 2 === 0 ? 'row' : 'row-reverse'}
        justifyContent="space-between"
      >
        <Box w={['60%', '45%']}>
          <PseudoBox
            transition="transform .3s ease"
            _hover={{
              transform: 'scale(1.05)'
            }}
          >
            {testimonial.description && (
              <Typography
                type="2xl"
                fontSize={['md', '2xl']}
                as="div"
                fontWeight="bold"
                dangerouslySetInnerHTML={{ __html: testimonial.description }}
              />
            )}
            <Typography type="md" as="h3" mb={2} fontWeight="bold">{testimonial.author}</Typography>
            <Typography type="xs" as="p" >{testimonial.companyJobRole}</Typography>
          </PseudoBox>
        </Box>
        <Box w={['35%', '50%']}>
          {testimonial.avatar && (
            <ImageBox ml={index % 2 === 0 ? '0' : 'auto'} onClick={() => setModal(true)} className={!!testimonial.videoReference && 'hasVideo'}>
              <Image src={testimonial.avatar} ignoreFallback alt={testimonial.author} objectFit="cover" />
              <div className="play-button"/>
            </ImageBox>
          )}
        </Box>
      </Flex>

      {!!testimonial.videoReference && modal &&
        <>
          <ModalBg />
          <Modal>
            <SambaPlayer reference={testimonial.videoReference} modalPlayer/>
            <CloseButton onClick={() => setModal(false)}>x</CloseButton>
          </Modal>
        </>
      }
    </>
  )
}

export default TestimonialItem

interface ImageBoxProps { ml: string}

const ImageBox = styled.div<ImageBoxProps>`
  position: relative;
  position: relative;
  width: 100%;
  max-width: 370px;
  margin-left: 0;
  aspect-ratio: 9/12;
  margin-left: ${props => (props.ml)};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.hasVideo {
    &:hover {
      &::before {
        display: block;
      }
      
      .play-button {
        display: block;
      }
    }
  }
  
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
    cursor: pointer;
  }

  .play-button {
    cursor: pointer;
    display: none;
    height: 0;
    width: 0;
    border-top: 60px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 110px solid #fff;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ModalBg = styled.div`
  background: rgb(0 0 0 / 70%);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
`

const Modal = styled.div`
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  aspect-ratio: 16/9;
  max-width: 90%;
`

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 28px;
  font-family: monospace;
  width: 30px;
  background: #ddd;
  line-height: 26.5px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  z-index: 99;
  display: block;
`
