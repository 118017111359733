import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { GuardProvider } from 'react-router-guards'
import {
  AllTraining,
  Anamnesis,
  Dashboard,
  DynamicPage,
  FavoriteTraining,
  FilteredTraining,
  Home,
  ListTeachers,
  Live,
  MyPlans,
  NoAuthorization,
  PageNotFound,
  Payment,
  PaymentTrial,
  Plan,
  Privacy,
  Research,
  Profile,
  SearchTraining,
  SelectPlan,
  ChangeSelectPlan,
  ChangePlanConfirm,
  Terms,
  UpdateCreditCard,
  UpdatePassword,
  UserSessions,
  ViewTeacher,
  ViewTraining
} from '../../pages'
import { ConfirmSignUp, CreateNewPassword, Logout, SignIn, SignUp } from '../../pages/amplify'
import { BaseBlank, BaseMobile, BaseSignIn, BaseSubscription } from '../layouts'
import { AppRoute } from './app-route'
import { makeWithAuth } from '../../main/factories/usecases/auth/make-with-auth'
import { getUser } from '../../utils/helpers/user'
import { settings } from '../../config/settings'
import ForgotPassword from '../../pages/amplify/forgot-password'
import PasswordRecovery from '../../pages/amplify/password-recovery'
import MobileSambaPlayer from '../mobile/mobile-samba-player'
import MobileLive from '../mobile/mobile-live'
import queryString from 'query-string'

const requireLogin = (to, _from, next) => {
  if (to.meta.auth) {
    makeWithAuth().then(() => {
      const user = getUser()
      const {
        hasSubscription,
        answeredAllActiveForms,
        hasCustomerFolders
      } = user.info

      if (to.meta.noSubscription) {
        if (hasCustomerFolders) {
          next.redirect('/dashboard')
        } else if (!hasCustomerFolders && hasSubscription) {
          next.redirect('/alterar-cartao')
        }
        next()
      } else if (to.meta.reqSubsWithoutAnamnesis) {
        if (!hasCustomerFolders) {
          next.redirect('/escolha-seu-plano')
        }
        next()
      } else if (to.meta.reqSubs) {
        if ((!hasCustomerFolders && !hasSubscription)) {
          next.redirect('/escolha-seu-plano')
        } else if (!hasCustomerFolders && hasSubscription) {
          next.redirect('/alterar-cartao')
        } else if (!answeredAllActiveForms) {
          if (user.user.answeredAnamnesisForms.includes(settings.anamnesis_1)) {
            next.redirect(`/ficha/${settings.anamnesis_2_form}`)
          }
          next.redirect(`/ficha/${settings.anamnesis_2_form}`)
        }
        next()
      }
      next()
    }).catch(() => {
      const query = queryString.parse(window.location.search)
      if (query.redirect) { next.redirect('/login?urlR=' + encodeURIComponent(window.location.href)) } else { next.redirect('/login') }
    })
  } else {
    next()
  }
}

const Route = () => {
  return (
    <BrowserRouter>
      <GuardProvider guards={[requireLogin]} error={PageNotFound}>
        <Switch>
          <AppRoute path="/" exact component={Home} />
          <AppRoute path="/login" exact component={SignIn} layout={BaseSignIn} />
          <AppRoute path="/criar-nova-senha" exact component={CreateNewPassword} layout={BaseSignIn} />
          <AppRoute path="/cadastre-se" exact component={SignUp} layout={BaseSignIn} />
          <AppRoute path="/cadastre-trial" exact component={SignUp} layout={BaseSignIn} />
          <AppRoute path="/confirmar-usuario" exact component={ConfirmSignUp} layout={BaseSignIn} />
          <AppRoute path="/esqueci-minha-senha" exact component={ForgotPassword} layout={BaseSignIn} />
          <AppRoute path="/recuperar-senha" exact component={PasswordRecovery} layout={BaseSignIn} />
          <AppRoute path="/sair" exact component={Logout} layout={BaseSignIn} />
          <AppRoute path="/mobile/sambaplayer/:reference/:contentId/:playerKey/:progress/:token" exact component={MobileSambaPlayer} layout={BaseMobile} />
          <AppRoute path="/mobile/live/:spaceId/:token" exact component={MobileLive} layout={BaseMobile} />

          <AppRoute path="/alterar-plano" exact component={ChangeSelectPlan} layout={BaseSubscription} meta={{ auth: true, noSubscription: false }} />
          <AppRoute path="/confirmar-alterar-plano" exact component={ChangePlanConfirm} layout={BaseSubscription} meta={{ auth: true, noSubscription: false }} />
          <AppRoute path="/escolha-seu-plano" exact component={SelectPlan} layout={BaseSubscription} meta={{ auth: true, noSubscription: true }} />
          <AppRoute path="/plano/:id" exact component={Plan} layout={BaseSubscription} meta={{ auth: true, noSubscription: true }} />
          <AppRoute path="/pagamento" exact component={Payment} layout={BaseSubscription} meta={{ auth: true, noSubscription: true }} />
          <AppRoute path="/pagamento-trial" exact component={PaymentTrial} layout={BaseSubscription} meta={{ auth: true, noSubscription: true }} />

          <AppRoute path="/alterar-cartao" exact component={UpdateCreditCard} meta={{ auth: true }} />
          <AppRoute path="/perfil" exact component={Profile} meta={{ auth: true }} />
          <AppRoute path="/alterar-senha" exact component={UpdatePassword} meta={{ auth: true }} />
          <AppRoute path="/meus-planos" exact component={MyPlans} meta={{ auth: true }} />
          <AppRoute path="/sessoes" exact component={UserSessions} meta={{ auth: true }} />

          <AppRoute path="/ficha/:slug" exact component={Anamnesis} layout={BaseSubscription} meta={{ auth: true, reqSubsWithoutAnamnesis: true }} />

          <AppRoute path="/dashboard" exact component={Dashboard} meta={{ auth: true, reqSubs: true }} />
          <AppRoute path="/favoritos" exact component={FavoriteTraining} meta={{ auth: true, reqSubs: true }} />
          <AppRoute path="/pesquisa" exact component={SearchTraining} meta={{ auth: true, reqSubs: true }} />
          <AppRoute path="/todos-os-treinos" exact component={AllTraining} meta={{ auth: true, reqSubs: true }} />
          <AppRoute path="/treino/:slug" exact component={FilteredTraining} meta={{ auth: true }} />
          <AppRoute path="/treino/:slug/:weekday" exact component={ViewTraining} meta={{ auth: true, reqSubs: true }} />
          <AppRoute path="/professores" exact component={ListTeachers} meta={{ auth: true, reqSubs: true }} />
          <AppRoute path="/professor/:slug" exact component={ViewTeacher} meta={{ auth: true, reqSubs: true }} />
          <AppRoute path="/live" exact component={Live} meta={{ auth: true, reqSubs: true }} />

          <AppRoute path="/termos-e-condicoes" exact component={Terms} layout={BaseBlank} />
          <AppRoute path="/politica-de-privacidade" exact component={Privacy} layout={BaseBlank} />

          <AppRoute path="/pesquisa-satisfacao" exact component={Research} meta={{ auth: true }} />

          <AppRoute path="/403" exact component={NoAuthorization} />
          <AppRoute path="/404" exact component={PageNotFound} />
          <AppRoute path="/:slug" exact component={DynamicPage} />
          <AppRoute path="*" component={PageNotFound} />

        </Switch>
      </GuardProvider>
    </BrowserRouter>
  )
}

export default Route
