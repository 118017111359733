import React from 'react'
import Slider, { ResponsiveObject, Settings } from 'react-slick'
import { ReactComponent as AngleLeftLight } from '../../icons/angle-left-light.svg'
import { ReactComponent as AngleRightLight } from '../../icons/angle-right-light.svg'
import styled from '@emotion/styled'
import customTheme from '../../../../styles/theme'

const PrevArrow = (props: any): JSX.Element => {
  const { onClick } = props
  return (
    <div onClick={onClick} className="slick-slider-arrow slick-slider-arrow-prev">
      <AngleLeftLight />
    </div>
  )
}

const NextArrow = (props: any): JSX.Element => {
  const { onClick } = props
  return (
    <div onClick={onClick} className="slick-slider-arrow slick-slider-arrow-next">
      <AngleRightLight />
    </div>
  )
}

interface CarouselProps extends Omit<Settings, 'children|responsive'>{
  children: React.ReactNode
  responsive: ResponsiveObject[]
}
const Carousel = ({ children, responsive, ...props }: CarouselProps): JSX.Element => {
  return (
    <CustomCarousel
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      responsive={responsive}
      {...props}
    >
      {children}
    </CustomCarousel>
  )
}

const CustomCarousel = styled(Slider)`
  margin-left: 10%;
  margin-right: 10%;
  .slick-slider-arrow{
    color: ${customTheme.colors.pink['200']};
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 2;
    cursor: pointer;
    
    &.slick-slider-arrow-prev {
      left: -40px;
      @media (min-width: 992px) {
        left: -5%;    
      }
    }
    
    &.slick-slider-arrow-next {
      right: -40px;
      @media (min-width: 992px) {
        right: -5%;    
      }
    }
    
    svg {
      width: 50px;
      height: 50px;
      text-align: center;
    }
  }
`

export default Carousel
