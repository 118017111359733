import React from 'react'
import styled from '@emotion/styled'
import customTheme from '../../../../styles/theme'
import { Box } from '@chakra-ui/core'
import { ModalContextInterface, useModal } from './modal-context'

const Modal: React.FC = () => {
  const { open, setOpen, content, setContent }:ModalContextInterface = useModal()

  const closeModal = (): void => {
    setOpen(false)
    setContent(null)
  }

  if (!open) {
    return null
  }

  return (
    <>
      <ModalBackground onClick={closeModal}/>
      <ModalBox>
        <ModalClose onClick={closeModal}>x</ModalClose>
        <Box>{content}</Box>
      </ModalBox>
    </>
  )
}

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 60%);
  z-index: 99;
`

const ModalBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70vw;
  min-height: 53vh;
  margin-left: -35vw;
  transform: translateY(-50%);
  background: ${customTheme.colors.white};
  z-index: 1000;
`
export const ModalClose = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  font-family: monospace;
  width: 30px;
  background: #ddd;
  line-height: 26.5px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  z-index: 99;
`

export default Modal
